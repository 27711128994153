import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {Form} from 'react-bootstrap'
import {useLocation} from 'react-router'
import 'react-toastify/dist/ReactToastify.css'

import {requestPassword, verify_otp} from '../core/_requests'
import {useRef} from 'react'
const initialValues = {
  email: 'mailto:admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [email, setEmail] = useState<any>('')
  const [userid, setUserid] = useState<any>()
  const [message, setMessage] = useState<any>({message: '', classValue: 0})
  const inputRefs = useRef<Array<HTMLInputElement | any>>([])
  const [digits, setDigits] = useState(['', '', '', '', '', ''])
  const [emailcheck, setemailcheck] = useState('');
  const handleDigitChange = (event: any, index: any,val:any) => {
    console.log('9999999', val)
    if (event.key === 'Backspace') {
      const prevIndex = index - 1;
      if (inputRefs.current[prevIndex]) {
        
        inputRefs?.current[prevIndex].focus();
      }
      const updatedDigits = [...digits];
      updatedDigits[index] = '';
      setDigits(updatedDigits);
    } else {
      if(val){
      const nextIndex = index + 1;
      if (inputRefs.current[nextIndex]) {
        inputRefs?.current[nextIndex].focus();
      }
      
      const updatedDigits = [...digits];
      updatedDigits[index] = val;
      console.log("updatedDigits", updatedDigits);
      setDigits(updatedDigits);
      }

    }
  }

const handleKeyDown = (event: any, index: any) => {
  if (event.key === 'Backspace' && index > 0) {
    // Move focus to the previous input field on Backspace
    const prevIndex = index - 1;
    if (inputRefs.current[prevIndex]) {
      inputRefs?.current[prevIndex].focus();
    }
  }
};
  
  
  
  

  const location = useLocation()
    const stateData = location.state;
    useEffect(() => {
      if(stateData && stateData !==''){
        setEmail(stateData)
        setemailcheck(stateData)
      }
    }, [])

  const emailChecker = (e: any)=>{
    setEmail(e.target.value)
    if(emailcheck !== '' && emailcheck ===e.target.value){
      setMessage({message: ``, classValue: 0})
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })
  const forgotPasswor = async (e: any) => {
    e.preventDefault()
    message && setMessage({message: ''})
    try {
      if (email?.length > 0) {
      if(email === emailcheck) {
        setLoading(true)
        const {data: user} = await requestPassword(email)

        setLoading(false)
        if (user.code === 200) {
          // saveAuth(user)

          setUserid(user?.user_id)
          setMessage({message: `Your one time password has been send to your email. Please enter the number below.`, classValue: 1})

          // setMessage({message: `Your one time password has been send to your mobile phone or email. Please enter the number below.`, classValue: 1})
        } else if (user.code === 400) {
          setMessage({message: 'User Not Found'})
        }
      }
      else{
        setMessage({message: `The detail is incorrect`, classValue: 0})
      }
      } else {
        setMessage({message: `Please enter email`, classValue: 0})
      }
      //}
    } catch (e) {
      setLoading(false)
    }
  }
  const saveOtp = async (e: any) => {
    e.preventDefault()
    message && setMessage({message: ''})
    if (!(digits.join('').length === 6))
      setMessage({message: 'Please fill full Otp', classValue: 0})
    else {
      try {
        let data = {
          user_id: userid,
          otp: digits.join(''),
        }
        setLoading(true)

        const {data: user} = await verify_otp(data)
        setLoading(false)

        if (user?.hassuccessed) {
          navigate('/auth/password-reset', {state: {userid: userid, from :'forgot-password'}})
        } else {
          setMessage({message: `${user?.message}`})
        }
      } catch (e) {
        setLoading(false)
      }
    }
  }
  return (
    <>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-6 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10 custmWdthUsr'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework bg-body rounded shadow-sm p-6 p-lg-10 mx-auto'
              noValidate
              id='kt_login_password_reset_form'
              onSubmit={userid ? saveOtp : forgotPasswor}
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Forgot Password ?</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-400 fw-bold fs-4'>
                  Enter your email to reset your password.
                </div>
                {/* end::Link */}
              </div>
              {message?.message && (
                <div
                  className={`alert ${
                    message?.classValue ? 'alert-primary' : 'alert-danger '
                  } alert-dismissible text-center`}
                  role='alert'
                >
                  {message?.message}
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='alert'
                    aria-label='Close'
                  ></button>
                </div>
              )}
              {/* begin::Title */}
              {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Sorry, looks like there are some errors detected, please try again.
                  </div>
                </div>
              )}
              {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                  <div className='text-info'>Sent password reset. Please check your email</div>
                </div>
              )}

              <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>

                {/* <label className='form-label fw-bolder text-gray-900 fs-6'>Email/Phone</label> */}
                <input
                  value={email}
                  type='email'
                  placeholder=''
                  disabled={userid}
                  autoComplete='off'
                  className='form-control bg-transparent'
                  onChange={(e) => emailChecker(e)}
                />
              </div>
              {userid && (
                <>
                  <p>Enter a 6-digit number:</p>

                  <div className='d-flex mb-10 fillNumDigit'>
                    {digits?.map((digit, index) => (
                      <Form.Control
                        key={index}
                        value={digit}
                        type='text'
                        pattern='[0-9]'
                        maxLength={1}
                        onChange={(e: any) => handleDigitChange(e, index,e.target.value)}

                        onKeyDown={(e: any) => handleDigitChange(e, index,'')}

                        // placeholder='-'
                        min='0'
                        max='9'
                        required
                        className='text-center'
                        ref={(ref: any) => {
                          inputRefs.current[index] = ref
                        }}
                      />
                    ))}
                  </div>
                </>
              )}

              {/* {userid && (
                <>
                  <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>Passwor</label>
                    <input
                      type='text'
                      placeholder=''
                      autoComplete='off'
                      className='form-control bg-transparent'
                      onChange={(e) => setPass(e.target.value)}
                    />
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='form-label fw-bolder text-gray-900 fs-6'>
                      Confirm Password
                    </label>
                    <input
                      type='text'
                      placeholder=''
                      autoComplete='off'
                      className='form-control bg-transparent'
                      onChange={(e) => setCpass(e.target.value)}
                    />
                  </div>
                </>
              )} */}
              {/* end::Form group */}
              {/* begin::Form group */}
              <div className='d-flex flex-wrap justify-content-center pb-lg-0 formFrgtSection'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder setloginbutton'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}

                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <Link to='/auth/login'>
                  <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-primary fw-bolder loginbtnlap'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Cancel
                  </button>
                </Link>{' '}
              </div>

              {/* end::Form group */}
              <div></div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
