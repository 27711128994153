export const datas = [
  //{ Dbfield: "packageid", alias: "pid" },
  {Dbfield: 'packagename', alias: 'pName'},
  {Dbfield: 'subtitle', alias: 'ptitle'},
  {Dbfield: 'images', alias: 'pimg'},
  {Dbfield: 'sectiondetails', alias: 'psec'},
  {Dbfield: 'startdate', alias: 'psdata'},
  {Dbfield: 'enddate', alias: 'pedata'},
  {Dbfield: 'duration', alias: 'pduration'},
  {Dbfield: 'cutofftime', alias: 'pcutofftime'},
  {Dbfield: 'setage', alias: 'psetage'},
  {Dbfield: 'setparticipants', alias: 'psetparticipants'},
  {Dbfield: 'amount', alias: 'pamount'},
  {Dbfield: 'status', alias: 'pstatus'},
  {Dbfield: 'created_at', alias: 'pcreated'},
]
export const datass = [
  //{ Dbfield: "packageid", alias: "pid" },
  {Dbfield: 'packagename', alias: 'pName'},
  {Dbfield: 'subtitle', alias: 'ptitle'},
  {Dbfield: 'images', alias: 'pimg'},
  //{Dbfield: 'sectiondetails', alias: 'psec'},
  {Dbfield: 'startdate', alias: 'psdata'},
  {Dbfield: 'enddate', alias: 'pedata'},
  // {Dbfield: 'duration', alias: 'pduration'},
  // {Dbfield: 'cutofftime', alias: 'pcutofftime'},
  // {Dbfield: 'setage', alias: 'psetage'},
  // {Dbfield: 'setparticipants', alias: 'psetparticipants'},
  {Dbfield: 'amount', alias: 'pamount'},
  {Dbfield: 'status', alias: 'pstatus'},
  {Dbfield: 'created_at', alias: 'pcreated'},
]

export const AscDescDatas = ['pName', 'ptitle']

export const Alias = (data) => datas.filter((item) => item.Dbfield === data)[0]?.alias
export const Dbfield = (data) => datas.filter((item) => item.alias === data)[0]?.Dbfield
