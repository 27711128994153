import { ActionTypes } from '../constants';


//  -- List Branch ----------------
export const listFormData = () => ({ type: ActionTypes.formvalues.getformvalues });
export const setFormData = (returnedPayload) => ({ type: ActionTypes.formvalues.setformvalues, payload: returnedPayload });
export const updateFormData = (returnedPayload) => ({ type: ActionTypes.formvalues.updateformvalues, payload: returnedPayload });


// //  -- Add Branch ----------------
// export const createBranch = () => ({ type: ActionTypes.Branch.createCategory });
// export const createBranchFail = (returnedPayload) => ({ type: ActionTypes.Branch.createBranchFail, payload: returnedPayload });
// export const createBranchSuccess = (returnedPayload) => ({ type: ActionTypes.Branch.createBranchSuccess, payload: returnedPayload });



// //  -- Update Branch ----------------
// export const updateBranch = () => ({ type: ActionTypes.Branch.updateBranch });
// export const updateBranchFail = (returnedPayload) => ({ type: ActionTypes.Branch.updateBranchFail, payload: returnedPayload });
// export const updateBranchSuccess = (returnedPayload) => ({ type: ActionTypes.Branch.updateBranchSuccess, payload: returnedPayload });


// //  -- Delete Branch ----------------
// export const deleteBranch = () => ({ type: ActionTypes.Branch.deleteBranch });
// export const deleteBranchFail = (returnedPayload) => ({ type: ActionTypes.Branch.deleteBranchFail, payload: returnedPayload });
// export const deleteBranchSuccess = (returnedPayload) => ({ type: ActionTypes.Branch.deleteBranchSuccess, payload: returnedPayload });
