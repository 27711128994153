import { FeedbacksTAb } from '../../app/modules/apps/Feedbacks/LeftMenu/Feedbacks'
import { ReviewTAb } from '../../app/modules/apps/Review/LeftMenu/Reviews'
import {AdminMearchantTab} from '../../app/modules/apps/marchant/LeftMenu/Amarchant'
import {MearchantTab} from '../../app/modules/apps/marchant/LeftMenu/Marchant'
import {AccountsTab} from '../layout/components/aside/Tabs/AccountsTab'
import {AuthorsTab} from '../layout/components/aside/Tabs/AuthorsTab'
import {OrganizationTab} from '../layout/components/aside/Tabs/OrganizationTab'
import {ReportsTab} from '../layout/components/aside/Tabs/ReportsTab'
import {SettingsTab} from '../layout/components/aside/Tabs/SettingsTab'
import {SubscriptionsTab} from '../layout/components/aside/Tabs/SubscriptionsTab'
import {TasksTab} from '../layout/components/aside/Tabs/TasksTab'
import Branding from '../../app/modules/apps/Branding/LeftMenu/Submenu'
import Subscriptions from '../../app/modules/apps/Subscription/LeftMenu/Submenu'
import Forms from '../../app/modules/apps/Forms/LeftMenu/Submenu'
type Props = {
  link: string
}
export interface TabComponents {
  [key: string]: React.FC<Props>
}

export const tabComponents: TabComponents = {
  organizations: OrganizationTab,
  Reports: ReportsTab,
  subscription: SubscriptionsTab,
  Accounts: AccountsTab,
  tasks: TasksTab,
  authors: AuthorsTab,
  Settings: SettingsTab,
  Merchant: MearchantTab,
  AMerchant: AdminMearchantTab,
  Feedbacks:FeedbacksTAb,
  Branding:Branding,
  Subscription:Subscriptions,
  Forms:Forms,
  Review:ReviewTAb

}
