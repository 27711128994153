/* eslint-disable react/jsx-no-target-blank */
import {FC} from 'react'
import {KTSVG} from '../../../../helpers'

import {SettingsTab} from './SettingsTab'

import {tabComponents} from '../../../../plugins/addplugins'
import { useLocation } from 'react-router-dom'


type Props = {
  link: string
}

const TabsBase: FC<Props> = ({link}) => {
  const location = useLocation();
  const urlPath = location.pathname;
  const feedbackMatch = urlPath.split('/').filter(Boolean)[1];
  let links=''
  if(feedbackMatch=='feedback'){
    links='Feedbacks'
  }
  else if( feedbackMatch  =='eview')
  links='Review'
  else if( feedbackMatch  =='branding')
  links='Branding'

  else if( feedbackMatch  =='organization')
  links='organizations'

  else if( feedbackMatch  =='subscription')
  links='Subscription'

  else if( feedbackMatch  =='reviewform')
  links='Forms'
   else if(feedbackMatch =='form'){
    links='Forms'

   }

else{
 links='Reports'

}



//  const TabComponent: any = tabComponents[link?link:links]

  
  const TabComponent: any = tabComponents[link]
  
 


  
  return (
    <div className='d-flex h-100 flex-column'>
      {/* begin::Wrapper */}
      <div
        className='flex-column-fluid hover-scroll-y'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-wrappers='#kt_aside_wordspace'
        data-kt-scroll-dependencies='#kt_aside_secondary_footer'
        data-kt-scroll-offset='0px'
      >
        {/* begin::Tab content */}
        <div className='tab-content'>
          <div
            className='tab-pane fade active show'
            id={`kt_aside_nav_tab_${link}`}
            role='tabpanel'
          >
            {TabComponent ? <TabComponent /> : <SettingsTab />}

            {/* <SelectedTab link={link} /> */}
          </div>
        </div>
        {/* end::Tab content */}
      </div>
      {/* end::Wrapper */}
      {/* begin::Footer */}
      <div className='flex-column-auto pt-10 px-5' id='kt_aside_secondary_footer'>
       
      </div>
      {/* end::Footer */}
    </div>
  )
}

export {TabsBase}
