import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {Dropdown1, Search} from '../../../../partials'
import {useEffect, useState} from 'react'
import {useAuth} from '../../../../../app/modules/auth'
import axios from 'axios'
import {company} from '../../../../../app/modules/Component/modal/data'

const API_URL = process.env.REACT_APP_API_URL
interface t {
  packagename: string
  subtitle: string
  packageid: string
  startdate: string
  enddate: string
  images: {}
  duration: {}
  cutofftime: {}
  setage: string
  setparticipant: string
}

const OrganizationTab = () => {
  const {currentUser, auth, superauth} = useAuth()
  const navigate = useNavigate()
  const [search, setSearch] = useState<any>()

  const [addAlldata, setAlldata] = useState<any>([])
  const [condition, setCondition] = useState<any>([
    {column: 'cName', operator: 'LIKE', value: `%%`},
  ])
  const projects: ReadonlyArray<any> = addAlldata
  var token: any = superauth ? superauth?.api_token : auth?.api_token

  const handleUpdateUser = async () => {
    const leadData = {
      fieldName: ['cName', 'cCreated'],
      sorting: [{fieldName: 'cCreated', order: 'DESC'}],
      pagination: {pages: '1', limits: '5'},
      filters: condition,
      created_by: currentUser?.user_id,
      is_admin: currentUser?.is_admin,
      company_id: currentUser?.company_id,
    }
   

    if (currentUser?.is_admin === true) {
      var query = `${API_URL}/Company/companyBySuperAdmin`
    } else {
      query = `${API_URL}/Company/RecentOrganization`
    }
    try {
      const response = await axios.post(`${query}`, JSON.stringify({leadData}), {
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      })
      if (currentUser?.is_admin === true) {
        setAlldata(response?.data?.data?.data)
      } else {
        setAlldata(response?.data?.data)
      }
    } catch (error) {
      console.error(error)
    }
  }
  


  useEffect(() => {
    handleUpdateUser()
  }, [condition])
  return (
    <div className='m-0'>
      <div className='d-flex mb-10'>
        <div className='d-flex align-items-center position-relative my-1'>
          <span className='svg-icon svg-icon-1 position-absolute ms-6'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                opacity='0.5'
                x='17.0365'
                y='15.1223'
                width='8.15546'
                height='2'
                rx='1'
                transform='rotate(45 17.0365 15.1223)'
                fill='currentColor'
              />
              <path
                d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                fill='currentColor'
              />
            </svg>
          </span>
          <input
            type='text'
            data-kt-customer-table-filter='search'
            className='form-control form-control-solid w-250px ps-15'
            placeholder='Search Organization'
            onChange={(e) => {
              setSearch(e.target.value)
              setCondition([{column: 'cName', operator: 'LIKE', value: `%${e.target.value}%`}])
            }}
            value={search}
          />{' '}
        </div>
        <div className='flex-shrink-0 ms-2'>
          <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
          </button>

          {/* <Dropdown1 /> */}
        </div>
      </div>
      <div className='d-flex mb-10 justify-content-center '>
        <div className='flex-shrink-0  '>
          <Link
            to='/apps/organizations/list'
            className='btn btn-primary btn-active-icon-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            View Org
          </Link>
          &nbsp; &nbsp;
          <Link
            to='apps/addOrganization/addorg'
            // type='button'
            className='btn btn-primary btn-active-icon-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            Add Org
          </Link>
        </div>
      </div>
      <div className='m-0'>
        <h1 className='text-gray-800 fw-bold mb-6 mx-5'>Recent Organization</h1>

        {/* <div className='mb-10'>

          <div
            // key={addAlldata.company_id}
            // to='/crafted/organization/settings'
            className='custom-list d-flex align-items-center px-5 py-4'
          >
            {addAlldata.company_logo ? (
              <div className='symbol symbol-40px me-5'>
                <span className='symbol-label symbol'>
                  <img
                    src={`${process.env.REACT_APP_IMAGES_URL}/${addAlldata.company_logo}`}
                    alt={addAlldata.company_name}
                    className='h-100 align-self-center'
                  />
                </span>
              </div>) : (<div className='symbol symbol-40px me-5'>
                <span className='symbol-label symbol'>
                  <img
                    src={toAbsoluteUrl('/media/avatars/blank_avatar.jpg')}
                    alt={addAlldata.company_name}
                    className='h-100 align-self-center'
                  />
                </span>
              </div>)}

            <div className='d-flex flex-column flex-grow-1'>
              <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{addAlldata.company_name}</h5>

              <span className='text-gray-400 fw-bold'></span>
            </div>
          </div>

        </div> */}
        <div className='mb-10'>
          {Array.isArray(projects) &&projects?.map((p) => (
            <Link
              key={p.company_id}
              // state={p?.company_id}
              to='#'
              className='custom-list d-flex align-items-center px-5 py-4'
            >
              {p?.company_logo ? (
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label symbol'>
                    <img
                      src={p?.company_logo}
                      alt={p.title}
                      className='h-100 align-self-center orgRecntOrg'
                    />
                  </span>
                </div>
              ) : (
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label symbol'>
                    <img
                      src={toAbsoluteUrl('/media/avatars/blank_avatar.jpg')}
                      alt={p.title}
                      className='h-100 align-self-center orgRecntOrg'
                    />
                  </span>
                </div>
              )}

              <div className='d-flex flex-column flex-grow-1'>
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{p.company_name}</h5>

                <span className='text-gray-400 fw-bold'></span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export {OrganizationTab}
