import React, { useEffect, useState } from "react";
import "./App.css";
import { useDispatch } from "react-redux";
import { useFormData } from "./store/FormData/selectors";
import _ from "lodash";
import axios from "axios";
import { decode } from "base-64";
import Select from "react-select";
import copy from "clipboard-copy";
import { Image, Rate } from "antd";
import { Upload, Button, message, Spin } from "antd";
import { Modal } from "react-bootstrap";
import { getForm, saveDataForm, setDataForm } from "./store/FormData/thunks";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useAuth } from "../auth";
import TagsInput from 'react-tagsinput';
import { CheckOutlined } from "@ant-design/icons";
import ReactGA from "react-ga4";

const API_URL = process.env.REACT_APP_API_URL;
const API_URL1 = process.env.REACT_APP_API_URL1;
const Index = () => {
  const dispatch = useDispatch();
  const formDatas = useFormData();
  const navigate = useNavigate();
  //  useFormData
  const [clicked, setClicked] = useState(false);
  const {auth,saveAuth, currentUser, setCurrentUser} = useAuth()

  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [rating, setRating] = useState("1");
  const [experience, setExperience] = useState("");
  const [review, setReview] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [values1, setValues] = useState("");
  const [memberId, setMemberId] = useState("");
  const [tags, setTags] = useState([]);
  const [wouldRecommend, setWouldRecommend] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [step1, setStep1] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);

  const [isModalOne, setIsModalOne] = useState(false);
  const [starRating, setStarRating] = useState(0);
  const [spin, setSpin] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [nameError, setNameError] = useState("");
  const handleRateChange = (value) => {
    setStarRating(value);
  };

  const toAddModalRate = () => {
    setIsModalOne(true);
  };

  const toCloseModal = () => {
    setIsModalOne(false);
  };

  const rateStyle = {
    fontSize: 32, // Adjust the fontSize to change the star size
  };

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(
        selectedTags.filter((selectedTag) => selectedTag !== tag)
      );
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  // const nextStep = () => {
  //   setStep(step + 1);
  // };
  const nextStep = () => {
    if (name.trim() === "") {
      setNameError("Please Enter your name");
    } else {
      setNameError("");
      setStep(step + 1);
    }
  };
  const handleInputChange = (value) => {
    setTags(value);
  }
  const handleTextCut = () => {
    const selectedText = window.getSelection().toString();
    if (selectedText) {
      setReview(prevReview => {
        const updatedReview = prevReview[currentIndex].replace(selectedText, '');
        const newReview = [...prevReview];
        newReview[currentIndex] = updatedReview;
        return newReview;
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      const selectionStart = e.target.selectionStart;
      const selectionEnd = e.target.selectionEnd;
  
      // Check if there's a selection
      if (selectionStart !== selectionEnd) {
        // Remove selected text from the review
        setReview(prevReview => {
          const updatedReview = prevReview[currentIndex].slice(0, selectionStart) + prevReview[currentIndex].slice(selectionEnd);
          const newReview = [...prevReview];
          newReview[currentIndex] = updatedReview;
          return newReview;
        });
        e.preventDefault(); // Prevent the default backspace behavior
      }
    }
  };
  const nextStep1 = () => {
    if (formDatas?.fields.length - 1 > step1) setStep1(step1 + 1);
    else {
      const value = {
        name,
        rating,
        formDatas,
        memberId,
      };
      const data = saveDataForm(value);
    // window.location='https://g.page/r/CRgbXrJPmCljEBE/review'

      setStep(6);
    }
  };
  const TRACKING_ID='G-NV6CE18P4G'
  ReactGA.initialize(TRACKING_ID)
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname });
  },[])
  useEffect(() => {
    const save=async()=>{
   if(formDatas.is_algen === false||formDatas.is_algen === null){
    const value = {
      name,
      rating,
      formDatas,
      memberId,
    };
    const data =await saveDataForm(value);
    if(data.length > 0){
      window.location=formDatas?.companyuri
    }
  }
   }
   save()
  }, [formDatas.is_algen === false && formDatas.lowfeedbackpoint<=rating ]);
  const handleChange = (input, value) => {
    setNameError('')
    if (input === "rating") {
      setRating(value);
      if(formDatas.is_algen === false && formDatas.lowfeedbackpoint<=value){
       // window.location=formDatas?.companyuri
      }else{
      setTimeout(() => {
        nextStep();
      }, 500);}
    } else if (input === "name") {
      setName(value);
    } else if (input === "experience") {
      setExperience(value);
    } else if (input === "review") {
      // setReview(value);
      if (value.length < review[currentIndex].length) {
        setReview(prevReview => {
          const updatedReview = prevReview[currentIndex].slice(0, -1);
          const newReview = [...prevReview];
          newReview[currentIndex] = updatedReview;
          return newReview;
        });
      } else {
        setReview(prevReview => {
          const newReview = [...prevReview];
          newReview[currentIndex] = value;
          return newReview;
        });
      }
    } else if (input === "wouldRecommend") {
      setWouldRecommend(value);
    } else if (input === "contactInfo") {
      setContactInfo(value);
    }
    // submitReview();
  };
  if (Array.isArray(formDatas?.fields))
    var fullDataForm = formDatas?.fields[step1];

  const submitReview = async() => {
    const value = {
      name,
      rating,
      formDatas,
      memberId,
      review,
    };
    const data= await saveDataForm(value);
    if(data.length > 0){
      window.location=formDatas?.companyuri
    }

   // setStep(6);
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const parts = currentPath.split("/");
    const lastPart = parts[parts.length - 1];

    const decodeId = decode(lastPart);
    dispatch(getForm(decodeId));
    setMemberId(decodeId);
  }, []);

  function addKeyValueToObjectById(objectId, key, value) {
    // console.log('value', value);
    const objectToUpdate = _.find(formDatas?.fields, { id: objectId });
    // console.log(objectToUpdate);
    if (objectToUpdate) {
      objectToUpdate[key] = value;
    }
    dispatch(setDataForm(formDatas));
  //  window.location='https://g.page/r/CRgbXrJPmCljEBE/review'
     // navigate('https://g.page/r/CRgbXrJPmCljEBE/review')

  }

  const handleTagsGeneration1 = async (tag1) => {
    setSpin(true);

    let tag = selectedTags;
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    try {
      // Fetch reviews from Google Places API
      const googlePlacesResponse = await axios.get(
        "https://maps.googleapis.com/maps/api/place/details/json",
        {
          params: {
            place_id: "ChIJ____78jjDDkRGBtesk-YKWM", // Replace with your place_id
            fields: "reviews",
            key: "AIzaSyDvF60RL0fjK5MWVkJlE50odb_wiHjhNLc",
          },
        },
        config
      );
    } catch (error) {
      console.error("Error:", error);
      // res.status(500).json({ error: 'An error occurred' });
    }
  };
  // try {
  //   setLoading(true);
  //   // Make a GET request to the '/generateTags' API with the selected tag
  //   const response = await axios.get(
  //     `http://localhost:5001/generateTags/${selectedTags}`
  //   );
  //   setLoading(false);
  //   // Handle the response as needed
  //   console.log('Tag generated:', response.data);
  //   setReview(response.data.generatedTags[0]);
  // } catch (error) {
  //   console.error('Error generating tag:', error);
  // }

  const handleTagsGeneration = async (tag1) => {
    try {
      setSpin(true);
  const newtag=[...new Set(selectedTags.concat(tags))];
      // Prepare the data to be sent in the request body
      const requestData = {
        selectedTags: newtag,
        selectedOptions: selectedOptions.map((data) => data.value),
        companyuri: formDatas?.companyuri,
        user_com_id: formDatas?.user_company_id
      };
  
      // Make a POST request to the '/generateTags' API with the selected tag
      const response = await axios.post(
        `${API_URL}/googlereview/generateTags`,
        requestData
      );
  
      setSpin(false);
  
      // Handle the response as needed
      setReview((prevData) => {
        const newTag = response.data.generatedTags[0];
  
        // Check if the new data already exists in the previous data
        if (!prevData.includes(newTag)) {
          // If it doesn't exist, append the new data to the previous data
          return [...prevData, newTag];
        }
  
        // If it exists, return the previous data without any changes
        return prevData;
      });
  
      setCurrentIndex((news) => news + 1);
    } catch (error) {
      setSpin(false);
  
      console.error("Error generating tag:", error);
    }
  };
  

  // const previousdata = () => {
  //     // Check if there is more data to display
  //     if (currentIndex < review.length - 1) {
  //       // Increment the index to display the next data
  //       setCurrentIndex((prevIndex) => prevIndex + 1);
  //     } else {
  //       // Reset the index to start over if all data has been displayed
  //       setCurrentIndex(0);
  //     }

  // }
  const nextData = () => {
    // Check if there is more data to display
    if (currentIndex < review.length - 1) {
      // Increment the index to display the next data
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      // Reset the index to start over if all data has been displayed
      // setCurrentIndex(0);
    }
  };

  const previousData = () => {
    // Check if there is previous data to display
    if (currentIndex > 0) {
      // Decrement the index to display the previous data
      setCurrentIndex((prevIndex) => prevIndex - 1);
    } else {
      // Set the index to the last item if at the beginning
      // setCurrentIndex(review.length - 1);
    }
  };
  const initialData = [
    "Casual",
    "Professional",
    "Conversational",
    "Enthusiastic and Excited",
    "Narrative and Storytelling",
    "Informative and Detailed",
    "Appreciative and Grateful",
  ];

  const generateOptions = (data) => {
    return data.map((item) => ({
      value: item,
      label: item,
    }));
  };
  const options = generateOptions(initialData);

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };
  const copyText = (e) => {
    e?.preventDefault();
    copy(review);
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 2000);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'var(--bs-app-blank-bg)',

      // set the width here
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#04c8c8' : '#dcfdfd',
      color: state.isFocused ? '#dcfdfd' : '#04c8c8',
      ':hover': {
        backgroundColor: '#04c8c8',
      },
    }), 
    menu: (provided) => ({
      ...provided,
   
      backgroundColor: 'var(--bs-app-blank-bg)',
      // enable scrolling if necessary
    })}

  return (
    <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <Modal
            className="fixforvalue3"
            dialogClassName="modal-dialog modal-dialog-centered"
            show={isModalOne}
          >
            {/* <div className='card'> */}
            <form
              className="form"
              action="#"
              id="kt_modal_add_customer_form"
              data-kt-redirect="../../demo7/dist/apps/customers/list.html"
            >
              <div className="d-flex justify-content-center my-4">
                <h2>Decathlon Indirapuram</h2>
                <div
                  className="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                  id="close"
                  onClick={toCloseModal}
                >
                  <span className="svg-icon svg-icon-1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x="6"
                        y="17.3137"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      />
                      <rect
                        x="7.41422"
                        y="6"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className=" p-9">
                <div className="row mx-2 mb-2">
                  <div className="mb-4 col-xl-12">
                    <div className="col-xl-12">
                      <div className="col d-flex align-items-center mt-2 ms-3">
                        <div className="" style={{ borderRadius: "50%" }}>
                          <Image
                            width={30}
                            src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                          />
                        </div>
                        <div className="ms-4">
                          <div className="font-weight-bold">Ruchi Agrawal</div>
                          <div>
                            Posting Publicaly{" "}
                            <span>
                              <i class="bi bi-exclamation-circle"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 mb-5">
                      <div style={{ textAlign: "center", marginTop: "50px" }}>
                        <Rate
                          value={starRating}
                          style={rateStyle}
                          onChange={handleRateChange}
                        />
                      </div>
                    </div>
                    <div className="mx-3">
                      <textarea
                        id="w3review"
                        name="w3review"
                        rows="6"
                        cols="50"
                        style={{ width: "100%" }}
                      >
                        At w3schools.com you will learn how to make a website. They
                        offer free tutorials in all web development technologies.
                      </textarea>
                    </div>
                    <div className="mb-5 mt-3 ">
                      <div className="justify-content-center d-flex">
                        <Upload
                          name="image"
                          showUploadList={false}
                        // onChange={handleChange}
                        // style={{width: '100%'}}
                        >
                          {/* <Button
                            style={{ width: "100%" }}
                            icon={<UploadOutlined />}
                          >
                            Add Image
                          </Button> */}
                        </Upload>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-center py-6 px-9 my-3">
                <button type="submit" className="btn btn-primary">
                  Post
                </button>
                <button
                  type="reset"
                  className="btn btn-light btn-active-light-primary me-2 editbutton"
                // onClick={handleReset}
                >
                  Cancel
                </button>
              </div>
            </form>
            {/* </div> */}
          </Modal>
          {step === 1 && (
            formDatas &&
            <div className="container">
              <div className="vh-100 nameSection"> 
                {formDatas?.filename &&
                  <div className="row justify-content-center position-relative align-items-center">
                    <div className="col-md-12 margin-left 11">
                      <img src={formDatas?.filename} className="fiximges" />
                    </div>
                  </div>
                }
                <div className="row justify-content-center align-items-center position-relative" >
                    <div className="col-md-12">
                      <h2
                        style={{
                          fontFamily: `${formDatas?.primaryfont}`,
                          color: `${formDatas?.primarycolor}`,
                        }}
                        className="fw-normal mt-2 sorry 111">What is your name?</h2>
                      <h4 className="please" style={{
                        //fontFamily: `${formDatas?.secondaryfont}`,
                        color: `${formDatas?.secondarycolor}`,
                      }}>First & Last Name</h4>
                      <input
                        type="text"
                        placeholder="Type your answer here..."
                        value={name}
                        onChange={(e) => handleChange("name", e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            nextStep();
                          }
                        }}
                        className="bottom-border my-3"
                      />
                       {nameError && <div style={{ color: 'red' }}>{nameError}</div>}
                      <div className="d-flex align-items-center">
                        <button onClick={nextStep} className="btn btn-secondary mt-3 ok">
                          OK{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="23"
                            fill="currentColor"
                            className="bi bi-check-lg"
                            viewBox="0 0 18 18">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                          </svg>
                        </button>

                        <div className="elpVLvv mt-3 ms-2 pressEntr">
                          <span>
                            press <strong>Enter ↵</strong>{" "}
                          </span>
                        </div>

                      </div>
                    </div>
                </div>
              </div>
              {/* <div className="row justify-content-center align-items-center vh-100">
            <div className=" col-md-12  m-0">
              {/* <img src={formDatas?.filename} className="img-fluid imgcss" /> 

              <div className="p-3">
                <h2 className="what-name">What is your name?</h2>
                <h4 className="fullname">First & Last Name</h4>
                <input
                  type="text"
                  placeholder="Type your answer here..."
                  value={name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      nextStep();
                    }
                  }}
                  className="bottom-border spec"
                />

                <div className="d-flex align-items-center mt-3 ">
                  <button onClick={nextStep} className="btn btn-secondary  ok ">
                    OK{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      fill="currentColor"
                      className="bi bi-check-lg"
                      viewBox="0 0 18 18"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  </button>
                  <div className="elpVLv">
                    press <strong>Enter ↵</strong>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          )}
          {step === 2 && (
            <>
              <div className="container">
                <div className="vh-100 addCstmRvw nameSection">
                  {formDatas?.filename && 
                    <div className="row justify-content-center position-relative align-items-center">
                      <div className="col-md-12 margin-left">
                        <img src={formDatas?.filename} className="fiximges" />
                      </div>
                    </div>
                   }

                  <div className="row justify-content-center position-relative align-items-center">
                      <div className="col-md-12">
                        <h2
                          style={{
                            fontFamily: `${formDatas?.primaryfont}`,
                            color: `${formDatas?.primarycolor}`,
                          }}
                          className="text-spacing">
                           How likely are you to recommend us to a friend or
                           colleague?*
                        </h2>
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div className="giveRatingBtns">
                              {[
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                                "10",
                              ].map((ratings) => (
                                <button
                                  key={ratings}
                                  className={`btn btn-secondary btn-lg btn-block rounded-1 boxes mt-2  ${ratings == rating ? "selected" : ""
                                    }`}
                                  onClick={() => {
                                    setTimeout(() => { }, 2000);
                                    handleChange("rating", ratings);
                                  }}
                                >
                                  {ratings}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between fw-medium rating-btn">
                          <p className="m-3 not-extremely">Not likely at all</p>
                          <p className="m-3 not-extremely">Extremely likely</p>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                </div>
              </div>
              {/* <div className="container-fluid d-flex align-items-center justify-content-center vh-100">
          <div className="row">
            <div className="col-md-12">
              <img src={formDatas?.filename} className="img-fluid imgcss" />
              <h2 className="text-spacing ms-3 mt-3">
                How likely are you to recommend us to a friend or colleague?*
              </h2>
              <div className="row mt-4">
                <div className="col-md-12 ">
                  <div className="  ">
                    {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map(
                      (ratings) => (
                        <button
                          key={ratings}
                          className={`btn btn-secondary btn-lg btn-block rounded-1 boxes mt-2  ${
                            ratings == rating ? "selected" : ""
                          }`}
                          onClick={() => {
                            setTimeout(() => {}, 2000);
                            handleChange("rating", ratings);
                          }}
                        >
                          {ratings}
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between fw-medium">
                <p className="m-3 not-extremely">Not likely at all</p>
                <p className="m-3 not-extremely">Extremely likely</p>
              </div>
            </div>
          </div>
        </div> */}
            </>
          )}
          {step === 3 && (
            <div className="container">
              {rating <= formDatas?.lowfeedbackpoint ? (
                <div className="vh-100 nameSection">
                  {formDatas?.filename &&
                    <div className="row justify-content-center position-relative align-items-center">
                      <div className="col-md-12 margin-left">
                        <img
                          src={formDatas?.filename}
                          className="fiximges"
                        />
                      </div>
                    </div>
                   }

                  {fullDataForm.name == "shortText" && (
                    <div className="row justify-content-center align-items-center position-relative">
                        <div className="col-md-12">
                          <h2
                            style={{
                              fontFamily: `${formDatas?.primaryfont}`,
                              color: `${formDatas?.primarycolor}`,
                            }}
                            className="fw-normal sorry">
                            {fullDataForm?.label}
                          </h2>
                          <h4
                            style={{
                              //fontFamily: `${formDatas?.secondaryfont}`,
                              color: `${formDatas?.secondarycolor}`,
                            }}
                            className="please mt-2">
                            {fullDataForm?.description}
                          </h4>

                          <input
                            type="text"
                            placeholder="Type your answer here..."
                            value={fullDataForm?.values ?? ""}
                            onChange={(e) => {
                              addKeyValueToObjectById(
                                fullDataForm?.id,
                                "values",
                                e.target.value
                              );
                              setValues(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                nextStep1();
                              }
                            }}
                            className="bottom-border my-3"
                          />

                          <div className="d-flex align-items-center ">
                            <button
                              onClick={nextStep1}
                              className="btn btn-secondary mt-3 ok "
                            >
                              OK{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="23"
                                fill="currentColor"
                                className="bi bi-check-lg"
                                viewBox="0 0 18 18"
                              >
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                              </svg>
                            </button>
                            <div className="elpVLvv mt-3 ms-2 pressEntr">
                              <span>
                                press <strong>Enter ↵</strong>{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                    </div>
                  )}

                  {fullDataForm.name == "longText" && (
                    // <div className=' row justify-content-center align-items-center vh-100'>
                    <div className="row justify-content-center align-items-center position-relative">
                        <div className="col-md-12">
                          <h2
                            className="fw-normal sorry"
                            style={{
                              fontFamily: `${formDatas?.primaryfont}`,
                              color: `${formDatas?.primarycolor}`,
                            }}>
                            {fullDataForm.label}
                          </h2>
                          <h4
                            className="please mt-2"
                            style={{
                              //fontFamily: `${formDatas?.secondaryfont}`,
                              color: `${formDatas?.secondarycolor}`,
                            }}>
                            {fullDataForm?.description}
                          </h4>

                          <textarea
                            type="text"
                            placeholder="Type your answer here..."
                            value={fullDataForm?.values ?? ""}
                            onChange={(e) => {
                              addKeyValueToObjectById(
                                fullDataForm?.id,
                                "values",
                                e.target.value
                              );
                              setValues(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                nextStep1();
                              }
                            }}
                            className="bottom-border my-3"
                          />

                          <div className="d-flex align-items-center">
                            <button
                              onClick={nextStep1}
                              className="btn btn-secondary mt-3 ok "
                            >
                              OK{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="23"
                                fill="currentColor"
                                className="bi bi-check-lg"
                                viewBox="0 0 18 18"
                              >
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                              </svg>
                            </button>
                            <div className="elpVLvv mt-3 ms-2 pressEntr">
                              <span>
                                press <strong>Enter ↵</strong>{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                    </div>
                  )}

                  {fullDataForm.name === "dropdown" && (
                    // <div className='container-fluid d-flex align-items-center justify-not-small vh-100 '>
                    <div className="row justify-content-center align-items-center position-relative">                         
                        <div className="col-md-12">
                          <h2
                            className="fw-normal sorry "
                            style={{
                              fontFamily: `${formDatas?.primaryfont}`,
                              color: `${formDatas?.primarycolor}`,
                            }}
                          >
                            {fullDataForm.label}
                          </h2>
                          <h4
                            className="please mt-2"
                            style={{
                              //fontFamily: `${formDatas?.secondaryfont}`,
                              color: `${formDatas?.secondarycolor}`,
                            }}
                          >
                            {fullDataForm?.description}
                          </h4>

                          <select
                            className="form-control form-select form-select-lg my-3"
                            aria-label=".form-select-lg example"
                            value={fullDataForm?.values ?? ""}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              addKeyValueToObjectById(
                                fullDataForm?.id,
                                "values",
                                selectedValue
                              );
                              setValues(selectedValue);
                            }}
                          >
                            <option disabled>Select your option</option>
                            {Array.isArray(fullDataForm?.options) &&
                              fullDataForm?.options.map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                          </select>
                          <div className="d-flex align-items-center">
                            <button
                              onClick={nextStep1}
                              className="btn btn-secondary mt-3 ok"
                            >
                              OK{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="23"
                                fill="currentColor"
                                className="bi bi-check-lg"
                                viewBox="0 0 18 18"
                              >
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                    </div>
                  )}

                  {fullDataForm.name == "multichoice" && (
                    // <div className='container-fluid d-flex align-items-center justify-content-center vh-100'>
                    <div className="row justify-content-center align-items-center position-relative">
                        <div className="col-md-12">
                          <h2
                            className="fw-normal sorry database "
                            style={{
                              fontFamily: `${formDatas?.primaryfont}`,
                              color: `${formDatas?.primarycolor}`,
                            }}
                          >
                            {fullDataForm?.label}
                          </h2>
                          <h4
                            className="please mt-2"
                            style={{
                              //fontFamily: `${formDatas?.secondaryfont}`,
                              color: `${formDatas?.secondarycolor}`,
                            }}
                          >
                            {fullDataForm?.description}
                          </h4>
                          <div className="my-3 multicolorUpr">
                            {fullDataForm?.options?.map((item, index) => {
                              return (
                                <div
                                  className="multicolor"
                                  onClick={() => {
                                    setSelectIndex(index);
                                    addKeyValueToObjectById(
                                      fullDataForm?.id,
                                      "values",
                                      item?.question
                                    );
                                  }}
                                >
                                  {/* <h1></h1> */}
                                  <div
                                    key={index}
                                    style={{
                                      cursor: "pointer",
                                      padding: "7px 5px",
                                      border: "1px solid #ccc",
                                      borderRadius: 4,
                                      marginBottom: "0px",
                                      textAlign: "center",
                                      fontWeight: 500,
                                      backgroundColor:
                                        index === selectIndex ? "#425869" : window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'                                        ,
                                    }}
                                   // onClick={() => handleOptionClick(choice)}
                                  >
                                    {item?.question}
                                  </div>
                                  {/* <input
                              type='text'
                              className='form-control  '
                              disabled
                              placeholder={`Choise ${index}`}
                              name='role_name'
                              value={item?.question}
                              
                            /> */}
                                </div>
                              );
                            })}
                          </div>

                          <button
                            onClick={nextStep1}
                            className="btn btn-secondary mt-3 ok"
                          >
                            OK{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="23"
                              fill="currentColor"
                              className="bi bi-check-lg"
                              viewBox="0 0 18 18"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                          </button>
                        </div>
                    </div>
                  )}
                  {fullDataForm.name == "radio" && (
                    // <div className='container-fluid d-flex align-items-center justify-content-center vh-100 '>
                    <div className="row justify-content-center align-items-center position-relative">
                        {/* <img src={formDatas?.filename} className="img-fluid imgcss" /> */}
                        <div className="col-md-12 ">
                          <h2
                            className="fw-normal sorry yes-no"
                            style={{
                              fontFamily: `${formDatas?.primaryfont}`,
                              color: `${formDatas?.primarycolor}`,
                            }}
                          >
                            {fullDataForm.label}
                          </h2>
                          <h4
                            className="please mt-2"
                            style={{
                              //fontFamily: `${formDatas?.secondaryfont}`,
                              color: `${formDatas?.secondarycolor}`,
                            }}
                          >
                            {fullDataForm?.description}
                          </h4>

                          <div className="d-flex align-items-center my-3">
                            <div className="radio-yes">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="yesOption"
                                // value={'yes'}
                                checked={fullDataForm?.values === "yes"}
                                // checked={selectedOption === 'yes'}
                                onChange={() => {
                                  addKeyValueToObjectById(
                                    fullDataForm?.id,
                                    "values",
                                    "yes"
                                  );
                                  setValues("yes");
                                }}
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor="yesOption"
                              >
                                {`${fullDataForm?.firstValues
                                    ? fullDataForm?.firstValues
                                    : "Yes"
                                  }`}
                              </label>
                            </div>

                            <div className="radio-no">
                              <input
                                type="radio"
                                className="form-check-input "
                                id="noOption"
                                // value={'no'}
                                checked={fullDataForm?.values === "no"}
                                onChange={() => {
                                  addKeyValueToObjectById(
                                    fullDataForm?.id,
                                    "values",
                                    "no"
                                  );

                                  setValues("no");
                                }}
                              />
                              <label
                                className="form-check-label ms-2"
                                htmlFor="noOption"
                              >
                                {`${fullDataForm?.secondValues
                                    ? fullDataForm?.secondValues
                                    : "No"
                                  }`}
                              </label>
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            <button
                              onClick={nextStep1}
                              className="btn btn-secondary mt-3 ok "
                            >
                              OK{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="23"
                                fill="currentColor"
                                className="bi bi-check-lg"
                                viewBox="0 0 18 18"
                              >
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                              </svg>
                            </button>
                            <div className="elpVLvv mt-3 ms-2 pressEntr">
                              <span>
                                press <strong>Enter ↵</strong>{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="container-fluid d-flex align-items-center justify-content-center vh-100 text-center">
                    <div className="row">
                      <div className="col-md-12 genrateInfoBtns">
                        <h2 className="text-spacing thank-you-size">
                          Thank you for your feedback, {name}!
                        </h2>
                        {formDatas.is_algen === true && (<>

                          <h2 className="text-spacing would-you-please">
                            Would you please take a moment to review us on Google now?
                            We appeciate you!
                          </h2>
                          <h4 className="chooseTagAI">
                            Choose tags below, and let our AI tool assist in
                            auto-generating your review.
                          </h4>
                          {formDatas?.tags?.map((tag) =>
                            (<>
                        <button
                          key={tag}
                            className={` m-2 ${selectedTags.includes(tag) ? "btn btn-light-primary active" : "btn btn-light-primary"
                              }`}
                            onClick={() => handleTagClick(tag)}
                          > {selectedTags.includes(tag) &&<CheckOutlined />}
                            {tag}
                          </button>
                        </> ) )}
                          {/* <button
                            className={`btn btn-secondary m-2 ${selectedTags.includes("reliable") ? "active" : ""
                              }`}
                            onClick={() => handleTagClick("reliable")}
                          >
                            reliable
                          </button>
                          <button
                            className={`btn btn-secondary m-2 ${selectedTags.includes("satisfactory") ? "active" : ""
                              }`}
                            onClick={() => handleTagClick("satisfactory")}
                          >
                            satisfactory
                          </button>
                          <button
                            className={`btn btn-secondary m-2 ${selectedTags.includes("peaceful") ? "active" : ""
                              }`}
                            onClick={() => handleTagClick("peaceful")}
                          >
                            peaceful
                          </button>
                          <button
                            className={`btn btn-secondary m-2 ${selectedTags.includes("excellent") ? "active" : ""
                              }`}
                            onClick={() => handleTagClick("excellent")}
                          >
                            excellent
                          </button> */}
                          {/**comment dropdown */}
                          <div className="selected-tags-container">
                            <div className="selected-tags">
                          <TagsInput
              inputProps={{
                placeholder: 'Write your own attributes And press Enter'
              }}
              className='tag-box react-tagsinput '
              maxTags={10}
              value={tags}
              // removeKeys={'Backspace'}

              onChange={handleInputChange} /></div></div>
                          {/* <div className="selected-tags-container">
                            <div className="selected-tags">
                              {selectedTags.map((tag) => (
                                <>
                                  <div key={tag} className="selected-tag mb-2">
                                    {tag}

                                    <button
                                      className="close-btn"
                                      onClick={() => {
                                        handleTagClick(tag);
                                      }}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div> */}

                          {/* <div
                      className="spin text-center"
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {loading && (
                        <h1>loading</h1>
                        // <TailSpin
                        //   type='TailSpin'
                        //   color='#00BFFF'
                        //   height={50}
                        //   width={50}
                        //   style={{
                        //     display: 'inline-block',
                        //     verticalAlign: 'middle',
                        //   }}
                        // />
                      )}
                    </div> */}
                          <div className="row">
                            <div className="flex-row-fluid sz mx-auto ">
                              <div className="genrateReview">
                                {/* <div className="selected_parent genrateReviewbtn">
                                  <div className="slect-size">
                                    <Select
                                      isMulti
                                      options={options}
                                      value={selectedOptions}
                                      onChange={handleSelectChange}
                                      styles={customStyles}
                                      className="genrateInfo"
                                    />
                                  </div>
                                </div> */}
                                <div className="genrateReviewbtn">
                                  <div className="">
                                    {!spin ? (
                                      <button
                                        className="btn btn-secondary"
                                        onClick={handleTagsGeneration}
                                      // disabled={loading || selectedTags.length === 0}
                                      >
                                        Generate Review
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-secondary"
                                        onClick={handleTagsGeneration}
                                      // disabled={loading || selectedTags.length === 0}
                                      >
                                        Generate Review
                                        <span>
                                          {" "}
                                          <Spin className="custom-spin" />
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </div>

                                  {review.length > 0 && (
                                   <div className="genrateReviewbtn">
                                    <div className="genrateReviewArw">
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-chevron-left"
                                          viewBox="0 0 16 16"
                                          // style={{ marginLeft: "48px", cursor: "pointer" }}
                                          onClick={previousData}
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                          />
                                        </svg>
                                      </div>
                                      <div
                                      // style={{ marginLeft: "10px" }}
                                      >
                                        {" "}
                                        {currentIndex + 1} / {review.length}{" "}
                                      </div>
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-chevron-right"
                                          viewBox="0 0 16 16"
                                          // style={{ marginLeft: "10px", cursor: "pointer" }}
                                          style={{ cursor: "pointer" }}
                                          onClick={nextData}
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                    </div>
                                  )}
                              

                              </div>
                            </div>
                          </div>

                          {/* <div
                      className="spin text-center"
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {loading && (
                        <h1>loding</h1>
                        // <TailSpin
                        //   type='TailSpin'
                        //   color='#00BFFF'
                        //   height={50}
                        //   width={50}
                        //   style={{
                        //     display: 'inline-block',
                        //     verticalAlign: 'middle',
                        //   }}
                        // />
                      )}
                    </div> */}

                          <textarea
                            className="form-control mx-auto my-3"
                            value={review[currentIndex]}
                            onChange={(e) => handleChange("review", e.target.value)}
                            onCut={handleTextCut}
                            onKeyDown={handleKeyDown}
                            id="exampleTextarea"
                            rows="5"
                          ></textarea>
                          <button
                            className="btn btn-secondary m-2"
                            onClick={(e) => {
                              copyText(e);
                            }}
                          >
                            <i
                              className={`bi ${clicked ? "bi-check-circle " : "bi-copy"
                                }`}
                            ></i>
                            {clicked ? "Copied to clipboard" : "Copy to clipboard"}
                          </button>

                          <button
                            onClick={(e)=>{submitReview()
                              copyText(e)}
                            }
                            className="btn btn-secondary m-2"
                            style={{ backgroundColor: "black", color: "orange" }}
                          >
                            Leave us a Review
                          </button></>)}
                      </div>
                    </div>
                  </div>
                  {/* <div className='container-fluid d-flex align-items-center justify-content-center vh-100 text-center'>
                <div className='row'>
                  <div className='col-md-12'>
                    <h2 className='text-spacing'>
                      Thank you for your feedback, Ruchi Agarwal!
                    </h2>
                    <h2 className='text-spacing m-5'>
                      Would you please take a moment to review us on Google now?
                      We appeciate you!
                    </h2>
                    <h4 className='lighter'>
                      Choose tags below, and let our AI tool assist in
                      auto-generating your review.
                    </h4>
                    <button className='btn btn-secondary m-2'>Tag 1</button>
                    <button className='btn btn-secondary m-2'>Tag 2</button>
                    <button className='btn btn-secondary m-2'>Tag 3</button>
                    <button className='btn btn-secondary m-2'>Tag 4</button>
                    <button className='btn btn-secondary m-2'>Tag 5</button>
                    <textarea
                      className='form-control sz mx-auto my-3'
                      value={review}
                      onChange={(e) => handleChange('review', e.target.value)}
                      id='exampleTextarea'
                      rows='5'
                      s
                    ></textarea>
                    <button
                      className='btn btn-secondary m-2'
                      onClick={() => {}}
                    >
                      Copy to clipboard
                    </button>

                    <button
                      onClick={submitReview}
                      className='btn btn-secondary m-2'
                      style={{ backgroundColor: 'black', color: 'orange' }}
                    >
                      Leave us a Review
                    </button>
                  </div>
                </div>
              </div> */}
                </>
              )}
            </div>
          )}
          {step === 4 && (
            <div className="container-fluid d-flex align-items-center justify-content-center vh-100">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="yesno">
                    {" "}
                    Would you like us to follow up with you to further address your
                    concerns?{" "}
                  </h2>

                  <select
                    className="form-control form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    onChange={(e) => {
                      const selectedValue = e.target.value;

                      if (selectedValue === "1") {
                        setStep(step + 1);
                        setWouldRecommend("Yes");
                      } else if (selectedValue === "2") {
                        setStep(step + 2);
                        setWouldRecommend("No Thanks");
                      }
                    }}
                  >
                    <option selected>Select your option</option>
                    <option value="1">Yes</option>
                    <option value="2">No Thanks</option>
                  </select>

                  <button onClick={nextStep} className="btn btn-secondary mt-2 ok">
                    OK{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      fill="currentColor"
                      className="bi bi-check-lg"
                      viewBox="0 0 18 18"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
          {step === 5 && (
            <div className="container-fluid d-flex align-items-center justify-content-center vh-100 ">
              <div className="row">
                 

                <div className=" col-md-12">
                  <h2 className="text-spacing fw-normal">
                    Please leave an email address or phone number we can connect
                    with you. This question is required.*
                  </h2>
                  <h4 className="please">
                    Your privacy is important to us. This information will only be
                    used in association with this form.
                  </h4>

                  <input
                    type="text"
                    placeholder="Type your answer here..."
                    value={contactInfo}
                    onChange={(e) => handleChange("contactInfo", e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        nextStep();
                      }
                    }}
                    className="bottom-border bottom-border-width"
                  />
                  <div>
                    <div className="d-flex align-items-center">
                      <button
                        onClick={nextStep}
                        className="btn btn-secondary mt-2 ok "
                      >
                        OK{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="currentColor"
                          className="bi bi-check-lg"
                          viewBox="0 0 18 18"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </button>
                      <div className="elpVLv pressEntr">
                        press <strong>Enter ↵</strong>
                      </div>
                    </div>
                  </div>
                </div>
                 
              </div>
            </div>
          )}
          {step === 6 && (
            <div className="container-fluid d-flex align-items-center justify-content-center vh-100">
              <div className="row">
                <div className="col-md-12  text-align-center">
                  <h2 className="thanks thankUser">
                    {/* Thanks for your time. We value your feedback {name}! */}
                    Thank you for your feedback, {name}!
                  </h2>
                </div>
              </div>
            </div>
          )}
     </div>
  );
};

export default Index;
