//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const productInitial = [];

export const productReducer = (state = productInitial, action) => {
   
    switch (action.type) {
       
        case ActionTypes.product.createProductSuccess:
         
           return [action.payload,...state ];
        //case ActionTypes.coupon.createCouponFail:
        
        case ActionTypes.product.deletProductSuccess:
            return state.filter(x => x.productId !== action.payload.productId);
        case ActionTypes.product.updateProductSuccess:
            return state.map(x => {
                if (x.productId === action.payload?.productId) {
                    return { ...x, ...action.payload };
                }
                return x;
            });      
      
   
        case ActionTypes.product.getProductSuccess:
            return action.payload;
        // case ActionTypes.coupon.getCouponFail:
        //     return state;
        // case ActionTypes.coupon.deletCouponFail:
        //     return {
        //         ...state,
        //         loading: false,
               
        //         error: action.payload,
        //       };
        // case ActionTypes.coupon.countCoupon:
        //     return {
        //         ...state,
        //         count:action.payload
        //     }
        default:
            return state;
    }
};

