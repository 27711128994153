export const packageTable = [
  //{Dbfield: 'packageid', alias: 'pid'},
  {Dbfield: 'packagename', alias: 'pName'},
  {Dbfield: 'subtitle', alias: 'ptitle'},
  {Dbfield: 'images', alias: 'pimg'},
  {Dbfield: 'sectiondetails', alias: 'psec'},
  {Dbfield: 'startdate', alias: 'psdata'},
  {Dbfield: 'enddate', alias: 'pedata'},
  {Dbfield: 'duration', alias: 'pduration'},
  {Dbfield: 'durationtype', alias: 'pdtype'},
  {Dbfield: 'night', alias: 'pnight'},
  {Dbfield: 'nightduration', alias: 'pnightd'},
  {Dbfield: 'cutofftime', alias: 'pcut'},
  {Dbfield: 'cutofftimetype', alias: 'pcuttype'},
  {Dbfield: 'minimumage', alias: 'pminage'},
  {Dbfield: 'maximumage', alias: 'pmaxage'},
  {Dbfield: 'amount', alias: 'pamount'},
  {Dbfield: 'status', alias: 'pstatus'},
]
export const Branding = [
  { Dbfield: 'companyname', alias: 'cName' },
  { Dbfield: 'style_name', alias: 'cStyle' },
  { Dbfield: 'primarycolor', alias: 'pColor' },
  { Dbfield: 'secondarycolor', alias: 'sScolor' },
  { Dbfield: 'primaryfont', alias: 'pFont' },
  { Dbfield: 'secondaryfont', alias: 'sFont' },
];
export const company = [
  // { Dbfield: "user_id", alias: "uid" },
  {Dbfield: 'company_name', alias: 'cName'},
  // {Dbfield: 'email', alias: 'cEmail'},
  // { Dbfield: "create_date", alias: "cCreated" }
]
export const agents = [
  {Dbfield: 'agentsid', alias: 'aid'},
  {Dbfield: 'agentphoto', alias: 'aphoto'},
  {Dbfield: 'companyname', alias: 'cname'},
  {Dbfield: 'timezone', alias: 'tzone'},
  {Dbfield: 'operatingcity', alias: 'ocity'},
  {Dbfield: 'websiteUrl', alias: 'wurl'},
  {Dbfield: 'companydetails', alias: 'cdetails'},
  {Dbfield: 'joiningdate', alias: 'jdate'},
]

// export const teamEmploye = [
//   // { Dbfield: "user_id", alias: "uid" },
//   {Dbfield: 'user_name', alias: 'uName'},
//   {Dbfield: 'reporting_manager', alias: 'rManager'},
//   {Dbfield: 'email', alias: 'uEmail'},
//   {Dbfield: 'phone_number', alias: 'uPhone'},
//   {Dbfield: 'alternate_phone_number', alias: 'ualternate_phone_number'},
//   {Dbfield: 'whatsapp', alias: 'uWhatsapp'},
//   {Dbfield: 'is_admin', alias: 'uIs_admin'},
// ]
export const googleReview=[
  {Dbfield: 'form_name', alias: 'fName'},
  // {Dbfield: 'Questions', alias: 'Questions'},
  // {Dbfield: 'Resspon', alias: 'Resspon'},

]
export const teamEmploye = [
 // { Dbfield: "user_id", alias: "uid" },
  { Dbfield: "user_name", alias: "uName" },
  // { Dbfield: "reporting_manager", alias: "rManager" },
  { Dbfield: "email", alias: "uEmail" },
  {Dbfield: 'phone_number', alias: 'uPhone'},
  // {Dbfield: 'alternate_phone_number', alias: 'uPhone2'},
  { Dbfield: "whatsapp", alias: "uWhatsapp" },
  // {Dbfield:"created_at",alias:"ucreated"}
];
export const Customer = [
  { Dbfield: "lead_id", alias: "lid" },
  { Dbfield: "fullname", alias: "lName" },
  // { Dbfield: "reporting_manager", alias: "rManager" },
  { Dbfield: "email", alias: "lEmail" },
  
];
export const customer = [
  { Dbfield: "Customer fullname", alias: "cName" },
  { Dbfield: "Customer phone", alias: "cphone" },
  {Dbfield:'Customer email',alias:"cmail"}
  
];
export const userRole = [
  // { Dbfield: "user_id", alias: "uid" },
  {Dbfield: 'role_name', alias: 'role_name'},
];
export const feedBack = [
  {Dbfield: 'person_name', alias: 'pName'},
  {Dbfield: 'feedback_score', alias: 'fScore'},
  { Dbfield: "date", alias: "cCreated" }
]
export const Alias = (data, tableName) => {
  return tableName?.filter((item) => item.Dbfield === data)[0]?.alias
}
