import {useEffect} from 'react'
// import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  MixedWidget8,
  MixedWidget3,
  ListsWidget9,
  TablesWidget9,
  ListsWidget3,
  ListsWidget2,
  ListsWidget6,
  TablesWidget5,
} from '../../../_metronic/partials/widgets'
import StatisticsWidget4 from '../../../_metronic/partials/widgets/statistics/StatisticsWidget4'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        {/* <div className='col-xxl-4'>
          <MixedWidget3
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='250px'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-12'>
          <TablesWidget9 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xxl-12'>
          <MixedWidget3
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='250px'
          />

        {/* <MixedWidget3
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='250px'
          /> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-xxl-4'>
          <ListsWidget9 className='card-xxl-stretch mb-xl-8' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-xxl-4'>
          <ListsWidget4 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div> */}
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      
      {/* end::Row */}

      {/* begin::Row */}
     
      {/* end::Row */}

      {/* begin::Row */}
   
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  // const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
      Dashboard
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
