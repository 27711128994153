import axios, {AxiosResponse} from 'axios'
import {GetUsersResponse} from './type'
import CryptoJS from 'crypto-js'
import getToken from '../apps/Token'
const secretKeys = 'my-secret-key'
const encryptData = (data: any, secretKey: string = secretKeys) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey)
  return encryptedData.toString()
}

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
  },
})

export const getPacked = (): Promise<GetUsersResponse> => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/user/Packages`)
    .then((d: AxiosResponse<GetUsersResponse>) => d.data)
}

export const packageData = async () => api.get(`user/Packages`)
export const getpackageDataRec = () => api.get(`/user/PackagesRecently`)
export const deletepackage = (deleteid: any) => api.delete(`/user/Packages${deleteid}`)
export const searchpackage = (search: any) => api.get(`/packages/packages/${search}`)
export const columnList = () => api.get(`/packages/columns`)

// export const getMainPackage = (packageData: any) => {
//   const encryptedData = encryptData(packageData)
//   return axios.post(
//     `${process.env.REACT_APP_API_URL}/packages/packages/`,
//     JSON.stringify({encryptedData}),
//     {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     }
//   )
// }

///getMainAgent  Api

export const getMainAgent = (agentData: any) => {
  const encryptedData = encryptData(agentData)

  return axios.post(
    `${process.env.REACT_APP_API_URL}/agent/agent`,
    JSON.stringify({encryptedData}),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export const deleteAgent = (agentData: any) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/Agent/delete`, agentData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

///Team Api
export const saveTeamMember = (teamData: any,token:any) => {
  axios.post(`${process.env.REACT_APP_API_URL}/team/team`, teamData, {
    headers: {
      'Content-Type': 'application/json',
      token:token
    },
  })
}
//getTeamMember
export const getTeamMember = (teamData: any,token:any) =>
  axios.post(`${process.env.REACT_APP_API_URL}/team/getTeamMember`, teamData, {
    headers: {
      'Content-Type': 'application/json',
      token:token
    },
  })

  export const getAllForm = (teamData: any,token:any) =>
  axios.post(`${process.env.REACT_APP_API_URL}/googlereview/getAllForm`, teamData, {
    headers: {
      'Content-Type': 'application/json',
      token:token,
      authorizationtoken: getToken()
    },
  })
  export const getAllBranding = (teamData: any,token:any) =>
  axios.post(`${process.env.REACT_APP_API_URL}/googlereview/getAllBrandList`, teamData, {
    headers: {
      'Content-Type': 'application/json',
      token:token,
      authorizationtoken: token
    },
  })
  export const getFeedback = (teamData: any,token:any) =>
  axios.post(`${process.env.REACT_APP_API_URL}/googlereview/getAllFeedBack`, teamData, {
    headers: {
      'Content-Type': 'application/json',
      token:token
    },
  })
//Team Delete APi call
export const deleteTeamMember = (teamData: any,token:any) => {
  //deleteteam
  return axios.post(`${process.env.REACT_APP_API_URL}/team/deleteteam`, teamData, {
    headers: {
      'Content-Type': 'application/json',
      token:token,
    },
  })
}
export const deleteFeedback = (teamData: any,token:any) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/GoogleReview/DeleteFeedback`, teamData, {
    headers: {
      'Content-Type': 'application/json',
      token:token,
    },
  })
}

export const updateTeamMembers = (id: number, teamData: any,token:any) =>
  axios.put(`${process.env.REACT_APP_API_URL}/team/team/${id}`, teamData,{
    headers: {
      'Content-Type': 'application/json',
      token:token,
    },
  })

//getTeamMemberOne
export const getTeamMemberOne = (teamData: any,token:any) =>
  axios.post(`${process.env.REACT_APP_API_URL}/team/getTeamMemberOne/${teamData}`,{
    headers: {
      'Content-Type': 'application/json',
      token:token,
    },
  })

///photo and pdf upload{
export const photoupload = (photo: any) =>
  axios.post(`${process.env.REACT_APP_API_URL}/team/upload-image`, photo, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const photouploads = async (photo: any) => {
  var formData = new FormData()
  if (photo?.length) {
    formData.append('photo', photo[0])
    let fileName = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/upload-image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return fileName?.data?.filename
  }
}

export const photodelete = (photoName: any) =>
  axios.delete(`${process.env.REACT_APP_API_URL}/team/upload-image/${photoName}`)
///pdfupload
export const pdfupload = (photo: void) => {
  axios.post(`${process.env.REACT_APP_API_URL}/team/upload-pdf`, photo, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const checkCategoryMatch = (data: any, newdata: any, category_name: any) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].category_name === category_name) {
      const functions = data[i].functions
      for (let j = 0; j < functions.length; j++) {
        if (newdata.includes(functions[j].id)) {
          return true
        }
      }
      break
    }
  }
  return false
}

// function checkCategoryMatch(data, newdata, category_name) {
//   for (let i = 0; i < data.length; i++) {
//     if (!category_name || data[i].category_name === category_name) {
//       const functions = data[i].functions;
//       for (let j = 0; j < functions.length; j++) {
//         if (newdata.includes(functions[j].id)) {
//           return true;
//         }
//       }
//     }
//   }
//   return false;
// }

export const getCustomerData = (leadData: any,token:any) =>
  axios.post(`${process.env.REACT_APP_API_URL}/Lead/getCustomers`, leadData, {
 
    headers: {
      'Content-Type': 'application/json',
      token:token
    },
  })




  export const imagesUploadaws= async(data:any) => {
    const formData = new FormData()
    formData.append('file', data)

    try {
      const response = await fetch(`${process.env.REACT_APP_IMAGES_URL}`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error; 
    }

  }