import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'

const DeletePopup = ({show, handleClose, handleDelete,loading, loader = false}) => {
  const modalContentStyle = {
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  }
  return (
    <Modal show={show}
      className='modal-wide'
      dialogClassName='modal-dialog modal-dialog-centered'>
        <div className='modal-header custom-delete-modal-header'>
          <h5 className='modal-title fs-6'>Deletion</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2 '
            onClick={handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='setDelete fw-bolder fs-5 mb-4 deletepopupfix'>Are You Sure?</div>
        <p className='setDelete  fw-semibold fs-6 mb-6 confirmmsg'>
          Are you sure you want to delete this form? This action cannot be undone.
        </p>
        <div className='text-center mb-5'>
          <button
            type='submit'
            className='btn btn-danger'
            data-kt-users-modal-action='submit'
            // onClick={toHandleDelete}
            onClick={handleDelete}
          >
            {!loading && 'yes'}
            {loading && (
              <span className='indicator-progress fixloadersite'>
                Delete...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='reset'
            className='btn btn-light me-3 editbutton'
            data-kt-users-modal-action='cancel'
            onClick={handleClose}
          >
            No
          </button>
        </div>
    </Modal>
  )
}

export default DeletePopup
