//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import {ActionTypes} from '../constants'

const _ = require('lodash')

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const feedbackintial = []

export const feedbackValuesReducer = (state = feedbackintial, action) => {
  switch (action.type) {
    case 'feedbacks':
      return action.payload

    default:
      return state
  }
}
