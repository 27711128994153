import {
    FC,
    useState,
    createContext,
    useContext,
  } from 'react'
  import {WithChildren} from '../../../../_metronic/helpers'
  
  type AuthContextProps = {
    setfunctionCategory:(data:any)=>void
    functionCategory:any
    setfunction:(data:any)=>void
    functionName:any
  }
  
  const initAuthContextPropsState = {
    setfunction:()=>{},
    functionName:undefined,
    setfunctionCategory:()=>{},
    functionCategory:undefined

  }
  
  const FunctionProvider = createContext<AuthContextProps>(initAuthContextPropsState)
  
  const useFunction = () => {
    return useContext(FunctionProvider)
  }

  
  
  const FunctionProviders: FC<WithChildren> = ({children}) => {
    const [functionName, setfunction] = useState<any>()
    const [functionCategory,setfunctionCategory]=useState<any>()
  
    const saveFunction = (auth:any) => {
        setfunction(auth)
    
    }
  
   
  
    return (
      <FunctionProvider.Provider value={{functionName, setfunction,setfunctionCategory,functionCategory}}>
        {children}
      </FunctionProvider.Provider>
    )
  }
  
 
  
  export {FunctionProviders, useFunction}
  