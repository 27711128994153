import React from 'react'

import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
declare module 'jspdf' {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}
export const handleChangePrint = (e: React.ChangeEvent<HTMLSelectElement>, data: any) => {
  console.log(e);
  
   const convertToCSV = (json: any[]) => {
    const header = Object.keys(json[0])?.join(',');
    const rows = json.map(row => Object.values(row)?.join(','));
    return header + '\n' + rows.join('\n');
  };
  if (e.target.value === 'PDF') {
    const doc: any = new jsPDF();

    // Load your image
    const logoImg = '/media/GoogleRev/revlogoLogos-06.png';

    // Define the width and height for the logo
    const logoWidth = 40; // Adjust as needed
    const logoHeight = 25; // Adjust as needed

    // Header function to add logo
    const header = function() {
        doc.addImage(logoImg, 'PNG', doc.internal.pageSize.getWidth() / 2 - logoWidth / 2, 10, logoWidth, logoHeight);
    };

    // Iterate through each page
    for (let pageNum = 1; pageNum <= doc.internal.getNumberOfPages(); pageNum++) {
        // Set current page
        doc.setPage(pageNum);

        // Add header to each page
        header();

        // Add the table content
        const columns = Object.keys(data[0])?.map(item => item);
        const rows = data?.map((item: any, index: any) => [index + 1, item.lowfeedbackpoint, item.person_name, item.feedback_score, item.date, item.form_name]);

        doc.autoTable({
            head: [['ID', 'Low Feedback Point', 'Person Name', 'Feedback Score', 'Date', 'Form Name']],
            body: rows,
            startY: 40 // Adjust startY to make space for the header
        });

        // If it's not the last page, add a new one
        if (pageNum !== doc.internal.getNumberOfPages()) {
            doc.addPage();
        }
    }

    // Save the PDF
    doc.save('data.pdf');
}




  if (e.target.value === 'PRINT') {
    const input = document.getElementById('print-button') as HTMLInputElement | null
    input?.click()
  }
  if (e.target.value === 'EXCEL') {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    let fileName = 'excelData'

    const ws = XLSX.utils.json_to_sheet(data)
    const wb = {Sheets: {data: ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const datas = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(datas, fileName + fileExtension)
  }
  if (e.target.value === 'CSV') {
    const fileType = 'text/csv';
    const fileExtension = '.csv';
    const fileName = 'data';

    // Convert JSON data to CSV format
    const csvData = convertToCSV(data);

    // Create Blob from CSV data
    const csvBlob = new Blob([csvData], { type: fileType });

    // Trigger download using FileSaver.js
    FileSaver.saveAs(csvBlob, fileName + fileExtension);
  }
 
}
