import React, { useEffect, useState } from 'react'
import { getsector, sectorApi } from './core/_requests'
import { useAuth } from '../auth'
import { useNavigate} from 'react-router-dom';



export const Sectors = () => {
  const navigate = useNavigate();

  const { auth, currentUser, setCurrentUser } = useAuth()

  const [loading, setLoading] = useState(false)

  const [sector, setSector] = useState<any>('')
  const [sectors, setSectors] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  //const company_id = location?.state?.company_id ? location?.state?.company_id : location?.state
  let token: any = auth?.api_token

  const selectsSector = async (e: any) => {
    const selectedSector = sector;
    if(!selectedSector){
      setErrorMessage('Please select atleast one sector.')
    }
    e.preventDefault()
    if (currentUser) {
      const { company_id } = currentUser
    try {
        const { data: auths } = await sectorApi({ token, company_id, sector })
        setLoading(true)
        if (auths.code === 201) {
          setCurrentUser({ ...auths.data, invitePages: true })
        }
      } catch (e) {
        setLoading(false)
      }
    }
  }
  const getsectors = async () => {
    const { data } = await getsector(token)
    setSectors(data)
  }
  useEffect(() => {
    getsectors()
  }, [])
  return (
    <>
      <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
        <div className='w-lg-500px p-10'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={selectsSector}
          >
            <div className='text-center mb-10'></div>

            <div className='fv-row mb-4'>
              <label className='fs-6 fw-semibold mb-2'>
                Please select your sector of work from the options below
              </label>
              <div className='position-relative d-flex align-items-center  '>
                <select
                  name='country'
                  aria-label='Select a Country'
                  data-control='select2'
                  data-placeholder='Select a Country...'
                  data-dropdown-parent='#kt_modal_update_customer'
                  className='form-select form-select-solid '
                  value={sector}
                  onChange={(event) => {
                    setSector(event.target.value)
                    setErrorMessage('')
                  }}
                >
                  <option>Select a Sector...</option>
                  {Array.isArray(sectors)&&sectors?.map((sector: any, index) => (
                    <option key={index} value={sector.sector_id}>
                      {sector.sector_name || `Unknown ${index + 1}`}
                    </option>
                  ))}
                </select>
                </div>
                {errorMessage && <p className='loginfixderror'>{errorMessage}</p>}
            </div>
            <div className='mt-5'>
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress fixloadersite'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div></div>
          </form>
        </div>
      </div>
    </>
  )
}
