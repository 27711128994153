import React, { useState} from 'react'
import ReactToPrint from 'react-to-print'
import {UsersListFilters} from '../../UsersListFilters'

import {User} from '../../../ApiCall/type'
import MultiSelectDropdown from '../../../Component/pagination/selecter'
import CryptoJS from 'crypto-js'
import {DeleteData} from '.././DeleteData'
// import {getMainPackage} from '../../profileApi'
// import {useQueryResponse} from '../../../agents/core/QueryResponseProvider'
import {handleChangePrint} from '../../../Component/helper/print'

type ButtonClickHandler = () => void

interface MyComponentProps {
  showListBtn: any
  isAddBtn?: any
  addTitle: any
  functionName:any
  MultiSelectOptions: any
  MultiSelectValue: string[]
  setMultiSelectValue: any
  setfilterfunction: any
  fullData: any
  componentRef: any
  componentRefcol: any
  onAddBtn: ButtonClickHandler
  onDeleteBtn: ButtonClickHandler
  filterData: any
  isSelected: any
  onMultiSelectChange:any
  abcd: (data: any) => void
}

export const ListHeader: React.FC<MyComponentProps> = ({
  showListBtn,
  isAddBtn = false,
  addTitle,
  MultiSelectOptions,
  MultiSelectValue,
  setMultiSelectValue,
  setfilterfunction,
  fullData,
  functionName,
  componentRef,
  componentRefcol,
  onAddBtn,
  onDeleteBtn,
  filterData,
  isSelected,
  abcd,
  onMultiSelectChange
}) => {
  // const {isLoading} = useQueryResponse()
 

  const [search, setSearch] = useState<any>('')
  // const [data1, setData1] = useState<User[]>([] as User[])
  const [rowColumn, setRowColumn] = useState('column')


  // const [valueDropDown, setValueDropDown] = useState(['pName', 'ptitle', 'pimg'])
  // const [isSelected, setIsSelected] = useState<any>([])
  const secretKey = 'my-secret-key'
  const decryptData = (encryptedData: any) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  }
var newData = fullData.map((obj:any)=> {
  // Create a new object without the sector_id property
  var { sector_id, ...newObj } = obj;
  return newObj;
});

  // const getpackageDataApi = async () => {
  //   let data = {
  //     fieldName: valueDropDown,
  //     sorting: sorting,
  //     pagination: {pages: paginationDataApi?.pages || 1, limits: paginationDataApi.limits || 2},

  //     filters: condition,
  //   }

  //   const packageData: AxiosResponse<any> = await getMainPackage(data)

  //   const {currentPage, totalPages, totalCount, data: filterData} = decryptData(packageData.data)

  //   setPaginationData({
  //     currentPage,
  //     totalPages,
  //     totalCount,
  //   })

  //   setData1(filterData)
  // }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setRowColumn(e.currentTarget.id)
  }
  const handleMultiSelectChange = (selectedOptions:any) => {
    onMultiSelectChange(selectedOptions);
  };

  return (
    <>
      <div className='d-flex flex-wrap flex-stack pb-7 mt-10'>
        <div className='d-flex flex-wrap align-items-center my-1'>
          {/* Start Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.5'
                  x='17.0365'
                  y='15.1223'
                  width='8.15546'
                  height='2'
                  rx='1'
                  transform='rotate(45 17.0365 15.1223)'
                  fill='currentColor'
                />
                <path
                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                  fill='currentColor'
                />
              </svg>
            </span>

            <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search'
              value={search}
              onChange={(e) => {
                abcd(e.target.value)
                setSearch(e.target.value)
              }}
            />
          </div>
          {/* end Search */}
        </div>
        <div className='d-flex flex-wrap my-1'  style={{overflowY: 'auto' }}>
          {/* List Btn */}
          {showListBtn == true ? (
            <ul className='nav nav-pills me-6 mb-2 mb-sm-0'>
              {/* <li className='nav-item m-0'>
                <a
                  className='btn btn-active-primary me-5 active'
                  //className='btn btn-sm btn-icon btn-light-primary btn-color-muted me-3 btn-active-primary'
                  data-bs-toggle='tab'
                  href='#kt_project_users_card_pane'
                  onClick={handleClick}
                  id={'row'}
                >
                  <span className='svg-icon svg-icon-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                    >
                      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <rect x='5' y='5' width='5' height='5' rx='1' fill='currentColor' />
                        <rect
                          x='14'
                          y='5'
                          width='5'
                          height='5'
                          rx='1'
                          fill='currentColor'
                          opacity='0.3'
                        />
                        <rect
                          x='5'
                          y='14'
                          width='5'
                          height='5'
                          rx='1'
                          fill='currentColor'
                          opacity='0.3'
                        />
                        <rect
                          x='14'
                          y='14'
                          width='5'
                          height='5'
                          rx='1'
                          fill='currentColor'
                          opacity='0.3'
                        />
                      </g>
                    </svg>
                  </span>
                </a>
              </li> */}
              <li className='nav-item m-0'>
                <a
                  className=' active'
                  //className='btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary'
                  data-bs-toggle='tab'
                  href='#kt_project_users_table_pane'
                  onClick={handleClick}
                  id={'column'}
                >
                  {/* <span className='svg-icon svg-icon-2'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
                        fill='currentColor'
                      />
                      <path
                        opacity='0.3'
                        d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span> */}
                </a>
              </li>
            </ul>
          ) : null}
          <ReactToPrint
            trigger={() => <div id='print-button'></div>}
            content={() => (rowColumn === 'row' ? componentRef.current : componentRefcol.current)}
          />
          {isSelected?.length > 0 ? (
            <DeleteData isSelected={isSelected} deletePackage={onDeleteBtn} />
          ) : (
            <>
              {/* <UsersListFilters fildata={datas} filterfunction={setFilter} /> */}
              <UsersListFilters fildata={MultiSelectOptions} filterfunction={setfilterfunction} />
              <div className='d-flex my-0'>
                <select
                  name='status'
                  onChange={(e) => handleChangePrint(e, newData)}
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Export'
                  className='form-select form-select-sm border-body bg-body w-100px'
                >
                  <option value='EXCEL'>Excel</option>

                  <option value='PRINT'>Print</option>
                </select>
                <div className='d-flex my-0 mx-2'>
                  <MultiSelectDropdown
                    options={MultiSelectOptions}
                    valueDropDown={MultiSelectValue}
                    setValueDropDown={setMultiSelectValue}
                    onOptionChange={handleMultiSelectChange}
                  />
                </div>
                {isAddBtn == true ? (<>
                  {functionName?.includes('Add Sectors') && (
                  <button
                    // disabled={isLoading}
                    type='button'
                    className='btn btn-light-primary me-3'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    onClick={() => {
                      onAddBtn()
                    }}
                  >
                    {addTitle}
                  </button>)}
                  </> ) : null}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
