/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import {FC, Dispatch, SetStateAction, useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import {useFunction} from '../../../../app/modules/auth/core/functionRoles'

const tabs: ReadonlyArray<{link: string; icon: string; tooltip: string; label: string}> = [
  {
    link: 'Reports',
    icon: '/media/icons/duotune/general/gen007.svg',
    tooltip: 'Dashboard',
    label: 'Reports',
  },
  {
    link: 'Review',
    icon: '/media/icons/duotune/general/gen002.svg',
    tooltip: 'Reviews',
    label: 'Review',
  },
  {
    link: 'Feedbacks',
    icon: '/media/icons/duotune/general/gen002.svg',
    tooltip: 'Feedback',
    label: 'Feedbacks',
  },
  {
    link: 'Forms',
    icon: '/media/icons/duotune/general/abs040.svg',
    tooltip: 'Forms',
    label: 'Forms',
  },
  {
    link: 'Branding',
    icon: '/media/icons/duotune/general/gen003.svg',
    tooltip: 'Branding',
    label: 'Branding',
  },
  {
    link: 'organizations',
    icon: '/media/icons/duotune/general/gen003.svg',
    tooltip: 'Organization',
    label: 'manageOrganization',
  },
  {
    link: 'Team',
    icon: '/media/icons/duotune/general/gen008.svg',
    tooltip: 'Team',
    label: 'organizations',
  },
 
  {
    link: 'Subscription',
    icon: '/media/icons/duotune/general/abs040.svg',
    tooltip: 'Subscribers',
    label: 'Subscription',
  },
  {
    link: 'Settings',
    icon: '/media/icons/duotune/general/gen008.svg',
    tooltip: 'Settings',
    label: 'Settings',
  }
]


type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
}

const API_URL = process.env.REACT_APP_API_URL

const AsideTabs: FC<Props> = ({link, setLink}) => {
  const {currentUser, superauth, auth} = useAuth()
  const {setfunction, setfunctionCategory, functionCategory} = useFunction()

 const FinalTab=currentUser?.is_superadmin==1?tabs:tabs.filter((key)=>key.label!=='Subscription')

  const getRolesFunction = () => {
    fetch(
      `${API_URL}/functionRole/userRolesFunction/${currentUser?.user_id}/${currentUser?.company_id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setfunctionCategory(data.functionCategory)
        setfunction(data.functionNames)
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    getRolesFunction()
  }, [])
 
  return (
    <>
      <div
        className='hover-scroll-y mb-10'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-height='auto'
        data-kt-scroll-wrappers='#kt_aside_nav'
        data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
        data-kt-scroll-offset='0px'
      >
          <ul className='nav flex-column' id='kt_aside_nav_tabs'>
            {FinalTab
              ?.filter((key) => functionCategory?.includes(key.label))
              .map((t) => (
                <li key={t.link}>
                  <a
                    className={clsx(
                      'nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light',
                      {active: t.link === link}
                    )}
                    onClick={() => setLink(t.link)}
                  >
                    <KTSVG path={t.icon} className='svg-icon-2x' />
                  </a>
                  <div className='text-gray-400 text-hover-primary mb-1 '>{t.tooltip}</div>
                </li>
              ))}
          </ul>
      
      </div>
    </>
  )
}

export {AsideTabs}
