import axios from 'axios'
import * as ActivityActions from './actions'
import {Dispatch} from 'react'
import getToken from '../../../Token'

const API_URL = process.env.REACT_APP_API_URL
const API_URL1 = `http://localhost:5002`

export const setFormValues = (val) => async (dispatch) => {
  try {
    await dispatch(ActivityActions.setFormData(val))
  } catch (error) {
    console.log('error', error)
  }
}
export const setFormValuesemptyData = (val) => async (dispatch) => {
  try {
    await dispatch({type: 'emptyData', payload: val})
  } catch (error) {
    console.log('error', error)
  }
}

export const setFormValuesData = (val) => async (dispatch) => {
  try {
    await dispatch({type: 'formDataAddval', payload: val})
  } catch (error) {
    console.log('error', error)
  }
}

export const getFormValuesData = (val) => async (dispatch) => {
  try {
    

    const config = {
      headers: {authorizationtoken: getToken(), 'Content-Type': 'application/json'},
    }

    const {data} = await axios.get(`${API_URL}/googlereview/getForm`, config)

    await dispatch({type: 'getformDataAddval', payload: data.data})
  } catch (error) {
    console.log('error', error)
  }
}

export const deleteFromValues = (id) => async (dispatch) => {
  const config = {
    headers: {'Content-Type': 'application/json'},
  }
  try{
  const {data} = await axios.delete(`${API_URL}/googlereview/deleteForm/${id}`, config)

  await dispatch({type: 'deleteformDataAddval', payload: data.data})
}catch(e){
  console.log('e',e)
}
}

export const deleteFromIsSelected = (id) => async (dispatch) => {
  const config = {
    headers: {'Content-Type': 'application/json'},
  }
  try{
  const {data} = await axios.delete(`${API_URL}/googlereview/deleteForm/${id}`, config)

  await dispatch({type: 'deleteformDataAddval', payload: data.data})
  }catch(e){
    console.log('e',e)
  }
}
export const addGoogleForm = async (values) => {
  // let token = sessionStorage.getItem('kt-auth-react-v')kt-auth-react-v-super

 let token1 =await getToken()

  const config = {
    headers: {authorizationtoken: token1, 'Content-Type': 'application/json'},
  }

  const data = await axios.post(`${API_URL}/googlereview/addForm`, values, config)
  return data
}

export const activeDeActiveForm = async (values) => {
  let token = sessionStorage.getItem('kt-auth-react-v')

  let token1 = JSON.parse(token)

  const config = {
    headers: {authorizationtoken: token1?.api_token, 'Content-Type': 'application/json'},
  }

  const {data} = await axios.post(`${API_URL}/googlereview/activeDeActive`, values, config)
}

export const calledit = (data) => async (dispatch) => {
  await dispatch({type: 'editFunction', payload: data})
}
