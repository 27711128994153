/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import clsx from 'clsx'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { useScratch } from 'react-use'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { object } from 'yup'
import { Tooltip } from 'antd'
import _ from 'lodash'
import { useAuth } from '../../../../app/modules/auth'
import { event } from 'jquery'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}
const API_URL = process.env.REACT_APP_API_URL

const MixedWidget3: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [selectYears,setSelectYears]=useState<any>()
  const { currentUser } = useAuth()

  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [account, setAccount] = useState<any>([])

  const [reviewData, setReviewData] = useState({
    averageRating: 0,
    reviews: [],
    totalReviewCount: 0,
  });
  const revToken = localStorage.getItem('bussiness_token')
  const fetchLocationId = async (accessToken: any) => {
    setLoadingSubmit(true)
    try {
      const data={
        company_id:currentUser?.company_id,
        user_id:currentUser?.user_id
      }
      const response = await axios.post(`${API_URL}/Company/getLocationByUserId`, { data });
      response?.data?.data.sort((a:any, b:any) => {
        // Convert titles to lowercase for case-insensitive comparison
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
    
        // Compare the titles
        if (titleA > titleB) {
            return 1; // b comes before a
        }
        if (titleA < titleB) {
            return -1; // a comes before b
        }
        return 0; // titles are equal
    });
      setAccount(response?.data?.data)
      setSelectedLocationId(response?.data?.data[0]?.name)
      const responses = await axios.post(`${API_URL}/Company/getaccount`, { account: response?.data?.data[0]?.account_id });      
      const parsedTime = new Date(responses.data.data.updated_at);
      var access_token = responses.data.data.access_token
      // Calculate the current time
      const currentTime = new Date();
      // Calculate the time one hour ago
      const oneHourAgo = new Date(currentTime.getTime() - (1 * 60 * 60 * 1000)); // 1 hour in milliseconds
      // Compare the given time with the time one hour ago
      const date1 = new Date(parsedTime);
      const date2 = new Date(oneHourAgo);
      if (date1 < date2) {         
        const data = await axios.post(`${API_URL}/refres_token`, { refresh_token: responses.data.data.ref_token, account_id: response?.data?.data[0]?.account_id });
        access_token = data?.data
      }
      const value = {
        token: access_token,
        location: response?.data?.data[0]?.name,
        account: response?.data?.data[0]?.account_id,
      }      
      const responsere = await axios.post(`${API_URL}/Company/getAllReview`, value);
      const fetchedLocationId = responsere?.data?.data;
      setReviewData({
        ...reviewData,
        averageRating: fetchedLocationId?.averageRating,
        reviews: fetchedLocationId?.reviews,
        totalReviewCount: fetchedLocationId?.totalReviewCount
      })
      setSelectYears(fetchedLocationId?.reviews?.map((item:any) => new Date(item?.updateTime).getFullYear())?.filter((value: any, index: any, array: any) => array.indexOf(value) === index) // Filter unique values
      .sort((a: any, b: any) => b - a)[0])
      setLoadingSubmit(false)

    } catch (error) {
      setLoadingSubmit(false)

      console.error("Error fetching location ID:", error);
    }
  };


const monthCounts:any = {
  Jan: {count:0,avages:0},
  Feb: {count:0,avages:0},
  Mar: {count:0,avages:0},
  Apr: {count:0,avages:0},
  May: {count:0,avages:0},
  Jun: {count:0,avages:0},
  Jul: {count:0,avages:0},
  Aug: {count:0,avages:0},
  Sep: {count:0,avages:0},
  Oct: {count:0,avages:0},
  Nov: {count:0,avages:0},
  Dec: {count:0,avages:0}
};

useEffect(() => {
    fetchLocationId(revToken);
  
}, [revToken,currentUser?.company_id]);
const handlechange=(event: any) => {
  setSelectedLocationId(event.target.value)
  handleSubmit1(event)
}
const handleSubmit1 = async (e: any) => {
  e.preventDefault()
  setLoadingSubmit(true)
  const id = account.filter((d: any) => d.name == e.target.value)[0].account_id
  const responses = await axios.post(`${API_URL}/Company/getaccount`, { account: id });
  const parsedTime = new Date(responses.data.data.updated_at);
  var access_token = responses?.data?.data?.access_token
  const currentTime = new Date();
  const oneHourAgo = new Date(currentTime.getTime() - (1 * 60 * 60 * 1000)); // 1 hour in milliseconds
  const date1 = new Date(parsedTime);
  const date2 = new Date(oneHourAgo);
  if (date1 < date2) {        
    const data = await axios.post(`${API_URL}/refres_token`, { refresh_token: responses.data.data.ref_token, account_id: id });
    access_token = data?.data
  }  
  const value = {
    token: access_token,
    location: e.target.value,
    account: account[0]?.account_id,
  }
  try{
  const responsere = await axios.post(`${API_URL}/Company/getAllReview`, value);
  const fetchedLocationId = responsere?.data?.data;
  setReviewData({
    ...reviewData,
    averageRating: fetchedLocationId?.averageRating,
    reviews: fetchedLocationId?.reviews,
    totalReviewCount: fetchedLocationId?.totalReviewCount
  })
  setSelectYears(fetchedLocationId?.reviews?.map((item:any) => new Date(item?.updateTime).getFullYear())?.filter((value: any, index: any, array: any) => array.indexOf(value) === index) // Filter unique values
      .sort((a: any, b: any) => b - a)[0])
      setLoadingSubmit(false)

}catch(err){
  setLoadingSubmit(false)

}
  hideModal()

}
const uniqueYears= reviewData?.reviews?.map((item:any) => new Date(item?.updateTime).getFullYear());


function getMonthFromDate(dateString:any) {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'short' });
  return month.toLowerCase();
}
const [locationselect, setLocationSelect] = useState(false)

const hideModal = () => {
  setLocationSelect(false)
}
const [loadingsubmit, setLoadingSubmit] = useState(false)
let starRatingsCount:any = {
  "FIVE": 0*5,
  "FOUR": 0*4,
  "THREE": 0*3,
  "TWO": 0*2,
  "ONE": 0*1
};
var monthWiseStarRatings:any = {};
var TillDate:any=[]
var sum:any=0
var currentemp:any=[]


reviewData?.reviews?.forEach((item:any,index) => {

  if(new Date(item?.createTime).getFullYear()<=selectYears){
    let avaSum = 0; 

    if (item.starRating === 'FIVE') {
        avaSum = 5; 
    } else if (item.starRating === 'FOUR') {
        avaSum = 4; 
    } else if (item.starRating === 'THREE') {
        avaSum = 3; 
    } else if (item.starRating === 'TWO') {
        avaSum = 2; 
    } else {
        avaSum = 1; 
    }
    sum += avaSum; 
    currentemp.push(item?.reviewId);
    

  }

  if(new Date(item?.createTime).getFullYear()==selectYears){

    let starRating = item.starRating.toUpperCase();
    if (starRatingsCount.hasOwnProperty(starRating)) {
      starRatingsCount[starRating]++;
    }
    let month1 = getMonthFromDate(item?.updateTime);
month1 = month1?.charAt(0).toUpperCase() + month1?.slice(1).toLowerCase();

    if (!monthWiseStarRatings.hasOwnProperty(month1)) {
      monthWiseStarRatings[month1] = {
        "FIVE": 0,
        "FOUR": 0,
        "THREE": 0,
        "TWO": 0,
        "ONE": 0
      };
    }
    if (monthWiseStarRatings[month1].hasOwnProperty(starRating)) {
      monthWiseStarRatings[month1][starRating]++;
    let ava=starRating=='FIVE'?5:starRating=='FOUR'?4:starRating=='THREE'?3:starRating=='TWO'?2:1
      monthCounts[month1].avages=monthCounts[month1].avages+ava;

   //   console.log('monthWiseStarRatings-->',monthWiseStarRatings)
    }
    // Count month-wise
    let month: any = getMonthFromDate(item?.updateTime);
    month = month?.charAt(0).toUpperCase() + month?.slice(1).toLowerCase();

    if (monthCounts.hasOwnProperty(month)) {
      

      monthCounts[month].count++;

    } else {
      monthCounts[month] = {count:1,avages:0};
    }
  }
});
TillDate.push(sum); // Push the total sum into the TillDate array

var data = reviewData?.averageRating;
var TLength=currentemp.length
var TData=TillDate[0]
if (typeof data !== 'undefined') {
    var roundedData = Math.floor(data * 10) / 10;
} else {
  roundedData=0
}
const locationOptions = account?.map((data: any) => (
  <div key={data?.name} className="form-check mt-3">
    <input
      className="form-check-input cursor-pointer"
      type="radio"
      name="color"
      value={data?.name}
      checked={selectedLocationId === data?.name}
      onChange={() => setSelectedLocationId(data?.name)}
    />
    <label className="form-check-label">{data?.title}</label>
  </div>
));

  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight,monthCounts,TLength,TData))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode,selectYears])

  return (

    <div className={`card ${className}`}>
      {/* begin::Header  */}
      <div className={`card-header border-0 bg-${chartColor} py-5`}>
        <h3 className='card-title fw-bold text-white'>Yearly Google Review Feedback</h3>
        {account.length <= 0 &&
        <div className="notice d-flex setbgcolor rounded border-warning border border-dashed  p-6">
          <i className="ki-duotone ki-information fs-2tx text-warning me-4"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
          <div className="d-flex flex-stack flex-grow-1 ">
            <div className=" fw-semibold">
              <h4 className="text-gray-900 fw-bold">We Need Your Business Account!</h4>

              <div className="fs-6 text-gray-700">
                You do not have an account yet. To add your account, please{' '}
                <Link className="fw-bold" to="/crafted/organization/accounts">
                  Add a Business Account
                </Link>
                .
              </div>
            </div>
          </div>
        </div>}
        <Modal
          show={locationselect}
          className='modal-wide'
          dialogClassName='modal-dialog modal-dialog-centered'
        >
          <div className='modal-content'>
            <form
              className='form'
              action='#'
              id='kt_modal_add_customer_form'
              data-kt-redirect='../../demo7/dist/apps/customers/list.html'
            >
              <div className='modal-header'>
                <h2>Company Location</h2>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  onClick={() => {
                    hideModal()
                  }}
                >
                  <span className='svg-icon svg-icon-1'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.5'
                        x='6'
                        y='17.3137'
                        width='16'
                        height='2'
                        rx='1'
                        transform='rotate(-45 6 17.3137)'
                        fill='currentColor'
                      />
                      <rect
                        x='7.41422'
                        y='6'
                        width='16'
                        height='2'
                        rx='1'
                        transform='rotate(45 7.41422 6)'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className='modal-body py-10 px-lg-17'>
                {account[0]?.name ? (<>
                  <div className='row mb-8'>
                    <div className='fv-row center'>
                      <label className='form-label fw-bolder text-gray-900 fs-6'>Select Location</label>
                      {locationOptions}
                    </div>
                  </div>
                </>) : (<> <div className=' mb-4 mt-7 fixnotavaibale '>No Options Available</div></>)}
              </div>
              <div className='modal-footer flex-center'>
                <button
                  type='reset'
                  className="btn btn-light btn-active-light-primary me-2 data-bs-dismiss='modal' id='close' editbutton "
                  onClick={() => {
                    hideModal()
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={(e) => {
                    handleSubmit1(e)
                  }}
                  className='btn btn-primary'
                >
                  {!loadingsubmit && <span className='indicator-label'>Search Review</span>}
                  {loadingsubmit && (
                    <span className='indicator-progress fixloadersite'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <div className='card-toolbar'>
          {/* <div className='d-flex flex-wrap my-1'>
            <div className='d-flex flex-wrap my-0 ' data-kt-customer-table-toolbar='base'>


              <div className='d-flex my-0'>
                <button
                  type='button'
                  className={clsx(
                    'btn btn-color-white btn-active-white',
                    `btn-active-color-${chartColor}`,
                    'me-3'
                  )}
                  onClick={() => {
                    showModalForm()
                  }}
                >
                  Change Location
                </button>
              </div>
            </div>
          </div> */}
          <>
            {/* begin::Filter Button */}
            <Link to=''
              // disabled={isLoading}
              type='button'
              className='btn btn-light-primary me-3 users-list-filters'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              
              {!loadingsubmit &&              (<><KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
 <span className='indicator-label'>Company</span></>)}
                  {loadingsubmit && (
                    <span className='indicator-progress fixloadersite'>Please wait...
                      <span className='spinner-border spinner-border-sm align-middle '></span>
                    </span>
                  )}
            </Link>
            {/* end::Filter Button */}
            {/* begin::SubMenu */}
            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fs-6 fw-bold'>Company</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    value={selectedLocationId}
                    onChange={(e) => handlechange(e)}
                  >
                    <option value='' disabled>
                    {`\u00A0\u00A0\u00A0`}Select Company
                    </option>
                    {account?.map((item: any, index: number) => {
                      return <option key={index} value={item?.name} >
                         {selectedLocationId === item?.name ? (
                      <span>&#x2713;{`\u00A0`}</span>
                    ):<span>{`\u00A0\u00A0 `}</span>}
                        {item.title}</option>;
                    })}
                  </select>
                </div>
                {/* end::Input group */}

                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fs-6 fw-bold'>Years</label>
                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      onChange={(e) => setSelectYears(e.target.value)}
                      value={selectYears}
                    >
                      {!uniqueYears && (<option value='' disabled>
                        No Years
                      </option>)}

                      {/* { uniqueYears?.filter((value:any, index:any, array:any) => array?.indexOf(value) === index).map((item:any)=>(

              <option value={item} data-kt-menu-dismiss='true'>{item}</option>
              ))} */} {
                        uniqueYears
                          ?.filter((value: any, index: any, array: any) => array.indexOf(value) === index) // Filter unique values
                          .sort((a: any, b: any) => b - a) // Sort in descending order
                          .map((item: any) => (
                            <option key={item} value={item} data-kt-menu-dismiss='true'>{item}</option>
                          ))}
                     
                    </select>

                  </div>

                  {/* </select> */}
                </div>
                {/* end::Input group */}

                {/* begin::Actions */}
                {/* <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
            //  onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            // onClick={() => propost.filterfunction(role, lastLogin)}
            >
              Reset
            </button>
            <button
              // disabled={isLoading}
              type='button'
             // onClick={() => propost.filterfunction(role, lastLogin)}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div> */}
                {/* end::Actions */}
              </div>
              {/* end::Content */}
            </div>
            {/* end::SubMenu */}
          </>
          {/* begin::Menu  */}
          {/* <Tooltip color={'#04c8c8'} title='Select Year'> */}

          {/* <Link to=''
            className={clsx(
              'btn btn-sm btn-icon btn-color-white btn-active-white',
              `btn-active-color-${chartColor}`,
              'border-0 me-n3'
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </Link> */}
          {/* <Dropdown1 uniqueYears={uniqueYears}selectYears={selectYears} setSelectYear={setSelectYears}/>

          </Tooltip> */}
          {/* end::Menu  */}
        </div>
      </div>
      {/* end::Header  */}

      {/* begin::Body  */}
      <div className='card-body p-0'>
        {/* begin::Chart  */}
        <div
          ref={chartRef}
          className={`mixed-widget-12-chart h-283px card-rounded-bottom bg-${chartColor}`}
        ></div>
        {/* end::Chart  */}

        {/* begin::Stats  */}
        <div className='card-rounded bg-body mt-n2 position-relative card-px py-15'>
          {/* begin::Row  */}
          <div className='row g-0 mb-7'>
            {/* begin::Col  */}
            <div className='col mx-5'>
              <div className='fs-6 text-gray-400'>Total Review</div>
              <div className='fs-2 fw-bold text-gray-800'>{reviewData?.totalReviewCount ? reviewData?.totalReviewCount : 'No Review Available'}</div>
            </div>
            {/* end::Col  */}

            {/* begin::Col  */}
            <div className='col mx-5'>
              <div className='fs-6 text-gray-400'>Avarage Rating</div>
              <div className='fs-2 fw-bold text-gray-800'>{roundedData}</div>
            </div>
            <div className='col mx-5'>
              <div className='fs-6 text-gray-400'>Company Name</div>
              <div className='fs-2 fw-bold text-gray-800'>{account?.filter((d:any)=>d.name===selectedLocationId)[0]?.title}</div>
            </div>
            {/* end::Col  */}
          </div>
          {/* end::Row  */}

          {/* begin::Row  */}
          {/* <div className='row g-0'> */}
          {/* begin::Col  */}
          {/* <div className='col mx-5'>
              <div className='fs-6 text-gray-400'>Revenue</div>
              <div className='fs-2 fw-bold text-gray-800'>$55,000</div>
            </div> */}
          {/* end::Col  */}

          {/* begin::Col  */}
          {/* <div className='col mx-5'>
              <div className='fs-6 text-gray-400'>Expenses</div>
              <div className='fs-2 fw-bold text-gray-800'>$1,130,600</div>
            </div> */}
          {/* end::Col  */}
          {/* </div> */}
          {/* end::Row  */}
        </div>
        {/* end::Stats  */}
      </div>
      {/* end::Body  */}
    </div>
  )
}

const chartOptions = (chartHeight: string, monthCounts: any, TLength: any, TData: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  // console.log('monthCounts',monthCounts)
  let values: any = Object.values(monthCounts)
  var yRating: number = 0
  function updateData(inputData:any, mainData:any,cData:any) {
    const data = inputData[0];
    const values = inputData.reduce((accumulator:any, monthData:any) => {
    return accumulator + Object.values(monthData).reduce((sum, month:any) => sum + month.avages, 0);
  }, 0);
  
  const values1 = inputData.reduce((accumulator:any, monthData:any) => {
    return accumulator + Object.values(monthData).reduce((sum, month:any) => sum + month.count, 0);
  }, 0);
    const months = Object.keys(data);
    let val=0;
    let val1=0
    for (let i = 0; i < months.length ; i++) {
      const month = months[i];
      val+= data[month].avages
    val1+= data[month].count
      data[month].avages = mainData-(values-val);
   data[month].count = cData-(values1-val1)
    }
    return inputData;
  }
  
  let sData=_.cloneDeep(monthCounts);
  const fullData=updateData([sData],TData,TLength)
  const YearAvarage=Object.values(fullData[0])
  return {
    series: [ {
      name: 'Monthly Average',
      data: values.map((item: any) => Math.floor((item.avages / item.count) * 10) / 10),
    },
    {
      name: 'Total Average',
      data: YearAvarage.map((item: any) => Math.floor((item.avages / item.count) * 10) / 10),
    }
  ],
    chart: {
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
     
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      }
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
    colors: ['#ffffff', '#207387'],
    tooltip: {
    
      y: [
        {
          formatter: function (val: any) {
            return ` ${val}`;
          },
        },
        {
          formatter: function (val: any, object: any) {
            return ` ${val}`;
          },
        },
      ],
      x: {
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          const fullMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
          return fullMonths[dataPointIndex];
        }
      },
      marker: {
        show: false,
      },

    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: Object.keys(monthCounts),
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
  
    fill: {
      opacity: 1
    },
   
    
   
  }
}

export {MixedWidget3}

