import React, {useState} from 'react'

import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import {requestPasswordsave} from '../core/_requests'
import {useAuth} from '../core/Auth'
import 'react-toastify/dist/ReactToastify.css'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
interface FormValues {
  name: string
  phone: string
  email: string
  emailPhone: string
  password: string
  cpass: string
  terms: string
}

export function PasswordReset() {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [pass, setPass] = useState<any>('')
  const [cpass, setCpass] = useState<any>('')
  const [message, setMessage] = useState<any>('')
  const [showPassword, setShowPassword] = useState(false)
  const location = useLocation()
  const {saveAuth, setCurrentUser, saveSuperAuth} = useAuth()
  const {state}: any = location
  const userid = state?.userid
  const [errors, setErrors] = useState<Partial<FormValues>>({})
  const   [passErrors,setPassErrors] = useState<Partial<FormValues>>({})
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [errorMessages, setErrorMessages] = useState<any>([])
  let messageFrom = [
    {
      header: 'Success',
      body: `Please enter the OTP sent to your phone number to complete verification. If you haven't received it, click "Resend OTP". To modify your phone number, click "Edit Phone Number". Remember to verify your email for receiving important notifications. Contact support for assistance. Thank you for verifying your phone number and email.`,
      pos: true,
    },
    {
      header: 'Success',
      body: `Please enter the 6-digit OTP sent to your phone number to verify. If you haven't received it, click "Resend OTP". To modify your phone number, click "Edit Phone Number". For assistance, contact our support team. Thank you for verifying your phone number`,
      pos: true,
    },
    {
      header: 'Success',
      body: `Thank you for registering! We have sent a verification link to your email inbox. Please check your email and click on the link to verify your email address. If you haven't received the email, please check your spam or junk folder. If you still encounter any issues, please contact our support team for assistance. Thank you!`,
      pos: false,
    },
  ]
  const navigate = useNavigate()

  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value
    if(cpass){
      setPasswordMatch(event.target.value === cpass)
    }
    setPass(newPassword)
    if (newPassword && newPassword !== '') {
      const validationResult = validatePassword(newPassword)
      setErrorMessages(validationResult.errorMessages)
    }
    const { name, value } = event.target
    var newErrors = passErrors;

    if (name === 'password') {
      if (value && value !== '') {
        newErrors.password = ''
      }
      else {
        newErrors.password = 'Please enter password with 8 or more characters with a mix of letters, numbers, and symbols.'
      }
    }
    setPassErrors(newErrors)


  }
  const handleConfirmPasswordChange = (event: any) => {
    setCpass(event.target.value)
    setPasswordMatch(event.target.value === pass)
    // setPasswordMatch(event.target.value === password)
  }
  const validatePassword = (password: any) => {
    const uppercaseRegex = /^(?=.*[A-Z])/
    const numberRegex = /^(?=.*\d)/
    const symbolRegex = /^(?=.*[@$!%*?&#()_-])/
    const lengthRegex = /^.{8,}$/

    const errors = []
    if (password) {
      if (!uppercaseRegex.test(password)) {
        errors.push('Password must contain at least one uppercase letter')
      }

      if (!numberRegex.test(password)) {
        errors.push('Password must contain at least one number')
      }

      if (!symbolRegex.test(password)) {
        errors.push('Password must contain at least one symbol')
      }

      if (!lengthRegex.test(password)) {
        errors.push('Password must be at least 8 characters long')
      }
    } else {
      errors.push('Password can not be blank')
    }

    return {
      isValid: errors.length === 0,
      errorMessages: errors,
    }
  }
  const resetPassWord = async (e: any) => {
    e.preventDefault()

    const newErrors: Partial<FormValues> = {}
    const validationResult = validatePassword(pass)
    if (!pass) {
      newErrors.password = 'Please enter password with 8 or more characters with a mix of letters, numbers, and symbols.'
    }

    setErrorMessages(validationResult.errorMessages)
    setPasswordMatch(cpass === pass)
    setErrors(newErrors)
    setPassErrors(newErrors)

    if (
      Object.keys(newErrors).length === 0 &&
      validationResult.isValid &&
      cpass === pass
    ) {
      try {
        let userId = userid ? userid : currentUser?.user_id
        let data = {
          userid: userId,
          password: pass,
        }
        setLoading(true)
        var data1: any = await requestPasswordsave(data)
if(data1?.data?.data?.company_id){
        if (data1?.data?.data?.is_superadmin) {
          data1['login'] = 'supeadmin'
          data1['window'] = 2
          saveSuperAuth(data1?.data)
          data1.loggedinCompany = false
          setCurrentUser(data1?.data?.data)
        } else {
          data1['login'] = 'direct'
          data1['window'] = 1
          saveAuth(data1?.data)
          setCurrentUser(data1?.data?.data)
        }
      }else{
        saveAuth(data1?.data)
        setCurrentUser(data1?.data?.data)
        navigate('/organisation', { state: messageFrom[0] })
      }
        // saveAuth(data)
        // setCurrentUser( user?.data)
        setLoading(false)
        // if(currentUser )
        // navigate('/organisation', {state: { moveOrg : true}})
        //}
      } catch (e) {
        setLoading(false)
      }
    }
  }
  return (
    <>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10 custmWdthUsr'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'
              noValidate
              id='kt_login_password_reset_form'
              onSubmit={resetPassWord}  
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Set New Password</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-400 fw-bold fs-4'>Enter your password .</div>
                {/* end::Link */}
              </div>
              {message && (
                <div
                  className='alert alert-danger alert-dismissible text-center mt-10'
                  role='alert'
                >
                  {message}
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='alert'
                    aria-label='Close'
                  ></button>
                </div>
              )}
              {/* begin::Title */}
              {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Sorry, looks like there are some errors detected, please try again.
                  </div>
                </div>
              )}
              {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                  <div className='text-info'>Sent password reset. Please check your email</div>
                </div>
              )}

              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Enter Password'
                  autoComplete='off'
                  className='form-control bg-transparent mb-2'
                  onChange={handlePasswordChange}
                  />
                   {passErrors.password?( <span className='loginfixderror'>{passErrors.password}</span>):( <>
                  {errorMessages && <span className='loginfixderror'>{errorMessages}</span>}
                  </> )} 
              </div>
              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Enter Confirm Password'
                  autoComplete='off'
                  className='form-control bg-transparent mb-2'
                  onChange={handleConfirmPasswordChange}
                />
                 {!passwordMatch && <span className='loginfixderror'>The passwords you've entered do not match</span>}
              </div>
              <div className='form-group form-check text-left mb-6'>
                <input
                  type='checkbox'
                  name='remember'
                  className='form-check-input'
                  id='remember_me'
                  onClick={() => setShowPassword(!showPassword)}
                />
                <label className='form-label fw-bolder text-gray-500 fs-6'>Show Password</label>
              </div>
              {/* for='remember_me' */}
              {/* end::Form group */}
              {/* begin::Form group */}
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder me-4'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>

              {/* end::Form group */}
              <div></div>
            </form>
          </div>
        </div>
        <div className='w-lg-500px d-flex flex-stack px-10 mx-auto'>
        

          <div className='d-flex fw-semibold text-primary fs-base gap-5'>
            <a href='https://termreview.s3.amazonaws.com/Terms-and-Conditions.html' target='_blank' rel="noreferrer">
              Terms
            </a>
           
          </div>
        </div>
      </div>
    </>
  )
}
