//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import {ActionTypes} from '../constants'

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const couponInitial = []

export const couponReducer = (state = couponInitial, action) => {
  switch (action.type) {
    case ActionTypes.coupon.createCouponSuccess:
      return [action.payload, ...state]
    // case ActionTypes.coupon.createCouponFail:

    case ActionTypes.coupon.deletCouponSuccess:
      return state.filter((x) => x.couponId !== action.payload.couponId)
    case ActionTypes.coupon.updateCouponSuccess:
      return state.map((x) => {
        if (x.couponId === action.payload?.couponId) {
          return {...x, ...action.payload}
        }
        return x
      })

    case ActionTypes.coupon.getCouponSuccess:
      return action.payload
    // case ActionTypes.coupon.getCouponFail:
    //     return state;
    // case ActionTypes.coupon.deletCouponFail:
    //     return {
    //         ...state,
    //         loading: false,

    //         error: action.payload,
    //       };
    // case ActionTypes.coupon.countCoupon:
    //     return {
    //         ...state,
    //         count:action.payload
    //     }
    default:
      return state
  }
}
