import axios from 'axios'
import * as ActivityActions from './actions'

const API_URL = process.env.REACT_APP_API_URL

export const getReportData = () => async (dispatch) => {
  try {
    let token = sessionStorage.getItem('kt-auth-react-v')

    let token1 = JSON.parse(token)

    const config = {
      headers: {authorizationtoken: token1?.api_token, 'Content-Type': 'application/json'},
    }

    const {data} = await axios.get(`${API_URL}/googlereview/report`, config)

    await dispatch({type: 'Report', payload: data})
  } catch (error) {
    console.log('error', error)
  }
}
