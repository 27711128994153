//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';
const _ = require('lodash');


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const categoryInitial = [];

export const categoryReducer = (state = categoryInitial, action) => {
    switch (action.type) {
        case ActionTypes.category.createCategorySuccess:
            return [action.payload, ...state];
        case ActionTypes.category.deleteCategorySuccess:
                //return state.filter(x => x.categoryId !== action.payload.categoryId);
                return  _.differenceWith(state, action.payload, (val, dataItem) => val.categoryId === dataItem.categoryId)
        case ActionTypes.category.updateCategorySuccess:
                return state.map(x => {
                    if (x.categoryId === action.payload?.categoryId) {
                        return { ...x, ...action.payload };
                    }
                    return x;
                }); 
        case ActionTypes.category.getCategorySuccess:
            return action.payload;
        case ActionTypes.category.getCategoryFail:
            return state;
        default:
            return state;
    }
};
