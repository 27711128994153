//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import {ActionTypes} from '../constants'

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const sellerInitial = []

export const sellerReducer = (state = sellerInitial, action) => {
  switch (action.type) {
    case ActionTypes.seller.createSellerSuccess:
      return [action.payload, ...state]
    case ActionTypes.seller.deleteSellerSuccess:
      return state.filter((x) => x.user_id !== action.payload.user_id)
    case ActionTypes.seller.updateSellerSuccess:
      return state.map((x) => {
        if (x.sellerId === action.payload?.sellerId) {
          return {...x, ...action.payload}
        }
        return x
      })

    case ActionTypes.seller.getSellerSuccess:
      return action.payload
    case ActionTypes.seller.getSellerFail:
      return state
    default:
      return state
  }
}
