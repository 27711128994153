/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useScratch } from 'react-use';
interface Dropdown1Props {
  uniqueYears?: any;
  setSelectYear?:any;
  selectYears?:any;
  
}
export function Dropdown1({uniqueYears,selectYears,setSelectYear}:Dropdown1Props) {
  const defaultYear = uniqueYears && uniqueYears.length > 0 ? uniqueYears[0] : '';

  // Call setSelectYear with the default year value when the component is rendered
  useEffect(() => {
    setSelectYear(defaultYear);
  }, [setSelectYear, defaultYear]);  
  // const year=[2000,2021,2022,2023,2024]
  return (<>
    {/* <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>choose Year:</label>*/}

          <div> 
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e)=>setSelectYear(e.target.value)}
              value={selectYears}
            >
           {!uniqueYears &&(   <option value='' disabled>
                  No Years
                </option>)}
             
              {/* { uniqueYears?.filter((value:any, index:any, array:any) => array?.indexOf(value) === index).map((item:any)=>(

              <option value={item} data-kt-menu-dismiss='true'>{item}</option>
              ))} */}
                {uniqueYears
                ?.filter((value: any, index: any, array: any) => array.indexOf(value) === index) // Filter unique values
                .sort((a: any, b: any) => b - a) // Sort in descending order
                .map((item: any) => (
                  <option key={item} value={item} data-kt-menu-dismiss='true'>{item}</option>
                ))}
            </select>
          </div>
        {/* </div> */}

        

        {/* <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>

          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
          onClick={()=>{setSelectYear('2022')}}
          
          >
            Apply
          </button>
        </div> */}
      {/* </div>
    </div> */}
    </> )
}
