//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import {ActionTypes} from '../constants'

const _ = require('lodash')

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const brandingIntial = []

export const BrandingValuesReducer = (state = brandingIntial, action) => {
  switch (action.type) {
    case 'Branding':
      return action.payload

    default:
      return state
  }
}
