/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
// import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {Organization} from '../modules/organization/organization'
import {Sectors} from '../modules/organization/sector'
import {InvitePages} from '../modules/organization/invitePages'
import {PasswordReset} from '../modules/auth/components/PasswordReset'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import Index from '../modules/GoogleReviewFrom/index.js'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  
  const {currentUser} = useAuth()
  const revToken = localStorage.getItem('bussiness_token')
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {/* <Route path='error/*' element={<ErrorsPage />} /> */}
          <Route path='logout' element={<Logout />} />
          <Route path='review/*' element={<Index />} />


          {currentUser?.loggedinfromSA &&(<>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={currentUser?.is_superadmin == 1 ? <Navigate to='/apps/organizations/list' /> : <Navigate to='/dashboard' />} />
            </>  )  }
          
          {currentUser ? (
            <>
            {revToken && !currentUser?.sector_id &&(<>
              <Route path='organization/*' element={<Organization />} />
                <Route
                  path='*'
                  element={
                    
                      <Navigate to='/organization' />
                    
                  }
                />
            </>)}
            {/* {(!currentUser?.company_id && !currentUser?.sector_id) || (currentUser?.password === null ||currentUser?.password === '') && !revToken ? (
              <>
                <Route path='organization/*' element={<Organization />} />
                <Route path='passwordSet/*' element={<PasswordReset />} />
                <Route
                  path='*'
                  element={
                    (currentUser?.password === null || currentUser?.password === '') ? (
                      <Navigate to='/passwordSet' />
                    ) : (
                      <Navigate to='/organization' />
                    )
                  }
                />
              </>
            ) : (
              <> */}
                {currentUser && currentUser?.company_id && !currentUser?.sector_id && (
                  <>
                    <Route path='sector/*' element={<Sectors />} />
                    <Route path='sector/invitePages' element={<InvitePages />} />

                    <Route path='*' element={<Navigate to='/sector' />} />
                  </>
                )}
                {currentUser &&
                  currentUser.company_id &&
                  currentUser.company_id !== null &&
                  currentUser.sector_id !== null &&
                  currentUser.hasOwnProperty('invitePages') &&
                  currentUser.invitePages && (
                    <>
                      <Route path='invitePages/*' element={<InvitePages />} />

                      <Route path='*' element={<Navigate to='/invitePages' />} />
                    </>
                  )}
                {currentUser &&
                  currentUser.company_id &&
                  currentUser.company_id !== null &&
                  currentUser.sector_id !== null && (
                    <>
                      <Route path='/*' element={<PrivateRoutes />} />
                      <Route index element={currentUser?.is_superadmin == 1 ? <Navigate to='/apps/organizations/list' /> : <Navigate to='/dashboard' />} />
                    </>
                  )}
              {/* </>
            )} */}
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
