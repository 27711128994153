import React from 'react'
import {Pagination} from 'react-bootstrap'

const PaginationUI = ({currentPage, totalPages, onPageChange}) => {
  const pageItems = []

  if (totalPages <= 3) {
    // If there are three or fewer pages, show all pages
    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
          {i}
        </Pagination.Item>
      )
    }
  } else {
    // If there are more than three pages, show first, current, and last three pages with an ellipsis
    if (currentPage <= 2) {
      // Show the first three pages
      for (let i = 1; i <= 3; i++) {
        pageItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
            {i}
          </Pagination.Item>
        )
      }
      pageItems.push(<Pagination.Ellipsis key='ellipsis-start' />)
    } else if (currentPage >= totalPages - 1) {
      // Show the last three pages
      pageItems.push(<Pagination.Ellipsis key='ellipsis-end' />)
      for (let i = totalPages - 2; i <= totalPages; i++) {
        pageItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
            {i}
          </Pagination.Item>
        )
      }
    } else {
      // Show the current page, one page before and after, and an ellipsis at both ends
      pageItems.push(<Pagination.Ellipsis key='ellipsis-start' />)
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
            {i}
          </Pagination.Item>
        )
      }
      pageItems.push(<Pagination.Ellipsis key='ellipsis-end' />)
    }
  }

  return (
    <Pagination>
      <Pagination.Prev disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)} />
      {pageItems}
      <Pagination.Next
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      />
    </Pagination>
  )
}

export default PaginationUI
