import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { KTSVG } from '../../../../_metronic/helpers';

interface SuccessModalProps {
    show: boolean;
    onClose: () => void;
}
const SuccessModal: React.FC<SuccessModalProps> = ({ show, onClose }) => {
    return (
        <Modal show={show} className='modal-wide ' dialogClassName='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
                <div className='modal-header custom-modal-header '>
                    {/* eslint-disable jsx-a11y/heading-has-content */}
                    <h5 className='modal-title fs-3'></h5>
                    {/* eslint-enable jsx-a11y/heading-has-content */}
                    <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={onClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
                    </div>
                </div>
                <div className='setDelete fw-bolder fs-3 mb-4 successpopup' >
                    Successfully Submitted
                </div>
                <p className='setDelete fw-semibold fs-5 mb-6 confirmsucces' >
                Your data has been successfully added. Thank you for your submission.
                </p>
                <div className='text-center mb-5'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        onClick={onClose}
                    >
                        <span className='indicator-label'>Ok</span>
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default SuccessModal;
