import {Link, useNavigate} from 'react-router-dom'

import {toAbsoluteUrl, KTSVG} from '../../../../../_metronic/helpers'

import {Dropdown1, Search} from '../../../../../_metronic/partials'
import { useEffect } from 'react';

const projects: ReadonlyArray<{image: string; title: string; link: string; label: string}> = [
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Reviews',
    link: 'apps/review/reviewlist',
    label: 'Google Review',
  },
]

const ReviewTAb = () => {
  const navigate = useNavigate()

  useEffect(() =>{
    navigate('apps/review/reviewlist')
  },[])

  return (
    <div className='m-0'>
      <div className='m-0'>
        <div className='mb-10'>
          {projects?.map((key: any) => (
            <Link
              key={key.link}
              to={key.link}
              className='custom-list d-flex align-items-center px-5 py-4'
            >
              <div className='symbol symbol-40px me-5'>
                <span className='symbol-label'>
                  <img
                    src={toAbsoluteUrl(key.image)}
                    alt={key.title}
                    className='h-50 align-self-center'
                  />
                </span>
              </div>
              <div className='d-flex flex-column flex-grow-1'>
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{key.title}</h5>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
export {ReviewTAb}
