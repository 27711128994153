import React, {useEffect, useState} from 'react'
import {Invitations} from './core/_requests'
import {useAuth} from '../auth'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import { KTSVG } from '../../../_metronic/helpers'
const API_URL = process.env.REACT_APP_API_URL

export const InvitePages = () => {
  const [emailList, setEmailList] = useState<string[]>([""]);
  const [errorMessage, setErrorMessage] = useState('')
  const {auth, setCurrentUser,superauth, currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
const [filteredDatas, setfilteredData] = useState([])
  const navigate = useNavigate()

  const handleEmailListChange = (e: any) => {
    const value = e.target.value
    setEmailList(value)
  }
  // const handleKeyDown = (e:any) => {
  //   if (e.key === 'Enter' || e.key === ' ') {
  //     e.preventDefault(); 
  //     setEmailList((prevEmailList) => prevEmailList + ', ');
  //   }
  // };
  const token: any = superauth ? superauth?.api_token : auth?.api_token

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const roleid='ff4e5b6b-5e1f-495f-9c53-40a6abe9961a'
        const response = await axios.get(`${API_URL}/functionRole/getRole/${roleid}`, {
          headers: {
            'Content-Type': 'application/json',
            token: token,
          },
        });

        if (response) {
          setfilteredData(response?.data[0]?.role_name);
          // setfilteredData(data);
        } else {
          console.error('Failed to fetch role data:', response);
        }
      } catch (error) {
        console.error('Error fetching role data:', error);
      }
    };

    fetchRoleData();
  }, []); // Empty dependency array to run effect only once on component mount
  const handleEmailChange = (index: number, value: string) => {
    const updatedEmailList = [...emailList];
    updatedEmailList[index] = value;
    setEmailList(updatedEmailList);
  };
  const handleRemoveField = (index: number) => {
    if (emailList.length > 1) {
       const updatedEmailList = [...emailList];
    updatedEmailList.splice(index, 1);
    setEmailList(updatedEmailList);
    }
  };

  const handleInputClick = (index: number) => {
    const isLastField = index === emailList.length - 1;
    if (isLastField) {
      setEmailList([...emailList, ""]);
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const emails = emailList.filter(element => element !== "");
    const invalidEmails = emails.filter((email:any) => !validateEmail(email.trim()))
    // if (invalidEmails.length > 0) {
    //   setErrorMessage(invalidEmails.join(', ')?'Invalid email addresses:' + invalidEmails.join(', '):'Invalid remove field')
    //   return
    // }
    // setErrorMessage('')

    const {api_token}: any = auth
    const {company_id}: any = currentUser
    const genrated_by= currentUser?.user_id
    var filteredData:any=filteredDatas
    filteredData=[filteredData]
    try {
      setLoading(true)
      await Invitations({emails, api_token, company_id,filteredData, genrated_by})
      setLoading(false)

      if (currentUser) setCurrentUser({...currentUser, invitePages: false, is_admin: true})
      navigate('/dashboard')
    } catch (error) {
      setLoading(false)
    }
  }

  const validateEmail = (email: any) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const goToMainPages = async (e: any) => {
    e.preventDefault()
    setLoading1(true)
    
    if (currentUser) setCurrentUser({...currentUser, invitePages: false, is_admin: true})
    setLoading1(false)

    // let token: any = auth?.api_token
    // setLoading(true)

    // const {data: user} = await getUserByToken(token)
    // setLoading(false)
    // setCurrentUser(user)
     navigate('/dashboard')

    // setCurrentUser(user)
  }

  return (
    <>
      <div className='d-flex flex-center flex-column flex-lg-row-fluid inviteTeamUpr'>
        <div className='w-lg-500px custmInviteUsr'>
          <form
            onSubmit={handleSubmit}
            className='form w-100 onSubmit={companInfo} fv-plugins-bootstrap5 fv-plugins-framework  w-lg-500px bg-body rounded shadow-sm p-10  mx-auto'
          >
            <div className='mb-10'> 
              {/* <svg xmlns='http://www.w3.org/2000/svg'>
                <symbol id='check-circle-fill' fill='currentColor' viewBox='0 0 16 16'>
                  <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                </symbol>
              </svg> */}
              <div className='mb-10'>
                <div className="font-size-lg mb-10">
                  <div>{'Invite your team members to join and collaborate on our platform. Simply enter their email addresses below and an invitation will be sent to them.'}</div>
                </div>
              </div>
            </div>

            <div className='fv-row mb-10 center'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>
                  Invite Teammates{' '}
                </label>
                <div>
                <div className='mb-3'>
                {emailList.map((email, index) => (
                    <div className='input-group' key={index}>
                      <span className='input-group-text' id={`basic-addon${index}`}>
                        {index + 1}
                      </span>
                      <input
                        type="text"
                        value={email}
                        className='form-control'
                        placeholder='Email address'
                        aria-label="Recipient's username"
                        aria-describedby={`basic-addon${index}`}
                        onChange={(e) => handleEmailChange(index, e.target.value)}
                        onClick={() => handleInputClick(index)}
                      />
                      <span className="input-group-text cursor-pointer" onClick={() => handleRemoveField(index)}>
                        <i className="bi bi-x-circle"></i>
                      </span>
                    </div>
                  ))}
                  </div>
                  {/* <div >
                    <button
                      className='btn btn-icon btn-light-primary btn-active-color-primary-inverse btn-sm '
                      onClick={handleAddField}

                    >
                      <i className='fas fa-plus'></i>
                    </button>
                  </div> */}
                </div>
            </div>
            <div className='d-flex justify-content-between'>
              <button
                type='submit'
                // onChange={handleSubmit}
                disabled={loading}
                className='btn btn-lg btn-primary fw-bolder me-4 '
              >
                {!loading && <span className='indicator-label'> Send Invitations</span>}
                {loading && (
                  <span className='indicator-progress fixloadersite' >
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <button className='btn btn-lg  fw-bolder me-4' onClick={goToMainPages}>
                {!loading1 && <span className='indicator-label'>Skip</span>}
                {loading1 && (
                  <span className='indicator-progress fixloadersite' >
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {errorMessage && (
              <div className='alert alert-danger alert-dismissible text-center mt-10' role='alert'>
                {errorMessage}
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='alert'
                  aria-label='Close'
                ></button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  )
}
