import axios from 'axios'
import getToken from '../../../Token'




const API_URL = process.env.REACT_APP_API_URL
const API_URL1 = `http://localhost:5002`



export const getAllTeamplate =  () =>async(dispatch)=> {
 
try{
 let token1 =await getToken()

  const config = {
    headers: {authorizationtoken: token1, 'Content-Type': 'application/json'},
  }


  const {data} = await axios.get(`${API_URL}/googlereview/getAllTemplate`, config)
  dispatch({type:'Template',payload:data?.data})
  return
}catch(e){
  console.log(e)
  return

}

}


