import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import SectorPage from '../modules/apps/sector/SectorPage'
import {SectorView} from '../modules/apps/sector/SectorView'
import {useAuth} from '../modules/auth'
import {useFunction} from '../modules/auth/core/functionRoles'
const PrivateRoutes = () => {
  const {functionName, functionCategory} = useFunction()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const OrganizationPage = lazy(() => import('../modules/organizations/OrganizationPage'))
  const Settings = lazy(() => import('../modules/apps/settings/Settings'))
  const RolePage = lazy(() => import('../modules/apps/userrole/RolePage'))
  const OrganizationPage2 = lazy(() => import('../modules/apps/organizations/OrganizationPage'))
  const AddOrgPage = lazy(() => import('../modules/apps/AddOrganization/AddOrgPage'))
  const FormPage = lazy(() => import('./../modules/apps/customform/formPage'))
  const Mearchant = lazy(() => import('./../modules/apps/marchant/formPage'))
  const Feedback = lazy(() => import('./../modules/apps/Feedbacks/formPages'))
  const ReviewPage = lazy(() => import('./../modules/apps/Review/ReviewPages'))
  const Subscription = lazy(() => import('./../modules/apps/Subscription/formPages'))
  const Branding = lazy(() => import('./../modules/apps/Branding/formPages'))
  const FormPages = lazy(() => import('./../modules/apps/Forms/formPages'))
  const ReviewPages = lazy(() => import('./../modules/apps/googlereviewList/reviewPages'))

  const SubSupperAdmin = lazy(() => import('./../modules/apps/SubSupperAdmin/formPage'))
  const {currentUser, auth} = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='auth/*'
          element={
            currentUser?.is_superadmin === 1 ? (
              <Navigate to='/apps/organizations/list' />
            ) : (
              <Navigate to='/dashboard' />
            )
          }
        />
        <Route path='dashboard/*' element={<DashboardWrapper />} />
        <Route path='organization/*' element={<Navigate to='/apps/organizations/list' />} />
        <Route path='passwordSet/*' element={<Navigate to='/apps/organizations/list' />} />
        {/* Pages */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}

        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/addOrganization/*'
          element={
            <SuspensedView>
              <AddOrgPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/organization/*'
          element={
            <SuspensedView>
              <OrganizationPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        
        <Route
          path='apps/userrole/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />
          <Route
            path='apps/settings/*'
            element={
              <SuspensedView>
                <Settings />
              </SuspensedView>
            }
          />
          <Route
            path='apps/sector/*'
            element={
              <SuspensedView>
                <SectorPage />
                <SectorView />
              </SuspensedView>
            }
          />

        <Route
          path='apps/organizations/*'
          element={
            <SuspensedView>
              <OrganizationPage2 />
            </SuspensedView>
          }
        />
        <Route
          path='apps/forms/*'
          element={
            <SuspensedView>
              <FormPage />
            </SuspensedView>
          }
        />
         <Route
          path='apps/reviewform/*'
          element={
            <SuspensedView>
              <ReviewPages />
            </SuspensedView>
          }
        />
        <Route
          path='apps/marchant/*'
          element={
            <SuspensedView>
              <Mearchant />
            </SuspensedView>
          }
        />
        <Route
          path='apps/feedback/*'
          element={
            <SuspensedView>
              <Feedback />
            </SuspensedView>
          }
        />
        <Route
          path='apps/review/*'
          element={
            <SuspensedView>
              <ReviewPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/adminCategory/*'
          element={
            <SuspensedView>
              <SubSupperAdmin />
            </SuspensedView>
          }
        />
         <Route
          path='apps/branding/*'
          element={
            <SuspensedView>
              <Branding />
            </SuspensedView>
          }
        />
         <Route
          path='apps/subscription/*'
          element={
            <SuspensedView>
              <Subscription />
            </SuspensedView>
          }
        />
         <Route
          path='apps/form/*'
          element={
            <SuspensedView>
              <FormPages />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}