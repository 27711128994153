// Modal.js
import React, {useEffect, useState} from 'react'
import './style.css'
import {Modal} from 'react-bootstrap'
import copy from 'clipboard-copy';
import TagsInput from 'react-tagsinput';
// import 'react-tagsinput/react-tagsinput.css'
import { useNavigate} from 'react-router-dom'

import QRCode from 'react-qr-code'
import {useGetBranding} from './store/Branding/selectors'
import { encode } from 'base-64';
import { template } from 'lodash'

import { Slider } from 'antd';

import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { company } from '../../Component/modal/data';

const Modals = ({isOpen, closeModal}) => {
  const fieldTypes = ['shortText', 'longText', 'dropdown', 'multichoice', 'radio']
  function capitalizeFirstLetter(str) {
    if (str.length === 0) {
      return str
    }

    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return (
    <Modal className='fixforvalue1' show={isOpen}>
      <div className='modal-content'>
        <form
          className='form'
          // onSubmit={formik.handleSubmit}
          action='#'
          id='kt_modal_add_customer_form'
          data-kt-redirect='../../demo7/dist/apps/customers/list.html'
        >
          <div className='modal-header'>
            <h2>Select Field</h2>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                closeModal(null)
                //  setSelectedBrand([])
              }}
            >
              <span className='svg-icon svg-icon-1'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='6'
                    y='17.3137'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(-45 6 17.3137)'
                    fill='currentColor'
                  />
                  <rect
                    x='7.41422'
                    y='6'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(45 7.41422 6)'
                    fill='currentColor'
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className='modal-body'>
            <div>
              {fieldTypes.map((type) => (
                <div
                  className='flex-row hovdev'
                  key={type}
                  onClick={(e) => {
                    closeModal(type)
                  }}
                >
                  {/* Use icons from a library like Bootstrap Icons */}
                  {type === 'shortText' && (
                    <i className='bi bi-file-text btn btn-icon btn-bg-light btn-active-color-primary btn-sm'></i>
                  )}
                  {type === 'longText' && (
                    <i className='bi bi-textarea btn btn-icon btn-bg-light btn-active-color-primary btn-sm'></i>
                  )}
                  {type === 'dropdown' && (
                    <i className='bi bi-caret-down btn btn-icon btn-bg-light btn-active-color-primary btn-sm'></i>
                  )}
                  {type === 'multichoice' && (
                    <i className='bi bi-check-square btn btn-icon btn-bg-light btn-active-color-primary btn-sm'></i>
                  )}
                  {type === 'radio' && (
                    <i className='bi bi-ui-radios btn btn-icon btn-bg-light btn-active-color-primary btn-sm'></i>
                  )}

                  {/* Display the capitalized fieldType */}
                  <span className='ml-1'> {capitalizeFirstLetter(type)}</span>
                </div>
              ))}
            </div>
          </div>
        </form>
      </div>
    </Modal>
    // <div className={`modal ${isOpen ? 'open' : 'closed'}`}>
    //   <div className="modal-content">
    //     <h2>Modal Content</h2>

    //     <label htmlFor="textBox" onClick={()=>closeModal('shortText')}>Short Text</label>

    //     <br />
    //     <label htmlFor="textBox" onClick={()=>closeModal('longText')}>Long Text</label>
    //     <br />
    //     <label htmlFor="textBox" onClick={()=>closeModal('Dropdown')}>Dropdown</label>
    //     <br />
    //     <label htmlFor="textBox" onClick={()=>closeModal('Multipal Choise')}>Multichoice</label>
    //     <br />
    //     <label htmlFor="textBox" onClick={()=>closeModal('Radio Choise')}>Radio</label>
    //     <br />

    //     {/* <button onClick={closeModal}>Close Modal</button> */}
    //   </div>
    // </div>
  )
}

export default Modals

const AddFormName = ({
  isOpen,
  closeModal,
  setFeedback,
  feedback,
  setSelectBranding,
  selectBranding,
  CompanyUri,
  setCompanyUri,
  setFormName,
  setIsAlgen,
  formName,
  isAlgen,
  values,
  templateName,
  setselectTemplate,
  selectTemplate,
  duplicate,
  ishide,
  tags,
  setTags,
  account,
  editTrue,
  setHideTemp,
  currentUser,
}) => {
  const branding = useGetBranding()
  const dispatch = useDispatch()
  const navigate = useNavigate()


const {is_superadmin}=currentUser
  const handleIncrement = (e) => {
    e.preventDefault()
    setFeedback(Math.min(feedback + 1, 10))
  }
  const [radioValue, setRadioValue] = useState('');
  const [CompanyData, setCompanyData] = useState([]);
  const [FormMsg,setFormMSg]=useState('');
  const [URIMsg,setURIMSg]=useState('');
useEffect(() => {
 setRadioValue(isAlgen===true?'yes':'no')
}, [isAlgen])

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if(event.target.value==='yes'){
      setIsAlgen(true);
    }else{
      setIsAlgen(false)
    };
  };  
  
  const handleDecrement = (e) => {
    e.preventDefault()
    setFeedback(Math.max(feedback - 1, 0))
  }
  // const handleChange = (e) => {
  //   const value = parseInt(e.target.value, 10) || 0
  //   setFeedback(Math.min(Math.max(value, 0), 10))
  // }
  // useEffect(() => {
  //   setFeedback(feedbacks || 0)
  //   setSelectBranding(selectBrandings || '')
  //   setName(formName || '')
  // }, [])
const CompanyDatas=currentUser?.googlemetadata
const filterDataByTitles = async (data, data2) => {
  try {
      console.log("Starting to filter data...");

      // Check if data and data2 exist and are arrays
      if (!Array.isArray(data) || !Array.isArray(data2)) {
          throw new Error("One or both of the data arrays are missing or not arrays.");
      }
      console.log("Both data and data2 are valid arrays.");

      // Check if data and data2 are not empty
      if (data.length === 0 || data2.length === 0) {
          throw new Error("One or both of the data arrays are empty.");
      }
      console.log("Both data and data2 are non-empty.");

      // Create a set of titles from data2 for quick lookup
      const titlesToMatch = new Set(data2.map(item => {
          if (!item.title) {
              throw new Error("One or more items in data2 are missing a title.");
          }
          return item.title;
      }));
      console.log("Successfully created a set of titles from data2.");

      // Filter data based on titles present in data2
      const filteredData = data.filter(item => {
          if (!item.title) {
              throw new Error("One or more items in data are missing a title.");
          }
          return titlesToMatch.has(item.title);
      });

      if (filteredData.length === 0) {
          console.log("No matching titles found in data.");
          return [];
      } else {
          console.log("Successfully filtered data based on titles.");
          return filteredData;
      }

  } catch (error) {
      console.error("An error occurred:", error.message);
      return [];
  }
};
useEffect(() => {
  const filterCompanyData = async () => {
    const filteredData = await filterDataByTitles(CompanyDatas, account);    
    setCompanyData(filteredData)
  };

  filterCompanyData();
}, [CompanyDatas, account]);

const handleChange = (value) => {
  setTags(value);
}
  return (
    <Modal className='fixforvalue1' dialogClassName='modal-dialog modal-dialog-centered' show={isOpen}>
        <form
          className='form thresholdModel'
          // onSubmit={formik.handleSubmit}
          action='#'
          id='kt_modal_add_customer_form'
          data-kt-redirect='../../demo7/dist/apps/customers/list.html'
        >
          <div className='modal-header'>
            <h2>New Form</h2>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                
                
                
                editTrue?closeModal('editval')  :closeModal(null)

                setHideTemp(false)
                //  setSelectedBrand([])
              }}
            >
              <span className='svg-icon svg-icon-1'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='6'
                    y='17.3137'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(-45 6 17.3137)'
                    fill='currentColor'
                  />
                  <rect
                    x='7.41422'
                    y='6'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(45 7.41422 6)'
                    fill='currentColor'
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className='modal-body'>
            <div>
              <label className='required fs-5 fw-semibold mb-3'>Name Your Form</label>

              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Please enter form name'
                value={values}
                onChange={(e) => {
                  setFormName(e.target.value)
                  setFormMSg('')
                }
                }
              />
              {FormMsg && <p className='loginfixderror mt-1'>{FormMsg}</p>}

            </div>
            {!is_superadmin&&
           (<> <div className='row mt-3 mb-3'>
              <label className='fs-5 fw-semibold mb-2'>Allow customers to use our AI tool to help write reviews</label>
            </div>
            <div className='row mb-3'>
              <div className='col-xl-3 col-sm-3'>
                <div className='form-check form-check-custom form-check-solid me-9'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='yes'
                    id='yes'
                    name='superadmin'
                    checked={radioValue === 'yes'}
                    onChange={handleRadioChange}
                  />
                  <label className='form-check-label ms-3'>YES</label>
                </div>
              </div>

              <div className='col-xl-9 col-sm-3'>
                <div className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    value='no'
                    id='no'
                    name='superadmin'
                    checked={radioValue === 'no'}
                    onChange={handleRadioChange}
                  />
                  <label className='form-check-label ms-3'>NO</label>
                </div>
              </div>
            </div>
            {isAlgen===true &&(<>
            <div className='row mt-3 mb-3'>
              <label className='fs-5 fw-semibold mb-2'>Write some tags for our AI tool to help your customers write creative, positive reviews.
              </label>
            </div>
            <div className='row mt-2 mb-3'>
              <TagsInput
              inputProps={{
                placeholder: 'Enter tags'
              }}
              className='tag-box react-tagsinput '
              maxTags={10}
              value={tags}
              // removeKeys={'Backspace'}

              onChange={handleChange} />
            </div></>)}</>)}
            {/* {!is_superadmin && ishide===false && <div >
              <label className='fs-5 fw-semibold mb-3 mt-5'>Select Template</label>

              <select
                className='form-select'
                value={selectTemplate}
                onChange={(e) => {

                  let template = templateName.find((item) => item.id === e.target.value)
                  if(!values)
                  {setFormName(template?.form_name)}
                  setFeedback(template?.lowfeedbackpoint)




                  setselectTemplate(e.target.value)
                }}
              >
                <option value='' disabled>
                  Please select Template
                </option>

                {Array.isArray(templateName) &&
                  templateName?.map((option, index) => (
                    <>
                      <option key={index} value={option?.id}>
                        {option?.form_name}
                      </option>
                    </>
                  ))}
              </select>
            </div>} */}

            {!is_superadmin&& (<>  <div >
              <label className='fs-5 fw-semibold mb-3 mt-2'>Select Branding</label>

              <select
                className='form-select'
                value={selectBranding}
                onChange={(e) => {
                  setSelectBranding(e.target.value)
                }}
              >
                <option value=''>
                  Please select brand
                </option>

                {Array.isArray(branding) &&
                  branding?.map((option, index) => (
                    <>
                      <option key={index} value={option?.id}>
                        {option?.companyname}
                      </option>
                    </>
                  ))}
              </select>
            </div>
            {Array.isArray(branding) && branding.length <= 0 &&
            
             <a
             className='fs-5 text-primary mt-1 cursor-pointer me-1'
             onClick={() =>
          {    navigate('/apps/branding/list',{
            state: 'Set_Brand',
          })
              closeModal(null)}
            }
           >Add Brand Kit</a>}</>)
            }
            {!is_superadmin&&   <div >
              <label className='required fs-5 fw-semibold mb-3 mt-5'>Select Company</label>

              <select
                className='form-select'
                value={CompanyUri}
                onChange={(e) => {
                  setCompanyUri(e.target.value)
                  setURIMSg('')
                }}
              >
                <option value=''>
                  Please select Company
                </option>

                {Array.isArray(CompanyData) &&
                  CompanyData?.map((option, index) => (
                    <>
                      <option key={index} value={option?.newReviewUri}>
                        {option?.title}
                      </option>
                    </>
                  ))}
              </select>
              {URIMsg && <p className='loginfixderror mt-1'>{URIMsg}</p>}

            </div>}
            {/* <div>
              <input
                type='number'
                placeholder='Enter values feedback'
                className='form-control form-control-solid mt-5'
                values={feedback}
                onCanPlay={(e) => setfeedback(e.target.value)}
              />
            </div> */}
            <div className=' mt-5'>
              <label className='fs-5 fw-semibold mx-auto'>Threshold Point</label>
              <label className='form-check-label'>When we ask your customers if they would recommend you to a friend, all answers this number and above will take them to Google. If the customer chooses a number below the threshold they will go to your feedback form</label>

            </div>

            
            <Slider
  max={10}
  min={1}
    value={feedback}
    onChange={(e)=>setFeedback(e)}
    tooltip={{
      open: true,
      placement: "bottom"
    }}
  />

         

            <div className='card-footer d-flex justify-content-end'>
              <button
                type='button'
                // disabled={formName.length > 0 && is_superadmin==1?'':  CompanyUri.length>0 ? false : true}
                className='btn btn-primary mt-3'
                onClick={(e) => {
                  if (formName.length <= 0) {
                    setFormMSg('Form name is required')
                  }else if(is_superadmin!==1 &&  CompanyUri.length<=0){
                    setURIMSg('Company name is required')
                  }else{
                   e.preventDefault()
                   dispatch({ type: 'threshold', payload: feedback });

                  let data = {
                    feedback,
                    selectBranding,
                  }
                  closeModal(data)
                  
                  let template=[]
                  if(ishide===false){
                  if(selectTemplate){
                template =templateName.find((item)=>item.id===selectTemplate)
                  }else if (!is_superadmin ){
                   template=templateName.find((item)=>item.defaulttem===true)

                  }
                  duplicate(template)
                }
                  setHideTemp(false)
              }
                  // setName('')
                  // setSelectBranding('')
                  // setFeedback(0)
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </form>
    </Modal>
  )
}

export {AddFormName}

const QrFormName = ({isOpen, Idvalue, closeModal}) => {
  const [name, setName] = useState('')
  const [clicked, setClicked] = useState(false)
  const [clicks, setClicks] = useState(false)

  const encodedId = encode(Idvalue)
  let inputValue = `https://accounts.dynamic.reviews/review/${encodedId}`
  const handleClick = (e) => {
    e.preventDefault()
    // let inputValue=`http://frontgoogle.s3-website-us-east-1.amazonaws.com/${encodedId}`
    // copy(inputValue)
    setClicks(!clicks)
  }
  const handleIconClick = (e) => {
    e.preventDefault()
    // let inputValue=`http://frontgoogle.s3-website-us-east-1.amazonaws.com/${encodedId}`
    copy(inputValue)
    setClicked(true)
  }
  return (
    <Modal className='Qrcode' show={isOpen}>
      <div className='modal-content'>
        <form
          className='form'
          action='#'
          id='kt_modal_add_customer_form'
          data-kt-redirect='../../demo7/dist/apps/customers/list.html'
        >
          <div className='modal-header'>
            <h2>Generate Qr Code</h2>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                closeModal(null)
                setClicked(false)
                //  setSelectedBrand([])
              }}
            >
              <span className='svg-icon svg-icon-1'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    opacity='0.5'
                    x='6'
                    y='17.3137'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(-45 6 17.3137)'
                    fill='currentColor'
                  />
                  <rect
                    x='7.41422'
                    y='6'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(45 7.41422 6)'
                    fill='currentColor'
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className='modal-body'>
            <div>
              <QRCode
                size={256}
                style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                value={`https://accounts.dynamic.reviews/review/${encodedId}`}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div className='mt-10 '>
              <label className='fs-5 fontbolt mb-2'>Copy The Url</label>
              <div className='d-flex justify-content-between '>
                <div onClick={handleClick}>
                <Tooltip valign='bottom' title={inputValue}>
                  <input
                    type='text'
                    className='form-control form-control-solid hoverable-input'
                    value={
                      inputValue.length > 30 ? clicks?inputValue:`${inputValue.substring(0, 27)}... `: inputValue
                    }
                    onChange={(e) => {}}
                  />
                  </Tooltip>
                </div>
                <div className='d-flex'>
                  <button
                    className='btn btn-light-primary'
                    onClick={(e) => handleIconClick(e)}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                  >
                    <i className={`bi ${clicked ? 'bi-check-circle ' : 'bi-copy'}`}></i>
                    {clicked ? 'Copied' : 'Copy link'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export {QrFormName}
