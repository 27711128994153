import { useEffect, useRef, useState } from 'react'

import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../_metronic/helpers'
import { DatePicker } from 'antd'
import { format, parseISO } from 'date-fns';
// import {useQueryRequest} from '../agents/core/QueryRequestProvider'
// import {useQueryResponse} from '../agents/core/QueryResponseProvider'
// import {User} from '../ApiCall/type'

// import {object} from 'yup'


const FilterDate = ({fildata,filterfunction}) => {
  // const {updateState} = useQueryRequest()
  // const {isLoading} = useQueryResponse()
  const [role, setRole] = useState('date')
  const valuess = fildata?.map((data) => data?.Dbfield)
  const [lastLogin, setLastLogin] = useState(["", ""]);
  const [OpenMenu,setOpenMenu]=useState(false)
  const handleStartDateChange = (date, dateString) => {
    // const startDate = e.target.value;
      const parts = dateString.split('-');
      const year = parts[2];
      let   day = parts[1]
      const month = parts[0];
    setLastLogin((prevDates) => [`${year}-${month}-${day}`, prevDates[1]]);
  };

  const handleEndDateChange =  (date, dateString) => {
    // const endDate = e.target.value;
    const parts = dateString.split('-');
    const year = parts[2];
    let day = parseInt(parts[1], 10); // Convert day to number
    day = day<31?day + 1:day;
    const month = parts[0];
    day = day < 10 ? '0' + day : day;
    setLastLogin((prevDates) => [prevDates[0], `${year}-${month}-${day}`]);
  };
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const filterData = () => {
    filterfunction(role, '')
    setRole('')
    setLastLogin('')
  }
const menuRef = useRef(null);

useEffect(() => {
  // Add event listener to the document body to listen for clicks
  const handleClickOutside = (e) => {
    console.log('handleClickOutside',e)
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setOpenMenu(false)
    }
  }

  document.addEventListener('click', handleClickOutside)

  return () => {
    // Cleanup the event listener when the component unmounts
    document.removeEventListener('click', handleClickOutside)
  }
}, [])
const disabledEndDate = (current) => {
  // Disable dates after today
  return current && current.valueOf() > new Date().setHours(23, 59, 59, 999);
};
  return (
    <>
      {/* begin::Filter Button */}
      <button
        // disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3 users-list-filters'
        data-kt-menu-placement='bottom-end'
        onClick={()=>setOpenMenu(true)}
        
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */} 
      {/* begin::SubMenu */}
      <div  className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px fixdeshfil ${OpenMenu===true?'show setmanu':''}`}  data-kt-menu='true' >
        {/* begin::Header */}
        <div className='d-flex justify-content-between'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                onClick={()=>setOpenMenu(false)}
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='currentColor'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
        
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Sort by:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setRole('date')}
              value={role}
            >
              {valuess?.map((item, index) => {
                return <option key={index} value={item}>{item.replaceAll('_', ' ')}</option>;
              })}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='row'>

            <div className='col-xl-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Start Date</label>
                {/* <input
                  type='date'
                  className='form-control form-control-solid'
               
                  format={dateFormatList[0]}
                  onChange={handleStartDateChange}
                  value={lastLogin[0]}
                /> */}
                <DatePicker className='form-control form-control-solid' disabledDate={disabledEndDate} onChange={handleStartDateChange} format='MM-DD-YYYY' />

                {/* </select> */}
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>End Date</label>
                {/* <input
                  type='date'
                  className='form-control form-control-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='two-step'
                  data-hide-search='true'
                  onChange={handleEndDateChange}
                  value={lastLogin[1]}
                /> */}
                <DatePicker className='form-control form-control-solid' onChange={handleEndDateChange} disabledDate={disabledEndDate} format='MM-DD-YYYY' />

                {/* </select> */}
              </div>
            </div>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              onClick={()=>{filterData()
                setLastLogin(["",""])
                setOpenMenu(false);
              }
              }
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            // onClick={() => propost.filterfunction(role, lastLogin)}
            >
              Reset
            </button>
            <button
              // disabled={isLoading}
              type='button'
              onClick={() => {filterfunction(role, lastLogin)
                setOpenMenu(false)
              }}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { FilterDate }
