
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_LOCAL_STORAGE_KEY_S = 'kt-auth-react-v-super'
const getToken=()=>{

  
        
        const firstValue = JSON.parse(sessionStorage.getItem(AUTH_LOCAL_STORAGE_KEY))
      
      
        if (firstValue !== null && firstValue !== ''&&firstValue?.api_token) {

         
          return firstValue?.api_token;
        } else {
        
          const secondValue = JSON.parse(sessionStorage.getItem(AUTH_LOCAL_STORAGE_KEY_S))
      
         
          if (secondValue !== null && secondValue !== '') {
          
            return secondValue?.api_token;
          } else {
           
            return null;
          }
        }
      
      
   
 
}
export default  getToken;


