//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import {ActionTypes} from '../constants'

const _ = require('lodash')

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const reportintial = {}

export const reportValuesReducer = (state = reportintial, action) => {
  switch (action.type) {
    case 'Report':
      return action.payload

    default:
      return state
  }
}
