import React, { useEffect, useRef, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { KTCard, KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import CryptoJS from 'crypto-js'
import { CreateMainSector, activeDeActive, deleteMainPackage, getMainSuperAdmin } from './API'
import { datas, datass } from './DataField/Datafield'
import { Modal } from 'react-bootstrap'
import { Alias } from './DataField/Datafield'
import { ListHeader } from '../../profile/components/header/ListHeader'
import { ListView } from '../../profile/components/listView/ListView'
import { useAuth } from '../../auth'
import { useFunction } from '../../auth/core/functionRoles'
// import { ReactFormGenerator } from 'react-form-builder2'
import FormComponent from '../FormComponent/FormComponent'
import SuccessModal from '../popup/SuccessPopup'
import UpdateModal from '../popup/UpdatePopup'
import { ReactFormGenerator } from 'react-form-builder2'

interface ChangesForm {
  cf_id: string,
  custom_form_id: string,
  company_id: string,
  sa_fieldchange: []
}
export function SectorView() {
  const { currentUser, auth, superauth } = useAuth()
  // const onDragEnd = (result: any) => {
  //   if (!result.destination) return

  //   const newData = [...data1]
  //   const [draggedItem] = newData.splice(result.source.index, 1)
  //   newData.splice(result.destination.index, 0, draggedItem)
  //   setData1(newData)
  //   //setState({data: newData})
  // }
  const API_URL = process.env.REACT_APP_API_URL
  let componentRef = useRef(null)
  let componentRefcol = useRef(null)

  const [error, setError] = useState('')
  const [data1, setData1] = useState([])
  // const [rowColumn, setRowColumn] = useState('row')
  const [paginationData, setPaginationData] = useState<any>({})
  const [paginationDataApi, setPaginationDataApi] = useState<any>({ pages: 1, limits: 10 })
  const [sorting, setSorting] = useState<any>([{ fieldName: 'sName', order: 'ASC' }])
  // const [order, setOrder] = useState<any>('ASC')
  const [condition, setCondition] = useState<any>([
    { column: 'sName', operator: 'LIKE', value: '%%' },
  ])
  const { functionName, } = useFunction()
  const [valueDropDown, setValueDropDown] = useState<any>(['sName', 'status'])
  const [isSelected, setIsSelected] = useState<any>([])
  const [show, setShow] = useState(false)
  const [superAdminName, setSuperAdminName] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  const [onEditData, setOnEditData] = useState({})
  const [loading, setLoading] = useState(false)
  const [listloading, setListloading] = useState(false)
  const [loadMoreloading, setloadMoreloading] = useState(false)
  const [successModalOpen, setsuccessModelOpen] = useState(false)
  const [isNoDataSelected, setIsNoDataSelected] = useState(false);
  // const [userForEdit , setUserForEdit] = useState({})
  // const [userForEdit] = useState<User>({
  //   name: user.name || initialUser.name,
  // })
  var token: any = superauth ? superauth?.api_token : auth?.api_token

  const deleteAllData = (e: React.ChangeEvent<HTMLInputElement>, value: any) => {
    if (value === 'full') {
      if (e.target.checked) {
        setIsSelected(data1.map((item: any) => item.sector_id))
      } else {
        setIsSelected([])
      }
    } else {
      if (isSelected.includes(value.sector_id)) {
        let index = isSelected.indexOf(value.sector_id)
        isSelected.splice(index, 1)
        setIsSelected([...isSelected])
      } else {
        setIsSelected([...isSelected, value.sector_id])
      }
    }
    // {
    //   deleteAllData1.length > 0 ? setDeleteAllData1([]) : setDeleteAllData1(e.target.checked)
    // }
  }



  const setFilter = (column: any, row: any) => {


    setCondition([
      { column: Alias(column) ? Alias(column) : 'sName', operator: 'LIKE', value: `%${row}%` },
    ])
  }

  const [successSectorOpen, setSuccessSetor] = useState(false)
  const [updateModelOpen, setUpdateModelOpen] = useState(false)
  const [errorSectorOpen, setErrorSetor] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false);
  const secretKey = 'my-secret-key'
  const [showEvent1, setShowEvent1] = useState([]);
  const [value1, setValue1] = useState<any>([])
  const [formgen, setFormgen] = useState([])
  const [formsuper, setFormsuper] = useState([])
  const [formsuperChanges, setFormsuperChanges] = useState<ChangesForm>({
    cf_id: '',
    custom_form_id: '',
    company_id: '',
    sa_fieldchange: []
  })
  const [showEvents, setShowEvents] = useState<any>([]);

  const decryptData = (encryptedData: any) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  }
  const task_data: any = showEvent1?.filter((formData: any) => formData.form_name === "Sector");
  const taskFormData: any = task_data ? [task_data] : [];
  const filteredDatas1 = taskFormData[0]?.filter((data: any) => data.created_by !== currentUser?.user_id)[0]?.custom_fields
  const customFieldsForTask = formsuperChanges?.sa_fieldchange.length >= 0 ? formsuperChanges?.sa_fieldchange : filteredDatas1
  const filteredDatas = taskFormData[0]?.filter((data: any) => data.created_by === currentUser?.user_id)[0]?.custom_fields


  const getpackageDataApi = async () => {
    setListloading(true)
    let data = {
      fieldName: valueDropDown,
      sorting: sorting,
      pagination: { pages: paginationDataApi?.pages || 1, limits: paginationDataApi?.limits || 10 },

      filters: condition,
      company_id: currentUser?.company_id,
      is_superadmin: currentUser?.is_superadmin,
      // fieldName: ['aName', 'aDesignation', 'aReporting'],
      // sorting: [{fieldName: 'aName', order: 'ASC'}],
      // pagination: {pages: 1, limits: 2},
      // filters: [{column: 'aName', operator: 'LIKE', value: '%%'}],
    }

    const packageData: AxiosResponse<any> = await getMainSuperAdmin(data, token)
    const { currentPage, totalPages, totalCount, data: datas } = decryptData(packageData?.data?.data)

    setPaginationData({
      currentPage,
      totalPages,
      totalCount,
    })
    setData1(datas)
    setListloading(false)
    setloadMoreloading(false)
  }

  // const {
  //   isFetching,
  //   refetch,
  //   data: response,
  // } = useQuery(
  //   `packagages`,
  //   () => {
  //     return getPacked()
  //   },
  //   {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  // )



  useEffect(() => {
    getpackageDataApi()
  }, [paginationDataApi, valueDropDown, sorting, condition])

  // const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //   setRowColumn(e.currentTarget.id)
  // }

  const deletePackages = async (value: any = 0) => {
    try {
      const response: any = await deleteMainPackage(value ? value : isSelected, token)
    if (response.data.code === 200) {
        setSuccessSetor(true);
        setIsSelected([]);
        getpackageDataApi();
      } else if (response.data.code === 404) {
        setErrorSetor(true);
      } else {
        setErrorSetor(true);
      }
    } catch (error) {
      console.error("error", error);
    }
  }

  const handleExistClose = () => {
    setErrorSetor(false)
  }

  // const handleColumnClick = (name: any, orders: any) => {
  //   let alias = datas.filter((item) => item.Dbfield === name)[0]?.alias

  //   setOrder(order === 'ASC' ? 'DESC' : 'ASC')

  //   const isDuplicate = sorting.some((sortField: any) => sortField.fieldName === alias)

  //   if (!isDuplicate) {
  //     // add newSortField to sortFields array
  //     setSorting([...sorting, {fieldName: alias, order: orders}])
  //   } else {
  //     const newSortFields = sorting.filter((sortField: any) => sortField.fieldName !== alias)
  //     setSorting([...newSortFields, {fieldName: alias, order: orders}])
  //   }
  // }

  // const handleColumnClick = (name: any, orders: any) => {
  //   //  if(sort.includes(name)
  //   let alias = datas.filter((item) => item.Dbfield === name)[0]?.alias
  //   if (AscDescDatas.includes(alias)) {
  //     setOrder(order === 'ASC' ? 'DESC' : 'ASC')

  //     const isDuplicate = sorting.some((sortField: any) => sortField.fieldName === alias)

  //     if (!isDuplicate) {
  //       // add newSortField to sortFields array
  //       setSorting([{ fieldName: alias, order: orders }])
  //     } else {
  //       const newSortFields = sorting.filter((sortField: any) => sortField.fieldName !== alias)
  //       setSorting([{ fieldName: alias, order: orders }])
  //     }
  //   }
  // }
  // function truncateString(str: string) {
  //   if (!str?.length) {
  //     return str
  //   } else if (str?.length <= 20) {
  //     return str
  //   } else {
  //     return str.substring(0, 15) + '...'
  //   }
  // }

  // const formik = useFormik({
  //   initialValues: userForEdit,
  //   // validationSchema: editUserSchema,

  //   onSubmit: async (values, {setSubmitting}) => {
  //     // createSuperAdmin(values)
  //   },
  // })

  const cancel = () => {
    setShow(false)
    setError('')
    setValue1([])
    setIsSubmitted(false)
  }

  const handleClose = () => {
    setSuperAdminName('')
    setIsUpdate(false)
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    if (!superAdminName) {
      setError('Please enter the sector name')
      setIsSubmitted(true)
      return
    }
    setLoading(true)
    const f_data = filteredDatas ? filteredDatas : []
    const first: any = [...(Array.isArray(formsuper) ? formsuper : []), ...(Array.isArray(formgen) ? formgen : [])];
    const data = {
      name: superAdminName,
      company_id: currentUser?.company_id,
      is_superadmin: currentUser?.is_superadmin,
      metadata: {
        custom_form: [...customFieldsForTask, ...f_data],
        custom_fields: first
      }
    }
    const { status } = await CreateMainSector(data, token)
    if (status === 200) {
      getpackageDataApi()
      setValue1([])
    }
    setShow(false)
    setError('')
    setIsSubmitted(false)
    setsuccessModelOpen(true)
    setLoading(false)
  }
  const handleSuccessModalClose = () => {
    setsuccessModelOpen(false);
  };
  const handleUpdateModalClose = () => {
    setUpdateModelOpen(false)
  }
  const onEdit = async (item: any) => {
    setShow(true)
    setIsUpdate(true)
    setSuperAdminName(item?.sector_name)
    setOnEditData(item?.sector_id)

    try {
      if (item?.sector_id) {
        const response = await axios.get(`${API_URL}/Company/sectorById/${item?.sector_id}`, {
          headers: {
            'Content-Type': 'application/json',
            token: token,
          },
        })

        setValue1(response?.data?.data?.metadata?.custom_fields)
        setFormgen(response?.data?.data?.metadata?.custom_fields)
        if (response?.data?.data?.metadata?.custom_form) {
          const f_data = filteredDatas ? filteredDatas : []
          const custom_form: any = [...(response?.data?.data?.metadata?.custom_form || []), ...f_data, ...customFieldsForTask];
          const uniqueCustomForm = [];
          const idSet = new Set();

          for (const item of custom_form) {
            if (!idSet.has(item.id)) {
              idSet.add(item.id);
              uniqueCustomForm.push(item);
            }
          }
          setShowEvents(uniqueCustomForm)
        } else {
          setShowEvents([])
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const getFormValue = async () => {

    try {
      const response = await axios.get(`${API_URL}/customForm/getCustomForm/${currentUser?.company_id}/${currentUser?.sector_id}`, {
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      })
      setShowEvent1(response?.data?.data)
      const taskFormData: any = response?.data?.data.filter((formData: any) => formData.form_name === "Sector");
      const d = taskFormData?.filter((data: any) => data.created_by !== currentUser?.user_id);
      // const form_id=taskFormData[0]?.form_id?taskFormData[0]?.form_id:taskFormData[1]?.form_id;
      if (d.length > 0) {
        let url = `${API_URL}/customForm/getChnagesForm/${currentUser?.company_id}/${d[0]?.form_id}`

        fetch(`${url}`, {
          headers: {
            'Content-Type': 'application/json',
            token: token,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setFormsuperChanges(data?.data)
          })
          .catch((error) => console.error(error))
      }

    } catch (error) {
      console.error(error)
    }
  };
  useEffect(() => {
    getFormValue()
  }, [])

  const dataChange = (data: any) => {
    setFormsuper(data)
  }
  // const getFormValue1 = async () => {

  //   try {
  //     const response = await axios.get(`${API_URL}/Company/SectorById/${currentUser?.company_id}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         token: token,
  //       },
  //     })
  //     setValue1(response?.data?.data)

  //   } catch (error) {
  //     console.error(error)
  //   }
  // };
  // useEffect(() => {
  //   getFormValue1()
  // }, [])
  const onEditChange = async (e: any) => {
    e.preventDefault()
    const first: any = [...(Array.isArray(formsuper) ? formsuper : []), ...(Array.isArray(formgen) ? formgen : [])];
    if (onEditData) {
      var custom_form = showEvents
    } else {
      custom_form = [...customFieldsForTask, ...filteredDatas]
    }
    const data = {
      name: superAdminName,
      id: onEditData,
      metadata: { custom_fields: first, custom_form: custom_form }
    }
    const { status } = await CreateMainSector(data, token)
    if (status === 200) {
      getpackageDataApi()
      setValue1([])
      setUpdateModelOpen(true)
    }
    setShow(false)

  }
  const ActiveDeactive = async (id: any) => {
    const { status } = await activeDeActive(id, token)
    if (status == 200) {
      getpackageDataApi()
    }
  }

  const dtachange = (data: any) => {
    setFormgen(data)
  }

  const handleDeleteClose = () => {
    setSuccessSetor(false)
  }
  const handleMultiSelectChange = (selectedOptions: any) => {
    setIsNoDataSelected(selectedOptions.length === 0);
  };
  return (
    <>
      <KTCard className='pcard'>
        {/* Header */}
        <ListHeader
          showListBtn={true}
          isAddBtn={true}
          // {functionName?.includes('Add Users') &&  isAddBtn={true}}
          addTitle={'Add Sector'}
          MultiSelectOptions={datass}
          MultiSelectValue={valueDropDown}
          setMultiSelectValue={setValueDropDown}
          filterData={datas}
          functionName={functionName}
          setfilterfunction={setFilter}
          fullData={data1}
          componentRef={componentRef}
          componentRefcol={componentRefcol}
          isSelected={isSelected}
          onDeleteBtn={() => {
            deletePackages()
          }}
          onAddBtn={() => {
            setSuperAdminName('')
            setShow(true)
            setIsUpdate(false)
          }}
          abcd={(val) => {
            setCondition([{ column: 'sName', operator: 'LIKE', value: `%${val}%` }])
          }}
          onMultiSelectChange={handleMultiSelectChange}
        />
        {isNoDataSelected ? (
          <div
            className='col-sm-4 mb-4 mt-7 fixnotavaibale'
           
          >
            No field has been selected.
          </div>) : (
          <ListView
            valueDropDown={valueDropDown}
            data1={data1}
            setData1={setData1}
            paginationData={paginationData}
            componentRef={componentRef}
            functionName={functionName}
            componentRefcol={componentRefcol}
            setPaginationDataApi={setPaginationDataApi}
            paginationDataApi={paginationDataApi}
            isSelected={isSelected}
            onEditBtn={(item) => {
              onEdit(item)
            }}
            onDeleteBtn={(itm) => {
              deletePackages([itm?.sector_id])
            }}
            onViewBtn={(itm) => {
              //alert('View')
            }}
            onViewBtn1={(itm: any) => {
              ActiveDeactive(itm?.sector_id)
              //alert('View')
            }}
            deleteAllData={(e, value) => {
              deleteAllData(e, value)
            }}
            listloading={listloading}
            setListloading={setListloading}
            loadMoreloading={loadMoreloading}
            setloadMoreloading={setloadMoreloading}
          />)}
        {/* List*/}

        <Modal
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered '
          className='tasksampleModal'
          show={show}
          onHide={handleClose}
        // onEntered={loadStepper}
        >
          <div className='modal-content '>
            <div className='modal-header'>
              <h2>{isUpdate === true ? 'Update' : 'Add Sector'}</h2>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                onClick={() => cancel()}
              >
                <span className='svg-icon svg-icon-1'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='currentColor'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <form className='form px-7 py-5' action='#' id='kt_modal_add_customer_form'>
              {/* <form id='kt_modal_add_user_form' onSubmit={Formik.handleSubmit} noValidate> */}
              {/* begin::Scroll */}
              <div className='row mb-2'>
                <div className='col-xl-12'>
                  <div className='fs-12 fw-semibold mt-2 mb-2'>Sector Name</div>
                  <input
                    type='Sector name'

                    className={isSubmitted && superAdminName?.trim() === '' ? 'form-control fixErrorborder' : 'form-control'}

                    // className='form-control form-control-solid'
                    name='name'
                    placeholder='Name'
                    onChange={(val) => {
                      setSuperAdminName(val.target.value)
                      setError('')
                    }}
                    value={superAdminName}
                  />
                  <p className='errorForcompany'>{error}</p>
                </div>
              </div>
              {(customFieldsForTask?.length > 0 && (value1?.length <= 0 || value1?.length===undefined)) &&
                      <div className='fv-row col-md-12'>
                        <ReactFormGenerator skip_validations={false} hide_actions={true} form_action="/" form_method="POST" data={customFieldsForTask || []} onChange={dtachange} />
                      </div>}
                    {((value1?.length > 0 && customFieldsForTask !== null)) &&
                      <div className='fv-row col-md-12'>
                        <ReactFormGenerator skip_validations={false} hide_actions={true} form_action="/" answer_data={value1} form_method="POST" data={customFieldsForTask || []} onChange={dtachange} />
                      </div>}

                    {(filteredDatas?.length > 0 && (value1?.length <= 0 || value1?.length===undefined)) &&
                      <div className='fv-row col-md-12'>
                        <ReactFormGenerator skip_validations={false} hide_actions={true} form_action="/" form_method="POST" data={filteredDatas || []} onChange={dataChange} />
                      </div>}
                    {(value1?.length > 0 && filteredDatas !== null) &&
                      <div className='fv-row col-md-12'>
                        <ReactFormGenerator skip_validations={false} hide_actions={true} form_action="/" answer_data={value1} form_method="POST" data={filteredDatas || []} onChange={dataChange} />
                      </div>}
              <div className='text-center pt-10 mb-5'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={(e: any) => (isUpdate == true ? onEditChange(e) : onSubmit(e))}
                // disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  {!loading && (
                    <span className='indicator-label'>{isUpdate === true ? 'Update' : 'Submit'}</span>)}
                  {loading && (
                    <span className='indicator-progress fixloadersite' >
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  type='reset'
                  onClick={() => cancel()}
                  className='btn btn-light me-3 editbutton'
                  data-kt-users-modal-action='cancel'
                // disabled={formik.isSubmitting || isUserLoading}
                >
                  Discard
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <SuccessModal show={successModalOpen} onClose={handleSuccessModalClose} />
        <UpdateModal show={updateModelOpen} onClose={handleUpdateModalClose} />

        <Modal
          show={successSectorOpen}
          className='modal-wide'
          dialogClassName='modal-dialog modal-dialog-centered'
        >
          <div className='modal-content'>
            <div className='modal-header '>
              {/* eslint-disable jsx-a11y/heading-has-content */}
              <h5 className='modal-title fs-3'></h5>
              {/* eslint-enable jsx-a11y/heading-has-content */}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2 '
                onClick={handleDeleteClose}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
              </div>
            </div>
            <div
              className='setDelete fw-bolder fs-3 mb-4 successpopup'
            >
              Successfully Deleted
            </div>
            <p
              className='setDelete  fw-semibold fs-5 mb-6 confirmsucces'
             
            >
              Your data has been successfully deleted.
            </p>

            <div className='text-center mb-5'>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                onClick={handleDeleteClose}
              >
                <span className='indicator-label'>Ok</span>
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          show={errorSectorOpen}
          className='modal-wide'
          dialogClassName='modal-dialog modal-dialog-centered'
        >
          <div className='modal-content'>
            <div className='modal-header custom-modal-header '>
              {/* eslint-disable jsx-a11y/heading-has-content */}
              <h5 className='modal-title fs-3'></h5>
              {/* eslint-enable jsx-a11y/heading-has-content */}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2 '
                onClick={handleExistClose}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
              </div>
            </div>
            <div className='setDelete'> <img src={toAbsoluteUrl('/media/avatars/delete.png')} alt='img' />
            </div>
            <p
              className='fw-semibold fs-5 mb-6 errorForcompany'
             
            >
              Unable to Delete Sector: This sector cannot be deleted as it is currently associated with one or more companies.
            </p>

            <div className='text-center mb-5'>
              <button
                type='submit'
                className='btn btn-danger'
                data-kt-users-modal-action='submit'
                onClick={handleExistClose}
              >
                <span className='indicator-label'>Ok</span>
              </button>
            </div>
          </div>
        </Modal>
      </KTCard>
    </>
  )
}
