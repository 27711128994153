import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'
import {getReportData} from '../../../../../app/modules/apps/Forms/store/Report/thunks'

const projects = [
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Dashboard',
    link: '/dashboard',
  },
]

const ReportsTab = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getReportData())
     navigate('/dashboard')
  }, [])
  return (
    <div className='m-0'>
      <div className='m-0'>
        <div className='mb-10'>
          {projects?.map((p) => (
            <Link
              key={p.link}
              to={p.link}
              className='custom-list d-flex align-items-center px-5 py-4'
            >
              <div className='symbol symbol-40px me-5'>
                <span className='symbol-label'>
                  <img
                    src={toAbsoluteUrl(p.image)}
                    alt={p.title}
                    className='h-50 align-self-center'
                  />
                </span>
              </div>

              <div className='d-flex flex-column flex-grow-1'>
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{p.title}</h5>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export {ReportsTab}
