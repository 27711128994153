import axios from 'axios';

// import * as ActivityActions from './actions'

const API_URL = process.env.REACT_APP_API_URL;
const API_URL1 = process.env.REACT_APP_API_URL1;
console.log('API_URL', API_URL);

export const getForm = (id) => async (dispatch) => {
  try {
   if (id) {
    
   
    // await dispatch(ActivityActions.listBranch())
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const { data } = await axios.get(
      `${API_URL}/googlereview/getGoogleRiview/${id}`,
      config
    );
    await dispatch({ type: 'setGoogleFrom', payload: data.data });
   }
    // await dispatch(ActivityActions.listBranchSuccess(data.data))
  } catch (error) {
    // await dispatch(ActivityActions.listBranchFail(error.message))
  }
};

export const setDataForm = (data) => async (dispatch) => {
  try {
    await dispatch({ type: 'setGoogleFrom', payload: data });
    // await dispatch(ActivityActions.listBranchSuccess(data.data))
  } catch (error) {
    // await dispatch(ActivityActions.listBranchFail(error.message))
  }
};

export const saveDataForm = async (datas) => {
  let token = sessionStorage.getItem('kt-auth-react-v');

  let token1 = JSON.parse(token);

  const config = {
    headers: {
      authorizationtoken: token1?.api_token,
      'Content-Type': 'application/json',
    },
  };

  const data  = await axios.post(
    `${API_URL}/googlereview/feedback`,
    datas,
    config
  );
  return data?.data?.data?.rows;
};
