

export const ActionTypes = {
  formvalues: {
    getformvalues: ' GET_formvalues',
    setformvalues: 'SET_formvalues',
    updateformvalues: 'UPDATE_formvalues',
   
  },
 
}
