import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import { UsersListWrapper } from './users-list/AdminList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/apps/sector/user',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SectorPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='user'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Sector</PageTitle>
              {/* <UsersListWrapper /> */}
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/sector/user' />} />
    </Routes>
  )
}

export default SectorPage
