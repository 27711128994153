/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import clsx from 'clsx'
import {LayoutSetup, useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideTabs} from './AsideTabs'
import {AsideFooter} from './AsideFooter'
import {TabsBase} from './Tabs/_TabsBase'
import { useAuth } from '../../../../app/modules/auth'

const AsideDefault = () => {
  const { currentUser } = useAuth()
  const location = useLocation();

  // const {config} = useLayout()
  const {classes} = useLayout()
  const urlPath = location.pathname;
  const feedbackMatch = urlPath.split('/').filter(Boolean)[1];
  let links=''
  if(feedbackMatch=='feedback'){
    links='Feedbacks'
  }
  else if( feedbackMatch  =='eview')
  links='Review'
  else if( feedbackMatch  =='branding')
  links='Branding'

  else if( feedbackMatch  =='organization')
  links='organizations'

  else if( feedbackMatch  =='subscription')
  links='Subscription'

  else if( feedbackMatch  =='reviewform')
  links='Forms'
   else if(feedbackMatch =='form'){
    links='Forms'

   }else{
    links=currentUser?.is_superadmin==1?'organizations':'Reports'
   
   }
   console.log("feedbackMatch",feedbackMatch)
  const [link, setLink] = useState<string>(currentUser?.is_superadmin === 1 ?(links):links)
  console.log("setLink",link)
  const [config, setConfig] = useState(LayoutSetup.config)
  useEffect(() => {
    if (link==='Team' || link==='Forms' || link==='organizations') {
      document.body.setAttribute('data-kt-aside-minimize', '')
      classes.asideToggle.push('')
    }else{
      document.body.setAttribute('data-kt-aside-minimize', 'on')
      classes.asideToggle.push('active')
    }
  }, [link])
  return (
    <div
      id='kt_aside'
      // className={clsx('aside aside-extended', classes.aside.join(' '))}
      className={'aside aside-extended'}
    
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Primary */}
      <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>
        {/* begin::Logo */}
        <div
          className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10'
          id='kt_aside_logo'
        >
          <Link to={`${currentUser?.is_superadmin!==1 ?'/dashboard':''}`}>
            <img src={toAbsoluteUrl('/media/GoogleRev/revlogoLogos-04.png')} alt='logo' className='h-35px' />
          </Link>
        </div>
        {/* end::Logo */}
        {/* begin::Nav */}
        <div
          className='aside-nav d-flex flex-column align-items-center flex-column-fluid  pt-5 pt-lg-0'
          id='kt_aside_nav'
        >
          <AsideTabs link={link} setLink={setLink} />
        </div>
        {/* end::Nav */}
        <AsideFooter />
      </div>
      {/* end::Primary */}
      {config.aside.secondaryDisplay && (
        <>
          {/* begin::Secondary */}
          <div className='aside-secondary d-flex flex-row-fluid'>
            {/* begin::Workspace */}
            <div className='aside-workspace my-5 p-5' id='kt_aside_wordspace'>
              <TabsBase link={link} />
            </div>
            {/* end::Workspace */}
          </div>
          {/* end::Secondary */}
          {/* begin::Aside Toggle */}
          <button
            id='kt_aside_toggle'
            className={clsx(
              'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
              classes.asideToggle.join(' ')
            )}
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            style={{marginBottom: '1.35rem'}}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr063.svg'
              className='svg-icon-2 rotate-180'
            />
          </button>
          {/* end::Aside Toggle */}
        </>
      )}
    </div>
  )
}

export {AsideDefault}
