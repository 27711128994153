import { useEffect, useState } from 'react'

import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../_metronic/helpers'
// import {useQueryRequest} from '../agents/core/QueryRequestProvider'
// import {useQueryResponse} from '../agents/core/QueryResponseProvider'
// import {User} from '../ApiCall/type'

// import {object} from 'yup'

type propes = {
  fildata: any
  filterfunction: (a: any, b: any) => void
}
const UsersListFilters = (propost: propes) => {
  // const {updateState} = useQueryRequest()
  // const {isLoading} = useQueryResponse()
  const [role, setRole] = useState<any>('')
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const valuess: string[] = propost?.fildata?.map((data: any) => data?.Dbfield)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const filterData = () => {
    propost?.filterfunction(role, '')
    setRole('')
    setLastLogin('')
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        // disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3 users-list-filters'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>FieldName:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              {valuess?.map((item: string, index: number) => {
                return <option key={index} value={item}>{item.replaceAll('_', ' ')}</option>;
              })}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Value</label>
            <input
              className='form-control form-control-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setLastLogin(e.target.value)}
              value={lastLogin}
            />

            {/* </select> */}
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            // onClick={() => propost.filterfunction(role, lastLogin)}
            >
              Reset
            </button>
            <button
              // disabled={isLoading}
              type='button'
              onClick={() => propost.filterfunction(role, lastLogin)}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { UsersListFilters }
