export const ActionTypes = {
  token: {
    setToken: 'SET_TOKEN',
  },
  category: {
    createCategory: 'CREATE_CATEGORY',
    createCategoryFail: 'CREATE_CATEGORY_FAIL',
    createCategorySuccess: 'CREATE_CATEGORY_SUCCESS',

    getCategory: 'GET_CATEGORY',
    getCategoryFail: 'GET_CATEGORY_FAIL',
    getCategorySuccess: 'GET_CATEGORY_SUCCESS',

    updateCategory: 'UPDATE_CATEGORY',
    updateCategoryFail: 'UPDATE_CATEGORY_FAIL',
    updateCategorySuccess: 'UPDATE_CATEGORY_SUCCESS',

    deleteCategory: 'DELETE_CATEGORY',
    deleteCategoryFail: 'DELETE_CATEGORY_FAIL',
    deleteCategorySuccess: 'DELETE_CATEGORY_SUCCESS',
  },
  seller: {
    createSeller: 'CREATE_SELLER',
    createSellerFail: 'CREATE_SELLER_FAIL',
    createSellerSuccess: 'CREATE_SELLER_SUCCESS',

    getSeller: 'GET_SELLER',
    getSellerFail: 'GET_SELLER_FAIL',
    getSellerSuccess: 'GET_SELLER_SUCCESS',

    updateSeller: 'UPDATE_SELLER',
    updateSellerFail: 'UPDATE_SELLER_FAIL',
    updateSellerSuccess: 'UPDATE_SELLER_SUCCESS',

    deleteSeller: 'DELETE_SELLER',
    deleteSellerFail: 'DELETE_SELLER_FAIL',
    deleteSellerSuccess: 'DELETE_SELLER_SUCCESS',
  },

  coupon: {
    createCoupon: 'CREATE_COUPON',
    createCouponFail: 'CREATE_COUPON_FAIL',
    createCouponSuccess: 'CREATE_COUPON_SUCCESS',

    getCoupon: 'GET_COUPON',
    getCouponFail: 'GET_COUPON_FAIL',
    getCouponSuccess: 'GET_COUPON_SUCCESS',

    updateCoupon: 'UPDATE_COUPON',
    updateCouponFail: 'UPDATE_COUPON_FAIL',
    updateCouponSuccess: 'UPDATE_COUPON_SUCCESS',

    deletCoupon: 'DELETE_COUPON',
    deletCouponFail: 'DELETE_COUPON_FAIL',
    deletCouponSuccess: 'DELETE_COUPON_SUCCESS',

    countCoupon: 'COUNT_COUPON',
  },
  product: {
    createProduct: 'CREATE_PRODUCT',
    createProductFail: 'CREATE_PRODUCT_FAIL',
    createProductSuccess: 'CREATE_PRODUCT_SUCCESS',

    getProduct: 'GET_PRODUCT',
    getProductFail: 'GET_PRODUCT_FAIL',
    getProductSuccess: 'GET_PRODUCT_SUCCESS',

    updateProduct: 'UPDATE_PRODUCT',
    updateProductFail: 'UPDATE_PRODUCT_FAIL',
    updateProductSuccess: 'UPDATE_PRODUCT_SUCCESS',

    deletProduct: 'DELETE_PRODUCT',
    deletProductFail: 'DELETE_PRODUCT_FAIL',
    deletProductSuccess: 'DELETE_PRODUCT_SUCCESS',
  },
  productCategory: {
    createProductCategory: 'CREATE_PRODUCTCATEGORY',
    createProductCategoryFail: 'CREATE_PRODUCTCATEGORY_FAIL',
    createProductCategorySuccess: 'CREATE_PRODUCTCATEGORY_SUCCESS',

    getProductCategory: 'GET_PRODUCTCATEGORY',
    getProductCategoryFail: 'GET_PRODUCTCATEGORY_FAIL',
    getProductCategorySuccess: 'GET_PRODUCTCATEGORY_SUCCESS',

    updateProductCategory: 'UPDATE_PRODUCTCATEGORY',
    updateProductCategoryFail: 'UPDATE_PRODUCTCATEGORY_FAIL',
    updateProductCategorySuccess: 'UPDATE_PRODUCTCATEGORY_SUCCESS',

    deletProductCategory: 'DELETE_PRODUCTCATEGORY',
    deletProductCategoryFail: 'DELETE_PRODUCTCATEGORY_FAIL',
    deletProductCategorySuccess: 'DELETE_PRODUCTCATEGORY_SUCCESS',
  },
  Branch: {
    createBranch: 'CREATE_BRANCH',
    createBranchFail: 'CREATE_BRANCH_FAIL',
    createBranchSuccess: 'CREATE_BRANCH_SUCCESS',
    createBranch1Success: 'CREATE_BRANCH1_SUCCESS',

    getBranch: 'GET_BRANCH',
    getBranchFail: 'GET_BRANCH_FAIL',
    getBranchSuccess: 'GET_BRANCH_SUCCESS',

    updateBranch: 'UPDATE_BRANCH',
    updateBranchFail: 'UPDATE_BRANCH_FAIL',
    updateBranchSuccess: 'UPDATE_BRANCH_SUCCESS',

    deleteBranch: 'DELETE_BRANCH',
    deleteBranchFail: 'DELETE_BRANCH_FAIL',
    deleteBranchSuccess: 'DELETE_BRANCH_SUCCESS',
  },
  Form: {
    // createCategory: 'CREATE_CATEGORY',
    // createCategoryFail: 'CREATE_CATEGORY_FAIL',
    // createCategorySuccess: 'CREATE_CATEGORY_SUCCESS',

    getForm: 'GET_CATEGORY',
    getFormFail: 'GET_CATEGORY_FAIL',
    getFormSuccess: 'GET_CATEGORY_SUCCESS',

    // updateCategory: 'UPDATE_CATEGORY',
    // updateCategoryFail: 'UPDATE_CATEGORY_FAIL',
    // updateCategorySuccess: 'UPDATE_CATEGORY_SUCCESS',

    // deleteCategory: 'DELETE_CATEGORY',
    // deleteCategoryFail: 'DELETE_CATEGORY_FAIL',
    // deleteCategorySuccess: 'DELETE_CATEGORY_SUCCESS',
  },
  adminPublish: {
    updateCouponSuccess: 'UPDATE_COUPON_SUCCESS1',
    createCouponSuccess: 'CREATE_COUPON_SUCCESS1',
    getCouponSuccess: 'GET_COUPON_SUCCESS1',
  },
}
