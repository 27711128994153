import axios from 'axios'
//import {GetUsersResponse} from './type'
import CryptoJS from 'crypto-js'
const secretKeys = 'my-secret-key'

const encryptData = (data: any, secretKey: string = secretKeys) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey)
  return encryptedData.toString()
}

export const getMainPackage = (packageData: any,token:any) => {
  
  const encryptedData = encryptData(packageData)
  return axios.post(
    `${process.env.REACT_APP_API_URL}/packages/packagess/`,
    JSON.stringify({encryptedData}),
    {
      headers: {
        'Content-Type': 'application/json',
        token:token
      },
    }
  )
}

export const getMainSuperAdmin = (packageData: any,token:any) => {
 
  const encryptedData = encryptData(packageData)
  return axios.post(
    `${process.env.REACT_APP_API_URL}/packages/getSector/`,
    JSON.stringify({encryptedData}),
    {
      headers: {
        'Content-Type': 'application/json',
        token:token
      },
    }
  )
}
export const activeDeActive = (activeId: any,token:any) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/sector/activeDeActive/${activeId}`, {
    headers: {
      'Content-Type': 'application/json',
      token:token
    },
  })
}

export const CreateMainSector = (packageData: any,token:any) => {
  
  //   const encryptedData = encryptData(packageData)
  return axios.post(`${process.env.REACT_APP_API_URL}/sector/createSector`, packageData, {
    headers: {
      'Content-Type': 'application/json',
      token:token,
    },
  })
}

export const deleteMainPackage = (packageData: any,token:any) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}/sector/deleteSector/${packageData}`, {
    headers: {
      'Content-Type': 'application/json',
      token:token,
    },
  })
}
