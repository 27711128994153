import axios from 'axios'
//import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const organisationUrl = `${API_URL}/Company/manageCompany`
export const Sector_Url = `${API_URL}/Company/sector`
export const Invitations_url = `${API_URL}/Company/invitations`
export const getSector_Url = `${API_URL}/Company/getSectors`

export function organisation(data: any) {
  return axios.post(organisationUrl, {
    company_name: data.company_name,
    token: data.token,
  },{headers: {
    'Content-Type': 'application/json',
    token:data.token
  }})
}
export function sectorApi(data: any) {
  return axios.post<any>(Sector_Url, {
    data,
  },{headers: {
    'Content-Type': 'application/json',
    token:data.token
  }})
}
export function getsector(token:any) {
  return axios.get<any>(getSector_Url,{headers: {
    'Content-Type': 'application/json',
    token:token
  }})
}
export function Invitations(data: any) {
  return axios.post<any>(Invitations_url, data,{headers: {
    'Content-Type': 'application/json',
    token:data.api_token
  }})
}
