/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { register } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { Modal, Button } from 'react-bootstrap'

import 'react-toastify/dist/ReactToastify.css'
import GoogleLogin from 'react-google-login'
import axios from 'axios'

interface FormValues {
  name: string
  phone: string
  email: string
  emailPhone: string
  password: string
  confirmPassword: string,
  terms: string
}

export function Registration() {
  const [termsModal, setTermsModal] = useState(false);
  const handleShowTerms = () => setTermsModal(true);
  const handleCloseTerms = () => setTermsModal(false);

  let messageFrom = [
    {
      header: 'Success',
      body: `Please enter the OTP sent to your phone number to complete verification. If you haven't received it, click "Resend OTP". To modify your phone number, click "Edit Phone Number". Remember to verify your email for receiving important notifications. Contact support for assistance. Thank you for verifying your phone number and email.`,
      pos: true,
    },
    {
      header: 'Success',
      body: `Please enter the 6-digit OTP sent to your phone number to verify. If you haven't received it, click "Resend OTP". To modify your phone number, click "Edit Phone Number". For assistance, contact our support team. Thank you for verifying your phone number`,
      pos: true,
    },
    {
      header: 'Success',
      body: `Thank you for registering! We have sent a verification link to your email inbox. Please check your email and click on the link to verify your email address. If you haven't received the email, please check your spam or junk folder. If you still encounter any issues, please contact our support team for assistance. Thank you!`,
      pos: false,
    },
  ]
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { saveAuth,setCurrentUser } = useAuth()
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [isValid, setIsValid] = useState(false)
  const [errorMessages, setErrorMessages] = useState<any>([])
  const [Googleloading, setGoogleLoading] = useState(false)
  // const [showPassword, setShowPassword] = useState(false)
  const [passwordType, setPasswordType] = useState('password')
  const [passwordType1, setPasswordType1] = useState('password')
  const [resValue, setResValue] = useState<any>({
    name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [ExistV, setExistV] = useState('');
  const clientId = "727700952240-hck59bm10ivgn3g90pfi3sj88521r4np.apps.googleusercontent.com";
  const API_URL = process.env.REACT_APP_API_URL
  const [data , setData] = useState('');

  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState<Partial<FormValues>>({})
const   [passErrors,setPassErrors] = useState<Partial<FormValues>>({})
useEffect(()=>{
  const log =async()=>{
  if(data){
    setGoogleLoading(true);
    const locationsUrl='https://www.googleapis.com/oauth2/v2/userinfo'
    const locationsResponse = await axios.get(locationsUrl, {
      headers: {
        Authorization: `Bearer ${data}`,
      },
    }); 
    // var filteredData
    // var accounts
    // var d
    // try {
    //   const accountsUrl = 'https://mybusinessbusinessinformation.googleapis.com/v1/accounts';

    //   const accountsResponse = await axios.get(accountsUrl, {
    //     headers: {
    //       Authorization: `Bearer ${data}`,
    //     },
    //   });
    //    accounts = accountsResponse?.data?.accounts;
    //   const MapUrl = `https://mybusiness.googleapis.com/v1/${accounts[0]?.name}/locations/?read_mask=name,storefrontAddress,title,languageCode,profile,phoneNumbers,metadata`
    //   const GetMapUrl: any = await axios.get(MapUrl, {
    //     headers: {
    //       Authorization: `Bearer ${data}`,
    //     },
    //   });
    //    d = GetMapUrl.data.locations
    //   filteredData = d.map((item: any) => {
    //     return {
    //       title: item.title,
    //       newReviewUri: item.metadata ? item.metadata.newReviewUri : null
    //     };
    //   });
    // } catch (e) {
    //   console.log(e)
    // }
    const user = locationsResponse?.data;   
    const value={
      email:user?.email,
      user_name:user?.name,
      // googlemetadata:filteredData,
      is_admin:true,
      metadata:{user_photo:user?.picture},
      // account:accounts[0]?.name,
      // location:d
    }
  try {
    const response = await axios.post(`${API_URL}/user/Googlelogin`, value);
    const {email,user_name,is_admin,sector_id,user_id,company_id,googlemetadata}=response?.data?.data
    const auth:any={
      email: email,
      user_id:user_id,
      user_name: user_name,
      is_admin : is_admin,
      sector_id: sector_id,
      company_id: company_id,
      api_token: response?.data?.api_token,
      // googlemetadata:googlemetadata
    }
    if(response.data.status ===200){
      setGoogleLoading(false);
      auth['login'] = 'direct'
      auth['window'] = 1
      saveAuth(auth)
      setCurrentUser(response?.data?.data)
    }else if(response.data.status ===201){
      setGoogleLoading(false)
        saveAuth(auth) 
        setCurrentUser(response?.data?.data) 
        navigate('/organisation')       
      
    }
    // Handle response data as needed
  } catch (error) {
    setGoogleLoading(false)
    console.error('Error:', error);
    // Handle error as needed
  }
  }}
  log()
},[data])
const onSuccess = async (res:any) => {
  const token:any = `${res.accessToken}`;
  if (token) {
    setData(token);
    localStorage.setItem('bussiness_token', token);
  }
};
const onFailure = (res:any) => {
  console.log("Login failed", res);
  // Handle the Google login response here
};
  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value
    if(confirmPassword) {
      setPasswordMatch(event.target.value===confirmPassword)
    }
    setPassword(newPassword)
    if (newPassword && newPassword !== '') {
      const validationResult = validatePassword(newPassword)
      setErrorMessages(validationResult.errorMessages)
    }
    const { name, value } = event.target
    var newErrors = passErrors;

    if (name === 'password') {
      if (value && value !== '') {
        newErrors.password = ''
      }
      else {
        newErrors.password = 'Please enter password with 8 or more characters with a mix of letters, numbers, and symbols.'
      }
    }
    setPassErrors(newErrors)


  }

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value)
    setPasswordMatch(event.target.value === password)
    // setPasswordMatch(event.target.value === password)
  }

  const validatePassword = (password: any) => {
    const uppercaseRegex = /^(?=.*[A-Z])/
    const numberRegex = /^(?=.*\d)/
    const symbolRegex = /^(?=.*[@$!%*?&#()_-])/
    const lengthRegex = /^.{8,}$/

    const errors = []
    if (password) {
      if (!uppercaseRegex.test(password)) {
        errors.push('Password must contain at least one uppercase letter')
      }

      else if (!numberRegex.test(password)) {
        errors.push('Password must contain at least one number')
      }

      else if (!symbolRegex.test(password)) {
        errors.push('Password must contain at least one symbol')
      }

      else if (!lengthRegex.test(password)) {
        errors.push('Password must be at least 8 characters long')
      }
    }
    else {
      errors.push('Password can not be blank');
    }

    return {
      isValid: errors.length === 0,
      errorMessages: errors,
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    var newErrors = errors;
    if (name === 'toc') {
      setResValue({ ...resValue, [name]: e.target.checked })
      if (e.target.checked) {
        newErrors.terms = ''
      }
      else {
        newErrors.terms = 'Before proceeding, please check the box to accept the terms and conditions.'
      }
    }
    else {
      setResValue({ ...resValue, [name]: value })
    }

    if ((name === 'email' && value !== '') || (name === 'phone' && value !== '')) {
      newErrors.emailPhone = ''
      if (name === 'phone') {
        if (value === '' || phoneNumber(value)) {
          newErrors.phone = ''
        }
        else {
          newErrors.phone = 'Invalid phone number'
        }
      }
      if (name === 'email')
        if (value === '' || isValidEmail(value)) {
          newErrors.email = ''
        }
        else {
          newErrors.email = 'Invalid email address'
        }
    }

    if (name === 'name') {
      if (value && value !== '') {
        newErrors.name = ''
      }
      else {
        newErrors.name = 'Please fill in your name as it is a mandatory field to continue'
      }
    }

    setErrors(newErrors)
  }
  const phoneNumber = (phoneNumber: string) => {
    var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i)
    return pattern.test(phoneNumber) && phoneNumber.length < 15 && phoneNumber.length > 9
  }
  const isValidEmail = (email: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const newErrors: Partial<FormValues> = {}
    const validationResult = validatePassword(password)

    if (!resValue.name) {
      newErrors.name = 'Please fill in your name as it is a mandatory field to continue'
    }
    if (!resValue.phone && !resValue.email) {
      newErrors.emailPhone = 'Please provide either your phone number or email address'
    } else {
      if (resValue.phone && resValue.phone !== '' && (!phoneNumber(resValue.phone))) {
        newErrors.phone = 'Invalid phone number'
      }
      if (resValue.email && resValue.email !== '' && (!isValidEmail(resValue.email))) {
        newErrors.email = 'Invalid email address'
      }
    }
    if (!resValue.toc) {
      newErrors.terms = 'Before proceeding, please check the box to accept the terms and conditions.'
    }
    if (!password) {
      newErrors.password = 'Please enter password with 8 or more characters with a mix of letters, numbers, and symbols.'
    }

    setErrorMessages(validationResult.errorMessages)
    setPasswordMatch(confirmPassword === password)
    setErrors(newErrors)
    setPassErrors(newErrors)

    if (
      Object.keys(newErrors).length === 0 &&
      validationResult.isValid &&
      confirmPassword === password
    ) {
      //if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true)
        resValue.password = password
        resValue.is_admin = true
        const { data: auth } = await register(resValue)
        setLoading(false)

        saveAuth(auth)
        if (auth.code === 200) {
          if (auth.message === 'Email and phone number sent otp and url') {
            navigate('/auth/enter-otp', { state: messageFrom[0] })
          } else if (auth.message === 'Phone number sent otp') {
            navigate('/auth/enter-otp', { state: messageFrom[1] })
          } else if (auth.message === 'Email sent url') {
            navigate('/auth/enter-otp', { state: messageFrom[2] })
          } else {
            navigate('/organisation', { state: messageFrom[0] })
          }
        }

        if (!auth.hassuccessed) {
          setShowModal(true)
          setExistV(auth.messageCode)
        }
      } catch (e) {
        setLoading(false)
        setMessage('Network Error')
      }
    }
  }

  // const [mess, setMess] = useState<any>({header: '', body: '', pos: false})

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // const handleShowModal = () => {
  //   setShowModal(true)
  // }
  const goLogin = () => {
    setShowModal(false)
    navigate('/auth/login')
  }
  const togglePassword = (e: any) => {
    e.preventDefault()
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }
  const togglePassword1 = (e: any) => {
    e.preventDefault()
    if (passwordType1 === 'password') {
      setPasswordType1('text')
      return
    }
    setPasswordType1('password')
  }

  return (
    <>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-lg-500px p-10 custmWdthUsr'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'
              noValidate
              id='kt_login_signup_form'
              onSubmit={handleSubmit}
            >
              <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>

                <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
              </div>

              <div className='row g-3 mb-9 loginGoogle'>
                {/* <div className='col-md-6'> */}
                {Googleloading === false ? (<>
                  <GoogleLogin
                    clientId={clientId}
                    buttonText={"Login with Google"}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    //isSignedIn={true}
                    scope="https://www.googleapis.com/auth/business.manage"
                    accessType="offline"
                  />
                </>
                ) : (
                  <>
                    <button
                      className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                    >
                      <span className='indicator-progress fixloadersite'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </button>
                  </>
                )}
                {/* </div> */}

                {/* <div className='col-md-6'>
                  <a
                    href='#'
                    className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                  >
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/auth/apple-black.svg')}
                      className='theme-light-show h-15px me-3'
                    />
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/auth/apple-black-dark.svg')}
                      className='theme-dark-show h-15px me-3'
                    />
                    Sign in with Apple
                  </a>
                </div> */}
              </div>

              <Modal show={showModal} onHide={handleCloseModal} centered className='accountModal'>
                <Modal.Header closeButton>
                  <Modal.Title>{ExistV === 'EmailPhone' ? 'Email and Phone number' : ExistV === 'Email' ? 'Email' : 'Phone number'} already exists </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    "It seems that the provided {ExistV === 'EmailPhone' ? 'email and phone number' : ExistV === 'Email' ? 'email' : 'phone number'} already exists in our
                    database. Please try logging in using your existing account credentials. If you
                    have forgotten your password, you can use the Forgot Password option to reset
                    it. If you need further assistance, please contact our support team. Thank you."
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' onClick={handleCloseModal}>
                    Close
                  </Button>

                  <Button variant='primary' onClick={() => goLogin()}>
                    Go To Login
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className='separator separator-content my-14'>
                <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
              </div>

              <div className='fv-row mb-4'>
                <input
                  placeholder='Name'
                  type='text'
                  name='name'
                  autoComplete='off'
                  onChange={handleInput}
                  className='form-control bg-transparent'
                />
                {errors.name && <span className='loginfixderror'>{errors.name}</span>}
              </div>
              <div className='fv-row mb-4'>
                {/* <div className='col-xl-6'>
                  <div className='fv-row mb-4'>
                    <input
                      placeholder='Phone'
                      type='text'
                      onChange={handleInput}
                      name='phone'
                      className='form-control bg-transparent inputfixregister'
                    />
                    {errors.phone && <span className='loginfixderror'>{errors.phone}</span>}
                  </div>
                </div> */}
                {/* <div className='col-xl-6'>
                  <div className='fv-row mb-4'> */}
                    <input
                      placeholder='Email'
                      type='email'
                      autoComplete='off'
                      name='email'
                      onChange={handleInput}
                      className='form-control bg-transparent'
                    />
                    {errors.email && <span className='loginfixderror'>{errors.email}</span>}
                  {/* </div>
                </div> */}
                {errors.emailPhone && <span className='loginfixderror'>{errors.emailPhone}</span>}
              </div>

              <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <div className='position-relative mb-3' id='setpass'>
                    <input
                      className='form-control bg-transparent'
                      type={passwordType}
                      placeholder='password'
                      autoComplete='off'
                      name='password'
                      onChange={handlePasswordChange}
                    />
                    <div id='passbutton'>
                      <button className='btn  ' onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <i className='bi bi-eye-slash  '></i>
                        ) : (
                          <i className='bi bi-eye '></i>
                        )}
                      </button>
                    </div>

                    {!isValid && (
                       <div className='text-muted '>
                     <span className='loginfixderror'></span> 
                     </div>
                      // <ul className='loginfixderror'>

                      //   {/* {errorMessages.map((message: any, index: number) => (
                      //     <li key={index}>{message}</li>
                      //   ))} */}
                      // </ul>
                    )}
                  </div>
                </div>
                {passErrors.password?( <span className='loginfixderror'>{passErrors.password}</span>):( <>
                  {errorMessages && <span className='loginfixderror'>{errorMessages}</span>}
                  </> )}            
              </div>
              <div className='fv-row mb-8' id='setpass'>
                <input
                  placeholder='Confirm Password'
                  type={passwordType1}
                  autoComplete='off'
                  className='form-control bg-transparent'
                  onChange={handleConfirmPasswordChange}
                />
                <div id='passbutton'>
                  <button className='btn  ' onClick={togglePassword1}>
                    {passwordType1 === 'password' ? (
                      <i className='bi bi-eye-slash  '></i>
                    ) : (
                      <i className='bi bi-eye '></i>
                    )}
                  </button>
                </div>
                {!passwordMatch && <span className='loginfixderror'>The passwords you've entered do not match</span>}
              </div>
              <div className='fv-row mb-8'>
                <label className='form-check form-check-inline'>
                  <input className='form-check-input' type='checkbox' name='toc' value='1' onChange={handleInput} />
                  <span className='form-check-label fw-semibold text-gray-700 fs-base ms-1 termAtReg'>
                    <span>I Accept the</span>
                    <Button variant="primary" className="termMdlText" onClick={handleShowTerms}>Terms</Button>
                  </span>
                </label>
                <div>
                </div>
                {errors.terms && <span className='loginfixderror'>{errors.terms}</span>}
              </div>

              <div className='d-grid mb-10'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress fixloadersite'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                {message && (
                  <div className='alert alert-danger alert-dismissible text-center mt-10' role='alert'>
                    {message}
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='alert'
                      aria-label='Close'
                    ></button>
                  </div>
                )}
              </div>

              <div className='text-gray-500 text-center fw-semibold fs-6'>
                Already have an Account?
                <Link to='/auth/login'  className='link-primary forpasswordfix'>
                  Sign in
                </Link>
              </div>
            </form>
          </div>
        </div>

        <Modal show={termsModal} onHide={handleCloseTerms} className="termModalTxt">
            <Modal.Header closeButton>
              <Modal.Title>Term Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body className='termModalBody'>
              <div className='termModalUpr'>

                <div className='termModal'>

                <div>
                <h1>Service Terms and Conditions</h1>
                <h2>Terms and conditions applicable to the use of the Services</h2>        
                <h3>Effective date: December 6, 2023.</h3>
                <p>The platform accessible through the www.dynamic.reviews domain name (the “Site”) and the services offered through the Site are provided by DYNAMIC REVIEWS (hereinafter referred to as “us”, “we” or the “Company”), 
                  a U.S. entity with registered address at 20289 County Rd 181, Flint, TX 75762 (United States). and conducts business under the ‘Dynamic Reviews‘ trade name. You may contact us regarding Dynamic Reviews by filling 
                  out our Contact Form or sending an email at info@dynamic.reviews.</p>
                  </div>

                <div>
                <label>1. General</label>
                <p>These Service Terms and Conditions (“STC”) govern the use of the services offered by us, consisting in the creation of online forms, the subsequent collection, storage and related information management, and any other 
                  services as may be offered by us from time to time (the “Services”). Terms of Use are included into this STC by reference.</p>
                <p>In order to be able to use the Services, you will need to create an Account (as this term is further described in section 2.a below) and comply with other requirements set forth in these STC. Please note that the Account also enables you to 
                  use the functionalities aimed at interacting with the product accessible through the Site. Should you decide to use those functionalities, the Developer Terms and Conditions apply to you in addition to these STC.</p>
                </div>
                
                <div>
                <label>2. Access to the Services; Account Creation</label>
                <p><b>a) Eligible individuals</b></p>
                <p>Services are offered to individuals and companies, either conducting business activities on their own or acting as consumers. For purposes of these STC, ‘consumer’ shall be 
                  interpreted as any individual or company using the Services for a purpose other than to conduct a business or commercial activity. If you are creating an account for your company, 
                  you understand and agree that said company will be understood to be the owner of the Account and, hence, any contents created using the Services, and any data collected through the Services will be 
                  understood to belong to your company, unless otherwise agreed between you and your company.</p>
                <p>The Services are only addressed to individuals being sixteen (16) years of age or older. By filling out the registration forms and requesting into our Services, you warrant and represent that you have that legal age.</p>
                </div>

                <div>
                <p><b>b) Account creation</b></p>
                <p>In order to access the Services, you will need to register and create an account (the “Account”). To this end, you must provide true, current, complete, 
                  and accurate information, as requested during the registration process, that refers to you. You cannot sign up or otherwise create an account with us on 
                  behalf of a third party.</p>
                <p>If you are an individual, you should create a personal and non-transferable Account, which will allow to use the Services for your personal purposes only.</p>
                <p>If you are a company, you should create a business Account, which will allow you to use the Services under a company/group name. In that case, you warrant and represent that you can legally enter into these STC on behalf of your company. Note that account sharing is not permitted under these STC and, thus, you should obtain as many seats as needed for each individual using an Account within your organization.</p>
                <p>If you create an Account, you will be able to opt for any of the plans described in the Pricing section of Dynamic Reviews.  Among other functionalities, these plans enable you to manage shared workspaces with as many Accounts as you have selected in your plan, by sending an invitation to collaborate with other users owning an Account with us. The owner of the paid plan will have limited admin 
                  rights to control contents created and manages from the rest of Accounts. It is your duty to make sure that the other users understand the impact for their privacy and content ownership when accepting to be part of your plan, and you shall indemnify, defend, and hold us harmless of any costs or damages directly or indirectly related to these matters.</p>
                <p>We may provide different plans offering different functionalities and features, and subject to different pricing conditions. Services definition may vary from time to time and further and more detailed information on the current features and functionalities of the Service is provided during the signup process.</p>
                <p>Account ownership is based on the data provided when signing up and, to this end, it is important as mentioned above that all information you have submitted is true, accurate, current, and complete. You undertake to notify us any changes to the information submitted upon sign-up or thereafter so as to keep any information we may have in our records current and accurate. Note that when you are providing your 
                  information and accepting these STC, you are entering into an agreement with us which describes which are the obligations we have with regards to each other. If you have problems accessing or logging in into the Services, please visit our Dynamic Reviews Help Center or contact us.</p>
                </div>

                <div>
                <p><b>c) Provision of the Services</b></p>
                <p>Once an Account has been successfully created, Services will be available and ready to use. Note, however, that access to and use of certain functionalities and Services are only available to certain plans and may be subject to payment requirements. We will provide any information in connection with the same in the Site.</p>
                <p>This section does not preclude your rights as a consumer under section 7 below—if you are using the Services as a consumer, you are entitled to cancel and withdraw from these STC and the Services under the terms and conditions detailed in that section.</p>
                </div>
                </div>

                <div>
                <label>3. Use of your Account and Services, and Your Contents</label>
                <p><b>a) Account security and credentials</b></p>
                <p>Accounts are to be used by you, and it is strictly forbidden to share or allow others to use it. You must keep credentials for your Account secured at all times. It is strictly forbidden to share said sets of data with any third parties, or to write them down for recovery purposes. Should you suspect that your Account or your credentials have been or are being used by a third party, or have been compromised, you must contact us immediately through our Dynamic Reviews Contact Form. Otherwise, we may attribute all use of your Account to you, and you agree to be responsible for all activities that occur under your Account.</p>
                <p><b>b) Use of your Account and Services</b></p>
                <p>You must use your Account and the Services complying with law, public order, and any guidelines in our Help Center. Our Help Center includes as well the documentation and instructions to use our Services. In particular but without limitation, you shall not:</p>

                <ul>
                <li>Access the Site, Accounts and/or the Services by any means other than through interfaces provided by us and as otherwise expressly authorized under these STC;</li>
                <li>Use the Services as a hosting service or hosting system only;</li>
                <li>Use the Services, or any responses or media within the Services to create web pages or for hosting or supporting online resources, or as a data repository;</li>
                <li>Avoid, bypass, remove, deactivate, impair, descramble or otherwise tamper the security measures, usage rules or other protection measures implemented by us, our service providers or any third parties to protect the Site, the Accounts or the Services, as well as the restricted features or functionalities available for given categories of Accounts other than the one you are holding, or to attempt to do any of those actions;</li>
                <li>Access, tamper with, or use non-public areas of the Service or the Site, the computer systems of the Company, or the technical delivery systems of our providers;</li>
                <li>Use any metatags or other hidden text or metadata in the Site or Services, as well as forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Service;</li>
                <li>Use, display, mirror, or frame the Site or Services, any individual element within the Site or Service, the layout and design of any portion of the Service or the Site, or the intellectual property rights and other proprietary rights of the Company;</li>
                <li>Attempt to access or search the Services or Site, or scrap or download content from the Services or Site, or otherwise use, upload content to, or create new links, reposts, or referrals in the Services or Site through the use of any engine, software, tool, agent, device or mechanism (including automated scripts, spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by us or other generally available third party web browsers or search engines;</li>
                <li>Reverse engineer, decompile or disassemble software used in connection with Dynamic Reviews, Site or Services;</li>
                <li>Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services or the Site;</li>
                <li>Reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purpose any portion of the Site or the Services, or your access to or use of the Site or Services;</li>
                <li>Impersonate or misrepresent your affiliation with any person or entity, as well as stalk or harass other users or third parties, or share or use offensive or pornographic materials;</li>
                <li>Activities such as vulnerability scanning, load testing, penetration tests or bypassing our security measures in any intended way are strictly prohibited to be carried out on our platform without our previous written approval;</li>
                <li>Use the Services and, in particular, the functionalities aimed at ensuring interaction of the Services and our product to monitor the availability, performance or functionality of our Services or the Site, or for benchmarking or other competitive purposes;</li>
                <li>Collect credit card information (unless using the specific questions blocks provided by us), passwords or similar login credentials;</li>
                <li>Send electronic communications that are not expressly requested or authorized by the recipients, or sending mass and/or repetitive electronic communications (spam). In this sense, you must not use the Services to send any communications in a way not permitted by or compliant with any applicable laws or industry standards, or to any recipient who has opted out, unsubscribed, or otherwise objected to receiving such messages from you or another party on whose behalf you may be mandated; or/and</li>
                <li>Otherwise, use the Account, Site or Services in a manner contrary to the rights and legitimate interests of the Company or any other third party, or in any other manner that may tamper, disrupt, overload, or otherwise damage the Site or the Services. You may let us know about any abuse through our Contact Us form.</li>
                </ul>

                <p>For avoidance of doubt, you—or any third party authorized by you—may carry out any action that enables the Services to interoperate and communicate with a given software program, provided that any such integration has been developed by means of the public APIs and related products and services provided by us as part of our range of technical products for developer and integrator users. You understand that we do not control the use of any information collected by third parties—regardless of whether said collection of information took place in the past, is taking place in the present, or is intended to be carried out in the future—by means of said integrations and that further third parties’ terms and conditions may apply to your use of APIs. Therefore, you understand and agree that we shall not defend, indemnify, or hold you harmless for any and all costs or damages arising from those third party actions and integrations.</p>
                <p><b>c) Usage limits</b></p>
                <p>You shall only use the Services in full compliance with the conditions set forth in these STC and according to the contracted plan limits as described in the Pricing section of Dynamic Reviews, which include, among others, limitations to the number of features that can be used, questions blocks that can be added to a form, or the number of authorized responses and/or users.</p>
                <p>Up to certain limits and for certain plans, you may opt to purchase add-ons to your plan at any time to increase, for instance, the number of available responses of your plan, or add other features. These add-ons can be purchased in the ‘Settings’ menu of your Account, and are valid upon activation and for a period of time equal to the subscription you have purchased (i.e. monthly / yearly).</p>
                <p>In the event that the authorized responses limits are reached, we will not be required to collect, store and/or process any response in excess of the applicable limits. You may opt to activate the ‘upgrade my plan’ feature as further described in section 5 of the Payment Terms and Conditions.</p>

                <p><b>d) Authentication Services</b></p>
                <p>You may decide to activate our authentication feature, which may be provided by us, Google LLC, or Google Ireland Limited, which enables the email address and passwords validation process used for purposes of logging in to the Service. If you opt to use this feature, security, password and other policies of Dynamic Reviews, Google LLC, or Google Ireland Limited apply, and we strongly recommend you to familiarize yourself with said policies and that you practice common sense in the administration of your user IDs and passwords.</p>

                <p><b>e) Beta Service</b></p>
                <p>You may be offered to take part in early access programs to use so-called alpha or beta versions of the Services (“Beta Services”). Beta Services may not work in accordance with the documentation we may provide you with, or they may contain errors, defects or bugs, as you acknowledge and agree. Beta Services are not covered under any service level commitments under these STC, and, as an exception to the provisions in sections 9, 10 and 11, we do not make any sort of representations and warranties, and disclaim any liabilities regarding Beta Services. Beta Services may be discontinued at any time, for no reason and without prior notice, and nothing in these STC shall be construed as requiring us to release Beta Services as part of our regular Services.</p>

                <p><b>f) Materials available in the Services or the Site</b></p>
                <p>The Site and the Services may include information, graphics, text, images, and other materials uploaded by other Account holders or third parties. Said materials are solely for your use in connection with the Site and Services, and their legality, accuracy, and completeness are the sole responsibility of the party that have uploaded them to or provided as part of the Site or the Services. Use of the materials may be subject to specific terms and conditions or license terms, and you are responsible to obtain any required licenses or authorizations, and to comply with any licenses or terms and conditions applicable to them.</p>

                <p><b>g) Services and third-party service providers</b></p>
                <p>To ensure you are provided with high-quality Services, from time to time we may rely on third party service providers. You understand that those providers act beyond our reasonable control and that we shall not be held liable for any damages caused by an action or omission attributable to them.</p>
                <p>Also, you may decide to use third parties to process the information you may collect through the Services (e.g. by using webhooks). In all those cases, you acknowledge and agree that those third parties are beyond our reasonable control, and that we will not be liable for any damages arising from the use of said information by them, or in the event that you decide to transfer information to those third parties by using non-secured means (e.g. non-https transfer protocols). We recommend that you carefully review any terms and conditions governing the use of those third parties’ services and any integration tools they may offer before you start using their services. Note that the use of said services may result in the transmission of any kind of information (either confidential or having a personal nature, among others) outside our platform, and third parties not related to the Company may subsequently be gaining access to, modifying, or even deleting said information.</p>

                <p><b>h) Your contents</b></p>
                <p>We do not claim ownership on the contents you may upload or otherwise use in connection with the Site or Services. However, to ensure we can provide you with the Services or access to the Site, you grant us a worldwide, royalty-free, transferable, sublicensable, non-exclusive license to use, reproduce, distribute, communicate and public perform or display (including, among others, the rights to broadcast and transmit), transform and modify, and/or adapt your contents in connection with the operation of the Site and/or the Services. This license is limited to the extent necessary to provide you with the Services only, and we shall not use your contents for any other purposes. You represent and warrant that you have the rights necessary to grant the license hereunder, and that your contents do not infringe the law or third party rights or interests.</p>
                <p>Please note that by submitting content (photos or videos) into the Service, said contents are made publicly available to third parties. Please evaluate whether you want to share said content under those conditions before submitting them as part of the Services.</p>

                <p><b>i) Third parties’ intellectual property & other proprietary rights</b></p>
                <p>Without prejudice to section 3.b above, you accept not to upload into the Services or the Site, or post, email, transmit, share, or otherwise use, in conjunction with, or related in any manner with the Services or the Site, content for which you do not have the prior authorization of their titleholders. We are not responsible for said content nor the actions you may take with respect to the content, and you shall not use third party content unless you have first obtained the permission of its owner.</p>
                <p>By way of example, you shall not use photographs, music, text, graphics, information, trademarks, trade names, or other content protected under intellectual property rights that are not yours, except when the corresponding owner has expressly given its approval. It is strictly forbidden to use the Services to circumvent the rights of any titleholder upon its intellectual property or other exclusive rights, such as, for instance, providing through the Services links to P2P platforms including infringing materials.</p>
                <p>Notwithstanding section 11 below, we may delete at any time any content that breaches this section, without prior notice and accepting no liability for any such deletion.</p>

                <p><b>j) Review of your contents</b></p>
                <p>You acknowledge that, in order to ensure compliance with legal obligations, prevent phishing or fraud, or when unlawful content is reported to us, we may be required by third parties to review certain content submitted by you to determine whether it is illegal or whether it breaches these STC. We may at our sole discretion modify, prevent access to, delete, or refuse to display content that we believe violates the law or these STC. However, you acknowledge that we have no obligation to monitor or review any content submitted by you.</p>

                <p><b>k) Obligations vis-à-vis Respondents</b></p>
                <p>Your use of the Services may result in the collection and further processing and analysis by you of information belonging to third parties (the “Respondents”). Any contractual relationship existing with Respondents is entered into between you and them. You are fully responsible for meeting any applicable obligations when contacting Respondents and processing their data.</p>

                <p><b>l) Collaboration with us</b></p>
                <p>You undertake to comply with any instructions or recommendations given by us or by any individual acting on behalf of the Company in connection with the use of the Site, your Account, or the Services.</p>

                <p><b>m) Specific features</b></p>
                <p>Please, consider that certain features that we may offer from time to time require additional terms and conditions. In such cases, please note that you can always choose not to use such features and continue to enjoy the rest, without this section being applicable to you.</p>
                <p>The ““Typeform AI” feature may contain IP, images and colors that we have obtained from BrandFetch by including the URL that you have previously provided us with on our platform during the onboarding survey (the “Website Content”).</p>
                <p>For us to offer you these new features, we need the collaboration of third parties such as BrandFetch or Open AI. Please, acknowledge that neither BrandFetch nor Open AI are an affiliate, partner, agent or representative of us. We do not own the Website Content and do not make any guarantees, representations or warranties of any kind to you regarding the ownership or your right to use the Website Content. We disclaim any liability for any third-party IP, including their accuracy, integrity, quality, legality, usefulness or safety, or any intellectual property rights therein. The availability of any third-party IP does not imply our endorsement or affiliation with any provider of such third-party IP and does not create any legal relationship between such provider and you.</p>
                <p>You are solely responsible for your use of Website Content on the Typeform AI feature, and any losses or liabilities of any kind associated to that use. You shall comply with all rights, obligations, conditions, policies, laws and regulations pertaining to such third-party IP. You shall defend us, indemnify us and hold harmless from and against any and all losses, settlements, damages, liabilities, judgements, obligations, fines or sanctions, costs, and expenses (including reasonable attorney’s fees) (collectively “Losses”), arising out of any claim, proceeding, demand, suit or action (collectively “Actions”) brought by a third party related to your use of the Typeform AI feature and Website Content.</p>
                <p>By using Typeform AI feature, you agree as well, to comply with all of BrandFetch and Open AI terms and conditions, which can be found here and here respectively, including any restrictions on use contained therein.</p>


                <p><strong>4. Fees and payments</strong></p>
                <p>Payment for the Services shall be subject to the Payment Terms and Conditions, which are included in these STC by reference.</p>
                
                <p><strong>5. Term</strong></p>
                <p>The Services shall be accessible from the moment the subscription process is finalized, and shall be available to you unless you terminate your Account through “My account". Paid plans are subject to a particular term, as selected by you when signing up for said Services, and shall automatically renew (together with any add-ons you may have decided to purchase) on monthly or annual periods, depending on the term contracted, unless you, at any time, decide to cancel their renewal through the “My account” section. In any such cases, except if you decide to cancel your entire Account, the subscription you have paid for will run for the entire contracted term, and your Account will be downgraded to a free plan.</p>
                <p>As mentioned above, you may terminate your Account at any time, and we reserve the right to terminate your Account under the conditions set forth in section 6 below. These STC apply as long as you have an Account through our Site, regardless of the type of Account you held at each moment.</p>
                <p>This section shall not prevent the rights you have as a consumer in connection with the cancellation of, or withdrawal from, your Account. For further information please refer to section 7 below.</p>
                <p>The content will be made available to you and at your disposal at the end of the Term, but if you terminate your Account, everything will be erased (including the content) as indicated in section 6.b below. For any personal data collected through the Service, please check the terms of the Data Processing Agreement to learn how data will be processed upon termination of the Services.</p>

                <p><strong>6. Suspension and termination of the Account</strong></p>
                <p><b>a)Termination or suspension by the Company</b></p>
                <p>We are entitled to suspend your Account in the event that you provide us with untrue, inaccurate, not current, or incomplete information when creating your Account, as well as when you fail to comply with these STC or other mandatory provisions by law. We shall have the right to modify, prevent access to, delete, or refuse to host, display or make available those contents that are believed to violate the law or these STC, either by the way in which said contents are used as analyzed on their own or by the way they interoperate with other contents, entities or people. Except for those cases in which the contents at issue could automatically trigger Company’s liability under applicable law or when it is necessary to act diligently to prevent harm to others, we will notify you in advance about its intention to act against said contents and give you reasonable time to respond and take any necessary actions. Upon occurrence of any of these, we will contact you and request you to remedy your breach of these STC.</p>
                <p>We are entitled to terminate your Account in the event you fail to redress any STC breach in the non-extendable term of ten (10) calendar days from notification date. Additionally, your Account may be terminated in the event you substantially breach these STC, including without limitation any case in which the Account is used to commit fraud (e.g. to carry out phishing attacks) or willfully addressed to breach the law. Account termination may result in data loss.</p>
                <p>We are entitled to terminate your Account, without any further prior notice, in the event that you, as a free plan user, do not access your Account on any occasion for an uninterrupted period of 24 (twenty- four) months. The purpose of this termination is ensuring that only active users use the Site and, therefore, that the Site works in a fast and effective way. You can prevent this from happening by accessing at any given time to your Account, and the 24-month timeframe will restart.</p>
                <p>Finally, we may terminate your Account should you oppose the appointment of any sub-processor, as further detailed in our Privacy Policy.</p>
                <p>Please note that in all these cases you may lose all the information stored in your information stored in your Account, such as surveys and responses.</p>

                <p><b>b) Termination by you</b></p>
                <p>You may terminate your Account at any time by using the account termination option. If you terminate your Account you may still be able to access the Site, but you will not have access to the Services, features and content that are available to Account holders. Please note that this may result in data loss.</p>

                <p><b>7. Cancellation of your subscription - withdrawal right</b></p>
                <p>The Services are available upon completion of the signup process and creation of your Account (except for those functionalities subject to any of the subscription plans provided by us). By creating your Account and expressly requesting the Services, you waive to your withdrawal right, if applicable.</p>

                <p><b>8. Social media and third party platforms</b></p>
                <p>The Services may include functionality that allows you to access and post content to social media and third party platforms regarding your activities while using our Services. If you choose to use this functionality, we may:</p>
                <ul>
                <li>a) have access to certain information that you make available through the social media or third-party platforms at issue, provided that the data has been made available to us under the terms and conditions and privacy policies set forth by said third parties; or</li>
                <li>b) post status messages, notes, photos, videos and other materials to the applicable Social Media or third-party platform on your behalf.</li>
                </ul>

<p>Subject to all the applicable third parties’ terms and conditions and privacy policies, by connecting your Account with your account on a social media or third party platform, you grant us permission to access and use the information that you make available through the social media or third party platform at issue. To manage the information provided to us, please review the privacy settings applicable to your social media or third party platform accounts.</p>
<p>We are not liable for social media or third party platform contents, products, or services. We shall have no obligation to review their contents, services, or products. You shall review all terms of use, policies, and guidelines established from time to time in said social media or platforms, and you agree to be solely responsible and liable for any claims arising as a result of sharing or posting any content to or your activity in any social media or third party platforms.</p>

<div>
<p><strong>9. Representations and warranties. Disclaimer of warranties and damages</strong></p>
<p>In addition to any other representations and warranties included in these STC, we both warrant and represent that we have the full power and authority to enter into these STCs, and that any approvals, consents and permissions, if any, have been obtained. In addition, you further warrant and represent that you will (i) use the Services in accordance with the provisions of these STC as well as any reasonable instructions delivered by the Company—or by any of our authorized representatives— from time to time; (ii) any contents or data used in connection with the Services will be uploaded, processed or otherwise used and acquired having obtained any necessary approvals, authorizations or licenses, and complying with any applicable laws, rules, regulations, directives and governmental requirements in the field of privacy, intellectual property and/or image rights; and (iii) provide your reasonable cooperation in the event that we need any evidence to prove before competent authorities and/or courts about the satisfaction of the requirements or consents referred therein.</p>
<p>To the fullest extent permitted by applicable law, the Site and the Services are provided “as is”, “with all faults” and “as available” and the entire risk of use and performance remains with you. The Company disclaims any representations, warranties, or conditions, express, or implied, or statutory, including, without limitation, (i) the implied warranties of merchantability, fitness for a particular purpose, and non-infringement; and (ii) that the Services and the Site will be available or provided on an uninterrupted, error-free, timely, or secure basis, will be free be error-free or free from viruses, worms, or other harmful or malicious components. You may have additional rights under your local laws that these STC cannot supersede and, in any such cases, the Company’s liability is limited in accordance with and to the extent permissible under said local laws.</p>
</div>

<div>
<p><strong>10. Limitation of liability</strong></p>
<p>To the maximum extent permitted by applicable law, in no event will the Company be liable for any indirect, consequential, incidental, exemplary, punitive, or special damages, including without limitation any damages to or for loss of data, revenue, profits, goodwill, or other intangible losses arising from or relating to these STC, your Account, or the Services. To the maximum extent permitted under applicable law, this limitation will apply to all claims under all theories of law and equity.</p>
<p>Subject to sections 9 and 10 above, Company’s maximum, aggregate or cumulative liability to you, for direct damages or under these STC (including under sections 9 and 10 above or any other contractual obligations), tort (including negligence and statutory duty) or otherwise shall not exceed the total maximum amount equivalent to fees paid to the Company in the last twelve (12) months preceding the date in which the damage took place.</p>
<p>Limitation and/or exclusion of liability and warranties may be limited in certain jurisdictions. To the extent that the limitations and exclusions in sections 9, 10 and 11 cannot be enforced or are considered void or illegal, either in whole or in part, said sections shall be construed and enforced in the sense of limiting the scope, duration and/or extent of the liability and/or warranty provision at issue. Nothing in these STC shall be understood to limit or exclude your liability for the price owed in excess of any liability caps hereunder.</p>
</div>

<div>
<p><strong>11. Indemnity</strong></p>
<p>You shall defend, indemnify and hold harmless the Company from and against any and all losses, settlements, damages, liabilities, judgements, obligations, fines or sanctions, costs, and expenses (including reasonable attorney’s fees) (collectively “Losses”), arising out of any claim, proceeding, demand, suit or action (collectively “Actions”) brought by a third party related to (a) your use of the Site or the Services, and activities occurring under your Account; (b) any violation of these STC; or (c) your violation of any other party’s rights or applicable law.</p>
</div>

<div>
<p><strong>12. Amendments to the STC. Other notices.</strong></p>
<p>We may modify these STC from time to time. We will provide you with reasonable prior written notice of any substantial change, to be sent via email or in-product notifications. If you do not agree to any amendments to the STC, you shall (as your sole remedy) stop using the Site and the Services. By continuing to use the Services or the Site, you are providing your agreement to be bound by the updated terms of the STC.</p>
<p>We may notify you about any topic related to this contract via email or in-product notifications. It is your responsibility to check those regularly to be informed about any said changes.</p>
</div>

<div>
<p><strong>13. Modifications and updates of the Services</strong></p>
<p>Due to the constant updates and changes made by the Company to improve the Services and ensure that you can use them in a seamless way and that the Services can interoperate with third-party platforms, we may add, alter, or remove functionalities from a Service at any time. Moreover, the Company may implement any updates to the Services (including security updates) which will be applicable to some or all users collectively at any time. Any modifications implemented hereunder will be applied for free.</p>
</div>

<div>
<p><strong>14. Miscellanea</strong></p>
<p>The illegality, invalidity, nullity, or unenforceability of any of the sections of these STC will not affect the validity of its other provisions, which shall remain in full force and effect. Such sections are to be replaced or integrated into others that, in accordance with law, correspond to the objective of the substituted sections.</p>
<p>If, at any time, we fail to respond to a breach of these STC by you, that failure will not waive our right to act with respect to subsequent or similar breaches. A waiver will only be binding on the Company if it is in writing and signed by the Company. These STC constitute the entire agreement between you and the Company with respect to your Account and the Services. Both you and the Company, warrant to each other that, in entering these STC, neither the Company nor you have relied on or will have any right or remedy based upon any statement, representation, warranty, or assurance other than those expressly stated in these STC.</p>
<p>The rights and obligations set forth in these STC (or, otherwise, of the Account) cannot be assigned to any third party without the prior written consent of the Company. Moreover, any decisions taken by the Company are final and binding.</p>
<p>You grant to the Company a perpetual, worldwide, royalty-free, transferable, sublicensable, non-exclusive, irrevocable license to use, reproduce, modify or transform, distribute, communicate to the public, and otherwise exploit any suggestions, ideas, enhancement requests, feedback, recommendations or other information or ideas provided by you or any third party on your behalf relating to the Services, Site, platform, deliverables, images or the software.</p>
<p>If you are a company, we may use your trade name and trademarks on our Site and any other promotional materials produced by us from time to time. To this extent, you grant to us a non-exclusive, non-sublicensable, royalty-free, worldwide license to use said intellectual property, it being understood, however, that we shall use said intellectual property in accordance with the industry standards.</p>
<p>The Services have a legal warranty period from its delivery. If an incident occurs in the contracted Services, you may communicate it to the Company by any of the means of support indicated in the following clause, and we will make a diagnosis of the incident, proceeding to its resolution.</p>
<p>These STC are drafted both in plain and legal jargon versions. In case of any discrepancies, the legal version included herein shall prevail and take precedence with respect to the plain version. In the event of any conflict between the provisions of these STC and the Terms of Use, the Privacy Policy, the Data Protection Agreement or any other terms included by reference into these STCs, the terms of the STCs shall take precedence.</p>
</div>

<div>
<p><strong>15. Support, Contact and Complaints</strong></p>
<p>You can contact us in case you have any doubts, comments or concerns by any of the following means:</p>
</div>

<div className='byPostSrvc'>
<p><b>By post:</b></p>
<p>Support Service</p>
<p>Dynamic Reviews</p>
<p>20289 County Rd 181, Flint, TX 75762 (United States)</p>
<p>By our contact email or form:</p>
<p><a href='https://www.dynamic.reviews/contact' target="_blank" rel="noreferrer">https://www.dynamic.reviews/contact</a></p>
<p><a href='www.dynamic.reviews' target="_blank">www.dynamic.reviews</a></p>
</div>

<div>
<p><strong>16. Applicable law and jurisdiction</strong></p>
<p><b>a. General</b></p>
<p>These STC shall be governed and construed in accordance with US law, without reference to its conflict of law provisions. The United Nations Convention on Contracts for the International Sale of Goods will not apply. Any dispute that may arise from or in connection to us and/or the Site or the Services shall be subject to the jurisdiction of the courts in Smith County, Texas, United States.</p>
<p><b>b. Consumers</b></p>
<p>If you are acting as a consumer, these STC shall be governed and construed in accordance with US law, but this shall not prevent the application of those mandatory rights you are entitled to under your applicable law. The courts in Smith County, Texas, United States, shall have no exclusive jurisdiction in connection with any claim brought by you against the Company.</p>
<p>You may also access to the European Union’s online dispute resolution webpage.</p>
</div>


                </div>
              
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseTerms}>Close</Button>
            </Modal.Footer>
          </Modal>


      </div>
    </>
  )
}
