import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {useDispatch} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel, AuthModelSUPER} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
// import {useNavigate} from 'react-router-dom'
import io from 'socket.io-client'
import {GetSocketUrl} from '../../../../_metronic/helpers'
import OfferApi from './OfferApi'
import { useNavigate } from 'react-router-dom'

const SOCKET_URL = GetSocketUrl()
// const socket = io(SOCKET_URL)

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  saveSuperAuth: (auth: AuthModelSUPER | undefined) => void
  SuperLoglogout: () => void
  superauth: AuthModelSUPER | undefined
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  saveSuperAuth: () => {},
  logout: () => {},
  SuperLoglogout: () => {},
  superauth: authHelper.getSuperAuth(),
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [superauth, setsuperAuth] = useState<AuthModelSUPER | undefined>(authHelper.getSuperAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const saveSuperAuth = (superauth: AuthModelSUPER | undefined) => {
    setsuperAuth(superauth)
    if (superauth) {
      authHelper.setSuperAuth(superauth)
    } else {
      authHelper.removeSuperAuth()
    }
  }

  const logout = () => {
    // socket.emit('user-offline', currentUser?.user_id)
    saveAuth(undefined)
    saveSuperAuth(undefined)
    setCurrentUser(undefined)

    localStorage.removeItem('tmpuser-ls')
  }

  const SuperLoglogout = () => {
    saveAuth(undefined)
    saveSuperAuth(undefined)
    setCurrentUser(undefined)
    // socket.emit('user-offline', currentUser?.user_id)
    localStorage.removeItem('tmpuser-ls')
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        saveSuperAuth,
        SuperLoglogout,
        superauth,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {auth, superauth, logout, setCurrentUser, saveAuth} = useAuth()

  // const navigate = useNavigate()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  // const fetchData = () => {
  //   dispatch(getCategory()); // Dispatch the async action
  // };
  useEffect(() => {
    // fetchData()
    //  OfferApi()

    const requestUser = async (apiToken: string, loggedinfromSA: boolean) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            data.loggedinfromSA = loggedinfromSA
            setCurrentUser(data)
            // navigate('/dashboard')
            // socket.emit('user-online', data?.user_id)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    var newSession = localStorage.getItem('tmpuser')
    var newSession1 = localStorage.getItem('tmpuser-ls')
    if (newSession1 !== null && newSession1 !== 'undefined') {
      var newAuth1 = JSON.parse(newSession1)
      if (newSession !== null && newSession !== 'undefined') {
        var newAuth = JSON.parse(newSession)
      } else if (auth?.login !== 'direct') {
        localStorage.setItem('tmpuser', newSession1)
        newSession = localStorage.getItem('tmpuser')
        newAuth = newSession && JSON.parse(newSession)
      }
      if (newAuth?.data?.user_id !== newAuth1?.data?.user_id && auth?.login !== 'direct') {
        localStorage.setItem('tmpuser', newAuth1)
      }
    }
    if (newSession !== null) {
      var newAuth = JSON.parse(newSession)
      saveAuth(newAuth)
      requestUser(newAuth.api_token, newAuth?.data?.loggedinfromSA)
      localStorage.removeItem('tmpuser')
    } else if (auth && auth.api_token && auth.login === 'viasuperadmin') {
      requestUser(auth.api_token, auth?.data?.loggedinfromSA)
    } else if (superauth && superauth.api_token) {
      requestUser(superauth.api_token, false)
    } else if (auth && auth.api_token) {
      requestUser(auth.api_token, false)
    } else {
      logout()
      setShowSplashScreen(false)
    }

    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
