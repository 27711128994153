import React, {useEffect, useState} from 'react'
import {getsector, organisation, sectorApi} from './core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../auth'
import { verify_otp } from '../auth/core/_requests'

export const Organization = () => {
  const navigate = useNavigate()
  const {auth,saveAuth,saveSuperAuth, currentUser, setCurrentUser} = useAuth()

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<any>('')
  const [company_name, setCompany] = useState<any>('')
  const [Address, setAddress] = useState<any>('')
  const [Website, setWebsite] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [sectors, setSectors] = useState<any>([])
  const token: any = auth?.api_token

  const getsectors = async () => {
    const { data } = await getsector(token)
    setSectors(data)
  }
  useEffect(() => {
    getsectors()
  }, [])
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const emailParam = urlParams.get('email')
    const emailValue = emailParam ? decodeURIComponent(emailParam) : ''

     if (emailParam) setTonken(emailParam)
  }, [])


  const setTonken = async (otp: any) => {
    try {
      setLoading(true)
      const data = {
        token: otp,
        auth: auth?.api_token,
      }

      const {data: auths} = await verify_otp(data)

      setLoading(false)
      if (auths?.code === 200) {
        if(auths?.data?.is_superadmin){
          auths['login'] = 'supeadmin'
          auths['window'] = 2
          saveSuperAuth(auths)
          auths.loggedinCompany = false;
          setCurrentUser(auths.data)
        }
        else{
          auths['login'] = 'direct'
          auths['window'] = 1
          saveAuth(auths)
          setCurrentUser(auths?.data)
        }
        saveAuth(auths)
        setCurrentUser(auths.data)

        // navigate('/organization', {
        //   state: `Congratulations! Your email address (${auths.data?.email}) has been successfully verified. You are now one step away from accessing your account. Please provide your organization details to complete the registration process..`,
        // })
      } else if (auths?.code == 202) {
        setMessage(auths?.message)
      } else if (auths?.code == 201) {
        setMessage(auths?.message)
      } else {
        setMessage(auths?.message)
      }
    } catch (e) {
      setLoading(false)

    }
  }
  const sector_id:any=Array.isArray(sectors) && sectors?.filter((sector:any) => sector?.sector_name==='Review')
  const companInfo = async (e: any) => {
    e.preventDefault()
    if (company_name.length <= 2) {
      setErrorMessage('Organization name must be longer than 2 characters.')
      return
    }

    try {

      setLoading(true)
      const {data: res} = await organisation({company_name, Address, Website, token})
      if (res.code === 200) {
      
        const company_id = res.data.company_id
        if(company_id){
      const sector = sector_id[0]?.sector_id
      console.log("sector_id[0]?.sector_id",sector )

          const { data: auths } = await sectorApi({ token, company_id,sector })
        if (auths.code === 201) {
        //  setCurrentUser(res.data)
          saveAuth(res)
          setLoading(false)
          setCurrentUser({ ...auths.data, invitePages: true })
          navigate('/invitePages')
         
        }
        }
      //  navigate('/sector')
      }
    } catch (e) {
      setLoading(false)

    }
  }
  const locationdata = async () => {
    let currentUsers: any = currentUser
    if (
      currentUsers?.is_phone_verified == 1 ||
      (currentUsers?.is_phone_verified == 1 && currentUsers?.is_email_verified == 1)
    ) {
      setMessage(
        'Congratulations! Your phone number has been successfully verified. You are now one step away from accessing your account. Please provide your organization details to complete the registration process.'
      )
    } else if (currentUsers?.is_email_verified == 1)
      setMessage(
        `Congratulations! Your email address (${currentUsers.email}) has been successfully verified. You are now one step away from accessing your account. Please provide your organization details to complete the registration process.`
      )
  }
  useEffect(() => {
    locationdata()
  }, [])
  return (
    <>
      <div className='d-flex flex-center flex-column flex-lg-row-fluid addOrgNameUpr'>
        <div className='w-lg-500px custmPhRegUsr addOrgName'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework  w-lg-500px bg-body rounded shadow-sm p-10  mx-auto'
            noValidate
            onSubmit={companInfo}
          >
            <div className='fv-row mb-10'>
           
              <div className='mb-10'>
              <div
                  className={`alert  alert-primary  alert-dismissible text-center`}
                  role='alert'
                >
                  Welcome to Dynamic Reviews!
                </div>
              </div>
              <label className='form-label fw-bolder text-gray-900 fs-6'>What is the name of your business?</label>
              <input
                type='text'
                placeholder='Organisation Name'
                autoComplete='off'
                className='form-control '
                onChange={(e) => setCompany(e.target.value)}
              />
              {errorMessage && <p>{errorMessage}</p>}
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
                disabled={loading}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>

            {/* end::Form group */}
            <div></div>
          </form>
        </div>
      </div>
    </>
  )
}
