//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import {ActionTypes} from '../constants'

const _ = require('lodash')

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const FormsValues = []

const comparator = (a, b) => a.id === b.id;


export const FormsValuesReduser = (state = FormsValues, action) => {
  switch (action.type) {
    case 'emptyData':
      return action.payload
    case ActionTypes.formvalues.setformvalues:
      return [action.payload]
    case ActionTypes.formvalues.updateformvalues:

    default:
      return state
  }
}

const formData = []
export const FormsDataReduser = (state = formData, action) => {
  switch (action.type) {
    case 'formDataAddval':
      return action.payload
    //  case ActionTypes.formvalues.setformvalues:
    //    return [action.payload]
    //  case ActionTypes.formvalues.updateformvalues:

    default:
      return state
  }
}

const AllForm = []
export const AllFormReduser = (state = AllForm, action) => {
  switch (action.type) {
    case 'getformDataAddval':
      return action.payload
    case 'deleteformDataAddval':
      return _.differenceWith(state, action.payload, comparator)

    //  case ActionTypes.formvalues.setformvalues:
    //    return [action.payload]
    //  case ActionTypes.formvalues.updateformvalues:

    default:
      return state
  }
}


const editValues = []
export const editFunction = (state = editValues, action) => {
  switch (action.type) {
    case 'editFunction':
      return action.payload

    default:
      return state
  }
}
const initialState = { }; // Initial state

export const thresholdPoint = (state = initialState, action) => {
  switch (action.type) {
    case 'threshold':
      return  action.payload ;
    default:
      return state;
  }
};
