import { Link, useLocation, useNavigate } from 'react-router-dom'

import { KTSVG } from '../../../../../_metronic/helpers'
import axios from "axios"
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from '../../../auth'
import DeletePopup from '../../marchant/components/Deletepopup'
import Modal, { AddFormName, QrFormName } from '../Modal'
import { getBrandingData } from '../store/Branding/thunks'
import { useGetTemplate } from '../store/Teamplate/selectors'
import { getAllTeamplate } from '../store/Teamplate/thunks'
import { useEditvalues, useFormsfullData, useGetFormsData } from '../store/forms/selectors'
import {
  addGoogleForm,
  calledit,
  deleteFromValues,

  getFormValuesData,
  setFormValues,
  setFormValuesData,
  setFormValuesemptyData,
} from '../store/forms/thunks'
import '../style.css'
import { Tooltip } from 'antd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { login } from '../../../auth/core/_requests'


const Submenu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {currentUser, superauth, auth} = useAuth()
  const [tags, setTags] = useState(["Customer Service", "Clean", "Great Attitude", "Friendly"]);
  const [account, setAccount] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isOpnenModal, setIsOpenModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [formName, setFormName] = useState('')
  const [isAlgen, setIsAlgen] = useState(true)
  const [id, setId] = useState(0)
  const [deleteModal, setDeletModal] = useState(false)
  const [loading, setloading] = useState(false)
  const [isOpenQr, setIsOpenQr] = useState(false)
  const [hideTemp, setHidetemp] = useState(false)
  const [qrCodeId, setQrCodeId] = useState(false)
  const [feedback, setfeedback] = useState('8')
  const [selectBranding, setSelectBranding] = useState('')
  const [CompanyUri, setCompanyUri] = useState('')
  const [editvalue, setEditValue] = useState('')
  const [SelectTemplate,setSelectTemplate]=useState('')
const [editTrue,setEditTrue] = useState(false)
  const inputValues = useFormsfullData()
  const formNameData = useGetFormsData()
  const editvalues = useEditvalues()
  const template=useGetTemplate()
  const addInputBox = (val) => {
    dispatch(setFormValuesData([...inputValues, {id: inputValues.length, name: val}]))
  }

  const closeModal = (val) => {
    setIsModalOpen(false)
    if (val != null) {
      addInputBox(val)
      dispatch(setFormValues({id: inputValues.length, name: val}))
    }
  }
  const openModal = () => {
    setIsModalOpen(true)
  }

  const deletefield = (id) => {
    dispatch(setFormValuesData(inputValues.filter((item) => item.id !== id)))
   
    // setInputValues(inputValues.filter((item) => item.id !== id))
  }

  const setValues = (val) => {
    // navigate('/apps/form/list')
    dispatch(setFormValues(val))
  }
  const location = useLocation();
  useEffect(() => {
    dispatch(getBrandingData())
    dispatch(getFormValuesData())
    dispatch(getAllTeamplate())
    const searchParams = new URLSearchParams(location.search);
    const formId = searchParams.get('form_id');
    const teamId = formId?`/?form_id=${formId}`:''
    navigate(`/apps/reviewform/list${teamId}`)
  }, [])

  function capitalizeFirstLetter(str) {
    if (str.length === 0) {
      return str
    }

    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  const onDragEnd = (result) => {
     
    if (!result.destination) {
      return; 
    }

 
    const reorderedFields = Array.from(inputValues);
    const [movedField] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, movedField);
    dispatch(setFormValuesData(reorderedFields))

  };
  const handleSaveForm = async () => {
    //authorizationtoken: token1?.api_token,
    try {
      const config = {
        headers: {'Content-Type': 'application/json'},
      }
    


     



      setloading(true)

      let values = {
        formName,
        isAlgen,
        fields: inputValues,
        selectBranding,
        CompanyUri,
        feedback,
        tags
      }
      let resData
      if (id) {
        let values = {
          id: id,
          formName,
          isAlgen,
          fields: inputValues,
          CompanyUri,
          selectBranding,
          feedback,
          tags
        }
        resData = await addGoogleForm(values)
        setTags([])
      } else {
         resData = await addGoogleForm(values)
         setTags([])

        
      }
      

      setFormName('')
      setSelectBranding('')
      setfeedback(8)
      setEditValue('')

      dispatch(setFormValues(null))

      dispatch(setFormValuesData([]))

      dispatch(getFormValuesData())
      const item =resData?.data?.data
if (currentUser.is_superadmin==1) {
  navigate('/apps/reviewform/list')

}else{
      if (item?.payment_status===false || item?.payment_status===null) {
        navigate('/apps/subscription/list', {state: {item}})
       }else{
        navigate('/apps/reviewform/list')
       }
}
      setloading(false)
      setId(0)

      setShowForm(false)
    } catch (e) {
      console.log('error', e)
    }
  }
  const deleteForm = async () => {
    dispatch(deleteFromValues(id))
    setDeletModal(false)
  }
  const duplicateField = (value) => {
    dispatch(setFormValuesData([...inputValues, {id: inputValues?.length, name: value.name, label: value.label}]))
  }
  const setEditForm = (edit) => {
    navigate('apps/form/list')
    setFormName(edit?.form_name)
    setTags(edit?.tags||[])
   setIsAlgen(edit?.is_algen===null?'false':edit?.is_algen)
    setCompanyUri(edit?.companyuri)
    setSelectBranding(edit?.branding_id || '')
    setfeedback(edit?.lowfeedbackpoint)
    dispatch({ type: 'threshold', payload: edit?.lowfeedbackpoint });

    dispatch(setFormValuesData(edit?.fields))
    setValues(edit?.fields[0])
    setId(edit.id)
    setShowForm(true)
  }
  const API_URL = process.env.REACT_APP_API_URL

  const fetchLocationId = async () => {
    const data = {
        company_id: currentUser?.company_id,
        user_id: currentUser?.user_id,
    }
    try {
        const response = await axios.post(`${API_URL}/Company/getLocationByUserId`, { data });
        response?.data?.data.sort((a, b) => {
            // Convert titles to lowercase for case-insensitive comparison
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();

            // Compare the titles
            if (titleA > titleB) {
                return 1; // b comes before a
            }
            if (titleA < titleB) {
                return -1; // a comes before b
            }
            return 0; // titles are equal
        });
        setAccount(response?.data?.data)
        

    } catch (err) {
        console.log("error", err)
    }
}
useEffect(() => {
  fetchLocationId()
}, [currentUser?.user_id])

  useEffect(() => {
    if (editvalues?.status == 'true') {
      navigate('apps/form/list')
      setEditForm(editvalues?.data)
      setEditValue(editvalues?.data)
      let value = {
        status: 'false',
        data: [],
      }
      dispatch(calledit(value))
    }
    fetchLocationId()
    if (editvalues?.status == 'open') {
     // navigate('apps/form/list')
      setIsOpenModal(true)
      setSelectTemplate(currentUser.is_admin===true && defalutData[0]?.id)
      // setfeedback(currentUser.is_admin===true && )
      setfeedback(currentUser.is_superadmin===1 ? 8:defalutData[0]?.lowfeedbackpoint)
      setId(0)
      dispatch(setFormValuesData([]))
      let value = {
        status: 'false',
        data: [],
      }
      dispatch(calledit(value))
    }
  }, [editvalues])

const defalutData = template?.filter((item) => item?.defaulttem===true)
  const duplicate=(data)=>{
    if(data?.fields){setValues(data?.fields[0])}
    if(data?.fields?.length===0 || data?.fields==undefined){
      openModal()
    }
    dispatch(setFormValuesData(data?.fields))
    
  }
const [val,setVal]=useState('')
  return (
    <div className='m-0'>
      <div>
        <DeletePopup
          show={deleteModal}
          handleClose={() => {
            setId(-1)
            setDeletModal(false)
          }}
          handleDelete={() => {
            deleteForm()
          }}
          // loader={loader}
        />
        <Modal isOpen={isModalOpen} closeModal={(val) => closeModal(val)} />
        <AddFormName
          isOpen={isOpnenModal}
          tags={tags}
          account={account}
          setTags={setTags}
          ishide={hideTemp}
          setHideTemp={setHidetemp}
          values={formName}
          feedback={feedback}
          setFeedback={setfeedback}
          setSelectBranding={setSelectBranding}
          selectBranding={selectBranding}
          CompanyUri={CompanyUri}
          setCompanyUri={setCompanyUri}
          setFormName={setFormName}
          setIsAlgen={setIsAlgen}
          editTrue={editTrue}
          formName={formName}
          isAlgen={isAlgen}
          templateName={template}
          setselectTemplate={setSelectTemplate}
          selectTemplate={SelectTemplate}
          duplicate={duplicate}
          currentUser={currentUser}
          closeModal={(val) => {
            setSelectTemplate('')
            if (val == null) {
              setIsOpenModal(false)
              if (showForm) {
                setFormName(editvalue?.form_name)
                setIsAlgen(editvalue?.is_algen)
                setSelectBranding(editvalue?.branding_id)
                setfeedback(editvalue?.lowfeedbackpoint)
                setTags(editvalue?.tags)
              }
            }else if(val=='editval'){
            setIsOpenModal(false)
            setEditTrue(false)

            }
            else {
              navigate('/apps/form/list')
              setShowForm(true)
              setIsOpenModal(false)

              // setfeedback(val?.feedback)
              // setSelectBranding(val?.selectBranding)
              // setFormName(val?.name)
            }
          }}
        />
        <QrFormName
          isOpen={isOpenQr}
          values={formName}
          Idvalue={qrCodeId}
          closeModal={(val) => {
            if (val == null) {
              setIsOpenQr(false)
            } else {
              // setShowForm(true)
              setIsOpenQr(false)
              // setFormName(val)
            }
          }}
        />

        {!showForm && (
          <>
            {' '}
            <div></div>
            <div className='d-flex mb-10'>
              <div className='flex-shrink-0 ms-2'>
                <buttion
                  // to='apps/leadtable/user'
                  className='btn btn-primary btn-active-icon-primary'
                  // data-kt-menu-trigger='click'
                  // data-kt-menu-placement='bottom-end'
                  onClick={() => {
                    setIsOpenModal(true)
                    setFormName('')
                    setIsAlgen(true)
                    setSelectBranding('')
                    setSelectTemplate(currentUser.is_admin===true && defalutData[0]?.id)
                    setfeedback(currentUser.is_admin===true? defalutData[0]?.lowfeedbackpoint:'8')
                   // setfeedback(0)
                    //setselectTemplate()
                    setId(0)
                    dispatch(setFormValuesData([]))
                    dispatch(setFormValuesemptyData([]))
                  }}
                >
                  Add Form
                </buttion>
                &nbsp; &nbsp;
                <Link
                  to='/apps/reviewform/list'
                  // type='button'
                  className='btn btn-primary btn-active-icon-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                >
                  View Form
                </Link>
              </div>
            </div>
          </>
        )}
        {showForm && (
          <div className='listfield'>
            <div>
              <h1 className='align-item-start'>Content</h1>
            </div>
            <Tooltip color={'#04c8c8'} title={'Click For Add more Fields'}>
            <div>
              {/* <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                onClick={() => openModal()}
              >


                <i className='fas fa-plus'></i>
              </button> */}
            </div>  </Tooltip>
          </div>
        )}
      </div>
      <div className='m-0'>
        {showForm && (
          <>
            <div className='col-12 fixinput d-flex justify-content-between'>
              <input
                className='form-control fw-bold text-gray-800   '
                onChange={(e) => {
                  setFormName(e.target.value)
                }}
                style={{border: '0'}}
                value={formName}
              ></input>
              <button
                className='buttoncross subEditIcon'
                // className='buttoncross'
                type='button'
                onClick={() => {
                  setIsOpenModal(true)
                  setEditTrue(true)
                  setHidetemp(true)
                }}
              >
                {/* <i class='bi bi-pen'></i> */}
                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
              </button>
            </div>
          </>
        )}
        {showForm &&
                <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="formFields">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {inputValues.map((field, index) => (
                        <Draggable key={field.id} draggableId={`field-${field.id}`} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                               <div key={field.id} className='custom-list align-items-center mt-2   hovdev'>
                        <div className='listfield' onClick={() => setValues(field)}>
                          <div className='custom-list d-flex align-items-center py-2'>
                            {field.name === 'shortText' && (
                              <>
                                <div className=' btn btn-icon btn-bg-light btn-active-color-primary btn-sm justify-content-between p-1 min-w-50px'>
                                  <i className='bi bi-file-text'></i>
                                  <span className=' fw-bold text-gray-800 '>{index + 1}</span>
                                </div>
                              </>
                            )}
                            {field.name === 'longText' && (
                              <>
                                <div className=' btn btn-icon btn-bg-light btn-active-color-primary btn-sm justify-content-between p-1 min-w-50px'>
                                  <i className='bi bi-textarea'></i>
                                  <span className=' fw-bold text-gray-800 '>{index + 1}</span>{' '}
                                </div>
                              </>
                              // <i className='bi bi-textarea btn btn-icon btn-bg-light btn-active-color-primary btn-sm'></i>
                            )}
                            {field.name === 'dropdown' && (
                              <>
                                <div className=' btn btn-icon btn-bg-light btn-active-color-primary btn-sm justify-content-between p-1 min-w-50px'>
                                  <i className='bi bi-caret-down '></i>
                                  <span className=' fw-bold text-gray-800 '>{index + 1}</span>{' '}
                                </div>
                              </>
                            )}
                            {field.name === 'multichoice' && (
                              <>
                                <div className=' btn btn-icon btn-bg-light btn-active-color-primary btn-sm justify-content-between p-1 min-w-50px'>
                                  <i className='bi bi-check-square '></i>
                                  <span className=' fw-bold text-gray-800 '>{index + 1}</span>{' '}
                                </div>
                              </>
                              // <i className='bi bi-check-square btn btn-icon btn-bg-light btn-active-color-primary btn-sm'></i>
                            )}
                            {field.name === 'radio' && (
                              <>
                                <div className=' btn btn-icon btn-bg-light btn-active-color-primary btn-sm justify-content-between p-1 min-w-50px'>
                                  <i className='bi bi-ui-radios '></i>
                                  <span className=' fw-bold text-gray-800 '>{index + 1}</span>{' '}
                                </div>
                              </>
                              // <i className='bi bi-radio btn btn-icon btn-bg-light btn-active-color-primary btn-sm'></i>
                            )}
      
                            <span className='custom-list-title fw-bold text-gray-800 marleft'>
                              {field?.label}
                            </span>
                          </div>
      
                          <div className='disable'>
                            <span className='justify-content-end'>
                              {/* eslint-disable jsx-a11y/anchor-is-valid */}
      
                              <a
                                href='#'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                              >
                                <i className='bi bi-three-dots-vertical'></i>
                              </a>
                              <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                data-kt-menu='true'
                              >
                                {/* begin::Menu item */}
                                <div className='menu-item px-3'>
                                  {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                  <a
                                    href='#'
                                    className='menu-link px-3'
                                    onClick={() => {
                                      duplicateField(field)
                                    }}
                                    // {i? 'data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer"': ''}
                                    // data-bs-toggle='modal'
                                    // data-bs-target='#kt_modal_add_customer'
                                    //  onClick={() => onEditBtn(item)}
                                  >
                                    Duplicate
                                  </a>
                                  {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                </div>
      
                                {/* end::Menu item */}
      
                                {/* begin::Menu item */}
                                <div className='menu-item px-3'>
                                  {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                  <a
                                    className='menu-link px-3'
                                    data-kt-users-table-filter='delete_row'
                                    // onClick={() => onDeleteBtn(item)
                                    onClick={() => deletefield(field.id)}
                                    // }
                                  >
                                    Delete
                                  </a>
                                  {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                        {/* <span className='ml-1'> {capitalizeFirstLetter(field.name)}</span> */}
      
                        {/* <button
                    className="btn btn-danger"
                    onClick={() => removeField(field.id)}
                  >
                    Remove
                  </button> */}
                      </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
          }
        {/* <div className='d-flex justify-content-end m-5'> */}
        {showForm &&  (
            <>
             <div className='listfield mt-3'>
            <div>
              <h1 className='align-item-start'></h1>
            </div>
            <Tooltip color={'#04c8c8'} title={'Click For Add more Fields'}>
            <div>
              <button
                className='btn btn-icon btn-light-primary btn-active-color-primary-inverse btn-sm '
                onClick={() => openModal()}
              >


                <i className='fas fa-plus'></i>
              </button>
            </div>  </Tooltip>
          </div></>)}
        <div
         // className={`d-flex ${id ? 'justify-content-between' : 'justify-content-end'} mx-3 mt-5`}
         className={`d-flex justify-content-between mt-5`}
        >
          {/* {showForm && inputValues.length > 0 && ( */}
          {showForm &&  (
            <>
              {/* {id !== 0 && ( */}
                <button
                  type='button'
                  className='btn btn-primary '
                  onClick={() => {
                    setShowForm(false)
                    navigate('/apps/reviewform/list')
                  }}
                >
                  Discard
                </button>
              {/* )} */}
              <button
                type='button'
                className='btn btn-primary marleft fixbtnload'
                onClick={() => {
                  handleSaveForm()
                }}
              >
                {!loading && (id ? 'Update' : 'Publish Form')}
                {/* <span className='indicator-label'>Continue</span> */}
                {loading && (
                  <span className='indicator-progress fixloadersite'>
                    Please wait...
                    <span className='spinner-border foxload spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </>
          )}
        </div>

        {!showForm &&
          Array.isArray(formNameData) &&
          formNameData.map((item) => (
            <div className='d-flex justify-content-between align-items-center mb-3 '>
              <div
                onClick={() => {
                  navigate('/apps/form/lists', {state: {item}})
                }}
              >
                <div className='d-flex btn flex-column flex-grow-1'>
                  <h5 onClick={()=>setVal(item?.form_name,item.id)} className={val===item?.form_name?'text-primary active':'text-dark fw-bold'}>
                    {item?.form_name}
                  </h5>
                </div>
              </div>

              <div className='disable'>
                <span className='justify-content-end'>
                  {/* eslint-disable jsx-a11y/anchor-is-valid */}

                  <a href='#' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
                  <Tooltip color={`#04c8c8`} title='Click for Action'>
                    <i className='bi bi-three-dots-vertical'></i>
                    </Tooltip>
                  </a>
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                    data-kt-menu='true'
                  >
                    <div className='menu-item px-3'>
                      {/* eslint-disable jsx-a11y/anchor-is-valid */}
                      <a
                        href='#'
                        className='menu-link px-3'
                        onClick={() => {
                          setEditForm(item)
                          setEditValue(item)
                        }}
                      >
                        Edit
                      </a>
                    </div>

                    {/* <div className='menu-item px-3'> */}
                      {/* eslint-disable jsx-a11y/anchor-is-valid */}
                      {/* <a
                        className='menu-link px-3'
                        data-kt-users-table-filter='delete_row'
                        onClick={() => {
                          setDeletModal(true)
                          setId(item.id)
                          // deleteForm(item.id)
                        }}
                        // }
                      >
                        Delete
                      </a> */}
                    {/* </div> */}
                    {( currentUser.is_superadmin!==1) &&
                    <div className='menu-item px-3'>
                      {/* eslint-disable jsx-a11y/anchor-is-valid */}
                      <a
                        className='menu-link px-3'
                        data-kt-users-table-filter='delete_row'
                        onClick={() => {
                          // setDeletModal(true)
                          if (item.payment_status === true) {
                            setIsOpenQr(true)
                            setQrCodeId(item.id)
                          }else{

                            navigate('/apps/subscription/list', {state: {item}})
                          }
                         
                          // deleteForm(item.id)
                        }}
                        
                        // }
                      >
                        {item.payment_status === true?'Qr code':
                         <Tooltip color={`#04c8c8`} title='This form payment is pending click on QR and make payment'>
                         Qr code
                    </Tooltip>}
                      </a>
                    </div>
                    }
                  </div>
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
    
  )
}

export default Submenu
