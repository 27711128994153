import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');
 
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class StatisticsWidget4 extends Component {
	render() {
		const options = {
			animationEnabled: true,
			// title:{
			// 	text: "Monthly Sales - 2017"
			// },
			axisX: {
				valueFormatString: "MMM"
			},
			// axisY: {
			// 	title: "Sales (in USD)",
			// 	prefix: "$"
			// },
			data: [{
				yValueFormatString: "$#,###",
				xValueFormatString: "MMMM",
				type: "spline",
				dataPoints: [
					{ x: new Date(2017, 0), y: 15 },
					{ x: new Date(2017, 1), y: 11 },
					{ x: new Date(2017, 2), y: 25 },
					{ x: new Date(2017, 3), y: 35 },
					{ x: new Date(2017, 4), y: 55 },
					{ x: new Date(2017, 5), y: 45 },
					{ x: new Date(2017, 6), y: 60 },
					{ x: new Date(2017, 7), y: 70 },
					{ x: new Date(2017, 8), y:80 },
					{ x: new Date(2017, 9), y: 85 },
					{ x: new Date(2017, 10), y: 70 },
					{ x: new Date(2017, 11), y: 95 }
				]
			}]
		}
		return (
			<div className={`card `}>
<div className='card-body'>
		<div>
			<CanvasJSChart options = {options}
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		</div></div>
		);
	}
}
export default StatisticsWidget4;  