import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import ReactGA from "react-ga4";

const App = () => {
  const TRACKING_ID='G-NV6CE18P4G'
  ReactGA.initialize(TRACKING_ID)
  useEffect(() =>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname });
  },[])
  // this useEffect hook is used for shop inspacts from user interaction sides
  // useEffect(() => {
  //   const preventDefault = (e:any) => {
  //     e.preventDefault();
  //   };

  //   // Disable right-click context menu
  //   window.addEventListener('contextmenu', preventDefault);

  //   // Disable keyboard shortcuts
  //   const handleKeyDown = (e:any) => {
  //     if (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i')) {
  //       e.preventDefault();
  //     }
  //   };
  //   window.addEventListener('keydown', handleKeyDown);

  //   // Cleanup on unmount
  //   return () => {
  //     window.removeEventListener('contextmenu', preventDefault);
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);
// end stop inspacts useEffect hook

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
