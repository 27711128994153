import {applyMiddleware, combineReducers, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import {BranchReducer} from './Branch/reducers'
import {productCategoryReducer} from './ProductCategory/reducers'
import {categoryReducer} from './category/reducers'
import {couponReducer} from './coupon/reducers'
import {productReducer} from './products/reducers'
import {sellerReducer} from './seller/reducers'
import {CustomForm} from './Form/reducers'
import {couponAdminReducer} from './PublishCoupon/reducers'
import {
  AllFormReduser,
  FormsDataReduser,
  FormsValuesReduser,
  editFunction,
   thresholdPoint,

} from '../../Forms/store/forms/reducers'
import {feedbackValuesReducer} from '../../Forms/store/feedback/reducers'
import {BrandingValuesReducer} from '../../Forms/store/Branding/reducers'
import {reportValuesReducer} from '../../Forms/store/Report/reducers'
import { reducerTemplate } from '../../Forms/store/Teamplate/reducers'
import { FormReducer } from '../../../GoogleReviewFrom/store/FormData/reducers'

const reducer = combineReducers({
  category: categoryReducer,
  seller: sellerReducer,
  coupons: couponReducer,

  product: productReducer,
  PCategory: productCategoryReducer,
  Branch: BranchReducer,
  CoustomForm: CustomForm,
  couponAdmin: couponAdminReducer,
  formvalues: FormsValuesReduser,
  formData: FormsDataReduser,
  AllForm: AllFormReduser,
  feedBack: feedbackValuesReducer,
  branding: BrandingValuesReducer,
  Report: reportValuesReducer,
  editval: editFunction,
   Template:reducerTemplate,
   formDatas:FormReducer,
   Point:thresholdPoint


})

let initialState = {
  //   cart: {
  //     cartItems: localStorage.getItem("cartItems")
  //       ? JSON.parse(localStorage.getItem("cartItems"))
  //       : [],
  //     shippingInfo: localStorage.getItem("shippingInfo")
  //       ? JSON.parse(localStorage.getItem("shippingInfo"))
  //       : {},
  //   },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
