/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect } from 'react'
import { useAuth } from '../../../../app/modules/auth';
import axios from 'axios'
import { usePageData } from '../../../../_metronic/layout/core/PageData'
import {
  KTSVG, toAbsoluteUrl,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
  GetSocketUrl
} from '../../../helpers'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import io from 'socket.io-client';
const SOCKET_URL = GetSocketUrl();
// const socket = io(SOCKET_URL);
const API_URL = process.env.REACT_APP_API_URL
type Props = {
  isDrawer?: boolean
}
interface FormData {
  user_id: string
  avatar: string
  user_name: string
  phone: string
  whatsapp: string
  languages: string
  email: string
  address: {
    address: string
    city: string
    country: string
    state: string
    pin: string
  }
  leadinquiry: string
  generatedby: string
  generatedatd: string
  status: string
  metadata: { avatar: string }
}

var bufferMessages: any[] = []
const DrawerMessenger: FC<Props> = ({ isDrawer = false }) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [formData, setFormData] = useState<FormData>({
    user_id: '',
    avatar: '',
    user_name: '',
    phone: '',
    whatsapp: '',
    languages: '',
    email: '',
    address: {
      address: '',
      city: '',
      country: '',
      state: '',
      pin: '',
    },
    leadinquiry: '',
    generatedby: '',
    generatedatd: '',
    status: 'inactive',
    metadata: {
      avatar: '',
    }
  })
  const [online, setOnline] = useState([])
  const [messages, setMessages] = useState<MessageModel[]>(bufferMessages)
  // const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const { currentUser, superauth, auth } = useAuth()
  const token: any = superauth ? superauth?.api_token : auth?.api_token
  const location = useLocation()

  const { setChatUser, chatUser } = usePageData()
  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;

  }

  useEffect(() => {
    if (chatUser && chatUser !== '') {
      fetch(`${API_URL}/user/userById/${chatUser}`, {
        headers: {
          'Content-Type': 'application/json',
          token: token
        },
      })
        .then((response) => response.json())
        .then((data) => { setFormData(data) })
        .catch((error) => console.error(error))

      fetch(`${API_URL}/lead_msg/msgById/${chatUser}`, {
        headers: {
          'Content-Type': 'application/json',
          token: token
        },
      })
        .then((response) => response.json())
        .then((data) => { bufferMessages = data?.data; setMessages(data?.data) })
        .catch((error) => console.error(error))

      // socket.on("user-list", (data) => {
      //   setOnline(data)
      // })
    }
  }, [chatUser])

  // useEffect(() => {
  //   socket.on("user-list", (data) => {
  //     setOnline(data)
  //   })
  //   // socket.on("receive-message", (data) => {
  //     if (data?.reciever_id === currentUser?.user_id) {
  //       bufferMessages.push(data)
  //       setTimeout(() => {
  //         setMessages(bufferMessages)
  //         toggleChatUpdateFlat(!chatUpdateFlag)
  //       }, 500)

  //     }

  //   // })
  //   toggleChatUpdateFlat(!chatUpdateFlag)
  // }, [])



  // const getLeadUser = () => {
  //   fetch(`${API_URL}/Lead/leadById/${location?.state}`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       token: token,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => setFormData(data?.data))
  //     .catch((error) => console.error(error))
  // }

  // const ()=>null = async () => {
  //   const newMessage: MessageModel = {
  //     // lead_id : location.state,
  //     reciever_id: formData?.user_id,
  //     sender_id: currentUser?.user_id.toString(),
  //     messagetype: 'out',
  //     text: message,
  //     time: ''
  //   }
  //   const response = await axios.post(`${API_URL}/lead_msg/AddUpdateMsg`, newMessage, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       token: token,
  //     },
  //   })
  //   // socket.emit('send-message', newMessage);
  //   bufferMessages.push(newMessage)
  //   setMessages(bufferMessages)
  //   toggleChatUpdateFlat(!chatUpdateFlag)
  //   setMessage('')
  //   // setTimeout(() => {
  //   //   bufferMessages.push(messageFromClient)
  //   //   setMessages(() => bufferMessages)
  //   //   toggleChatUpdateFlat((flag) => !flag)
  //   // }, 1000)

  //   // there must be api call
  // }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      // ()=>null()
    }
  }
  return (
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
      onClick={() => setChatUser('')}
    >
      <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
        <div className='card-header pe-10' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
                {formData?.user_name ? formData?.user_name : formData?.email ? formData?.email : formData?.user_name ? formData?.user_name : formData?.phone}
              </a>

              <div className='mb-0 lh-1'>
                <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                <span className='fs-7 fw-bold text-gray-400'>{online.find((user: any) => user.user_id === formData?.user_id) ? 'Online' : 'Offline'}</span>
              </div>
            </div>
          </div>


          <ul className="nav nav-custom nav-tabs mt-1 nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">

            <li className="nav-item ">
              <a className="nav-link text-active-primary pb-4 active" data-kt-countup-tabs="true" data-bs-toggle="tab" href="#kt_customer_chat">
                <span className="svg-icon svg-icon-2" data-bs-toggle="tooltip" title="CHat">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chat" viewBox="0 0 16 16">
                    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                  </svg>
                </span></a>
            </li>

            <li className="nav-item">
              <a className="nav-link text-active-primary pb-4 " data-bs-toggle="tab" href="#kt_customer_whatsapp">
                <span className="svg-icon svg-icon-2" data-bs-toggle="tooltip" title="WhatsApp">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-whatsapp editbutton" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                  </svg>
                </span></a>
            </li>

            <li className="nav-item">
              <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_customer_SMS">
                <span className="svg-icon svg-icon-2 m-0" data-bs-toggle="tooltip" title="SMS">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="currentColor" />
                    <path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="currentColor" />
                  </svg>
                </span></a>
            </li>

            <li className="nav-item">
              <a className="nav-link text-active-primary pb-4" data-kt-countup-tabs="true" data-bs-toggle="tab" href="#kt_customer_email">
                <span className="svg-icon svg-icon-2" data-bs-toggle="tooltip" title="Email">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor" />
                    <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor" />
                  </svg>
                </span></a>
            </li>

            <li className="nav-item">
              <a className="nav-link text-active-primary pb-4" data-kt-countup-tabs="true" data-bs-toggle="tab" href="#kt_customer_phone">
                <span className="svg-icon svg-icon-2" data-bs-toggle="tooltip" title="Phone">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z" fill="currentColor" />
                    <path opacity="0.3" d="M19 4H5V20H19V4Z" fill="currentColor" />
                  </svg>
                </span></a>
            </li>

            <div className='card-toolbar'>
              <div className='me-2'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-3'></i>
                </button>
              </div>

              <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </div>
            </div>

          </ul>


          <div className="tab-content" >

            <div className="tab-pane fade show active" id="kt_customer_chat" role="tabpanel">

              <div

                id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
              >
                <div
                  className={clsx('scroll-y me-n5 pe-9', { 'h-500px h-lg-auto': !isDrawer })}
                  data-kt-element='messages'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies={
                    isDrawer
                      ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                      : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                  }
                  data-kt-scroll-wrappers={
                    isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
                  }
                  data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                >
                  {messages.map((message, index) => {

                    const state = message.sender_id === currentUser?.user_id ? 'primary' : 'info'
                    const templateAttr = {}
                    if (message.template) {
                      Object.defineProperty(templateAttr, 'data-kt-element', {
                        value: `template-${message.messagetype}`,
                      })
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.sender_id === currentUser?.user_id ? 'end' : 'start'
                      } mb-10`
                    return (
                      <div
                        key={`message${index}`}
                        className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                        {...templateAttr}
                      >
                        <div
                          className={clsx(
                            'd-flex flex-column align-items',
                            `align-items-${message.sender_id === currentUser?.user_id ? 'end' : 'start'}`
                          )}
                        >
                          <div className='d-flex align-items-center mb-2'>
                            {message.sender_id === currentUser?.user_id ? (
                              <>
                                <div className='me-3'>
                                  <span className='text-muted fs-7 mb-1'>{formatDate(new Date(message?.time === '' ? new Date() : message?.time))}</span>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                  >
                                    You
                                  </a>
                                </div>
                                {/* <div className='symbol  symbol-35px symbol-circle '>
                                  
                                   <img alt='Pic' src={toAbsoluteUrl(`/media/${formData?.metadata?.avatar}`)} />
                                 </div> */}
                              </>
                            ) : (
                              <>
                                {/* <div className='symbol  symbol-35px symbol-circle '>
                                  <img alt='Pic' src={toAbsoluteUrl(`/media/${formData?.metadata?.avatar}`)} />
                                </div> */}
                                <div className='ms-3'>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                  >
                                    {formData.user_name}
                                  </a>
                                  <span className='text-muted fs-7 mb-1'>{formatDate(new Date(message?.time === '' ? new Date() : message?.time))}</span>
                                </div>
                              </>


                            )}
                          </div>

                          <div
                            className={clsx(
                              'p-5 rounded',
                              `bg-light-${state}`,
                              'text-dark fw-bold mw-lg-400px',
                              `text-${message.sender_id === currentUser?.user_id ? 'end' : 'start'}`
                            )}
                            data-kt-element='message-text'
                            dangerouslySetInnerHTML={{ __html: message.text }}
                          ></div>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div
                  className='card-footer pt-4'
                  id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                >
                  <div className='d-flex flex-row'>
                    <div className="d-flex flex-column-auto mx-2">
                      <button
                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                        type='button'
                        data-bs-toggle='tooltip'
                        title='Coming soon'
                      >
                        <i className='bi bi-paperclip fs-3'></i>
                      </button>
                    </div>
                    <div className="d-flex flex-column-fluid mx-2">
                      <textarea
                        className='form-control form-control-flush mb-3'
                        rows={1}
                        data-kt-element='input'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={onEnterPress}
                      ></textarea>
                    </div>

                    <div className="d-flex flex-column-auto mx-2">
                      <button onClick={()=>{}} type="button" className="btn btn-active-light-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                      </button>

                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div className="tab-pane fade" id="kt_customer_whatsapp" role="tabpanel">

              <div
                id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
              >
                <div
                  className={clsx('scroll-y me-n5 pe-9', { 'h-500px h-lg-auto': !isDrawer })}
                  data-kt-element='messages'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies={
                    isDrawer
                      ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                      : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                  }
                  data-kt-scroll-wrappers={
                    isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
                  }
                  data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                >
                  {messages.map((message, index) => {

                    const state = message.sender_id === currentUser?.user_id ? 'primary' : 'info'
                    const templateAttr = {}
                    if (message.template) {
                      Object.defineProperty(templateAttr, 'data-kt-element', {
                        value: `template-${message.messagetype}`,
                      })
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.sender_id === currentUser?.user_id ? 'end' : 'start'
                      } mb-10`
                    return (
                      <div
                        key={`message${index}`}
                        className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                        {...templateAttr}
                      >
                        <div
                          className={clsx(
                            'd-flex flex-column align-items',
                            `align-items-${message.sender_id === currentUser?.user_id ? 'end' : 'start'}`
                          )}
                        >
                          <div className='d-flex align-items-center mb-2'>
                            {message.sender_id === currentUser?.user_id ? (
                              <>
                                <div className='me-3'>
                                  <span className='text-muted fs-7 mb-1'>{formatDate(new Date(message?.time === '' ? new Date() : message?.time))}</span>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                  >

                                  </a>
                                </div>

                              </>
                            ) : (
                              <>


                                <span className='text-muted fs-7 mb-1'>{formatDate(new Date(message?.time === '' ? new Date() : message?.time))}</span>

                              </>

                            )}
                          </div>

                          <div
                            className={clsx(
                              'p-5 rounded',
                              `bg-light-${state}`,
                              'text-dark fw-bold mw-lg-400px',
                              `text-${message.sender_id === currentUser?.user_id ? 'end' : 'start'}`
                            )}
                            data-kt-element='message-text'
                            dangerouslySetInnerHTML={{ __html: message.text }}
                          ></div>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div
                  className='card-footer pt-4'
                  id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                >
                  <div className='d-flex flex-row'>
                    <div className="d-flex flex-column-auto mx-2">
                      <button
                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                        type='button'
                        data-bs-toggle='tooltip'
                        title='Coming soon'
                      >
                        <i className='bi bi-paperclip fs-3'></i>
                      </button>
                    </div>
                    <div className="d-flex flex-column-fluid mx-2">
                      <textarea
                        className='form-control form-control-flush mb-3'
                        rows={1}
                        data-kt-element='input'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={onEnterPress}
                      ></textarea>
                    </div>

                    <div className="d-flex flex-column-auto mx-2">
                      <button onClick={()=>null} type="button" className="btn btn-active-light-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                      </button>

                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div className="tab-pane fade" id="kt_customer_SMS" role="tabpanel">

              <div
                id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
              >
                <div
                  className={clsx('scroll-y me-n5 pe-9', { 'h-500px h-lg-auto': !isDrawer })}
                  data-kt-element='messages'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies={
                    isDrawer
                      ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                      : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                  }
                  data-kt-scroll-wrappers={
                    isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
                  }
                  data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                >
                  {messages.map((message, index) => {

                    const state = message.sender_id === currentUser?.user_id ? 'primary' : 'info'
                    const templateAttr = {}
                    if (message.template) {
                      Object.defineProperty(templateAttr, 'data-kt-element', {
                        value: `template-${message.messagetype}`,
                      })
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.sender_id === currentUser?.user_id ? 'end' : 'start'
                      } mb-10`
                    return (
                      <div
                        key={`message${index}`}
                        className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                        {...templateAttr}
                      >
                        <div
                          className={clsx(
                            'd-flex flex-column align-items',
                            `align-items-${message.sender_id === currentUser?.user_id ? 'end' : 'start'}`
                          )}
                        >
                          <div className='d-flex align-items-center mb-2'>
                            {message.sender_id === currentUser?.user_id ? (
                              <>
                                <div className='me-3'>
                                  <span className='text-muted fs-7 mb-1'>{formatDate(new Date(message?.time === '' ? new Date() : message?.time))}</span>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                  >

                                  </a>
                                </div>

                              </>
                            ) : (
                              <>


                                <span className='text-muted fs-7 mb-1'>{formatDate(new Date(message?.time === '' ? new Date() : message?.time))}</span>

                              </>

                            )}
                          </div>

                          <div
                            className={clsx(
                              'p-5 rounded',
                              `bg-light-${state}`,
                              'text-dark fw-bold mw-lg-400px',
                              `text-${message.sender_id === currentUser?.user_id ? 'end' : 'start'}`
                            )}
                            data-kt-element='message-text'
                            dangerouslySetInnerHTML={{ __html: message.text }}
                          ></div>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div
                  className='card-footer pt-4'
                  id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                >
                  <div className='d-flex flex-row'>
                    <div className="d-flex flex-column-auto mx-2">
                      <button
                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                        type='button'
                        data-bs-toggle='tooltip'
                        title='Coming soon'
                      >
                        <i className='bi bi-paperclip fs-3'></i>
                      </button>
                    </div>
                    <div className="d-flex flex-column-fluid mx-2">
                      <textarea
                        className='form-control form-control-flush mb-3'
                        rows={1}
                        data-kt-element='input'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={onEnterPress}
                      ></textarea>
                    </div>

                    <div className="d-flex flex-column-auto mx-2">
                      <button onClick={()=>null} type="button" className="btn btn-active-light-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                      </button>

                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div className="tab-pane fade" id="kt_customer_email" role="tabpanel">


              <div
                id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
              >


                <div className="collapse fade show" data-kt-inbox-message="message">
                  <div className="py-5">
                    <p>Hi Bob,</p>
                    <p>With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important part of any article is the title.Without a compelleing title, your reader won't even get to the first sentence.After the title, however, the first few sentences of your article are certainly the most important part.</p>
                    <p>Jornalists call this critical, introductory section the "Lede," and when bridge properly executed, it's the that carries your reader from an headine try at attention-grabbing to the body of your blog post, if you want to get it right on of these 10 clever ways to omen your next blog posr with a bang</p>
                    <p>Best regards,</p>
                    <p className="mb-0">Jason Muller</p>
                  </div>
                </div>

                <div className="d-flex flex-wrap gap-2 flex-stack cursor-pointer" data-kt-inbox-message="header">

                  <div className="d-flex align-items-center">

                    <div className="symbol symbol-50 me-4">
                      <span className="symbol-label" style={{ backgroundImage: `url(assets/media/avatars/300-1.jpg)` }}></span>
                    </div>

                    <div className="pe-5">

                      <div className="d-flex align-items-center flex-wrap gap-1">
                        <a href="#" className="fw-bold text-dark text-hover-primary">Max Smith</a>

                        <span className="svg-icon svg-icon-7 svg-icon-success mx-3">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <circle fill="currentColor" cx="12" cy="12" r="8" />
                          </svg>
                        </span>

                        <span className="text-muted fw-bold">2 days ago</span>
                      </div>


                      <div className="d-none" data-kt-inbox-message="details">
                        <span className="text-muted fw-semibold">to me</span>

                        <a href="#" className="me-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">

                          <span className="svg-icon svg-icon-5 m-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                            </svg>
                          </span>

                        </a>


                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px p-4" data-kt-menu="true">

                          <table className="table mb-0">
                            <tbody>

                              <tr>
                                <td className="w-75px text-muted">From</td>
                                <td>Emma Bold</td>
                              </tr>


                              <tr>
                                <td className="text-muted">Date</td>
                                <td>24 Jun 2023, 5:30 pm</td>
                              </tr>


                              <tr>
                                <td className="text-muted">Subject</td>
                                <td>Trip Reminder. Thank you for flying with us!</td>
                              </tr>


                              <tr>
                                <td className="text-muted">Reply-to</td>
                                <td>emma@intenso.com</td>
                              </tr>

                            </tbody>
                          </table>

                        </div>

                      </div>


                      <div className="text-muted fw-semibold mw-450px" data-kt-inbox-message="preview">Jornalists call this critical, introductory section the "Lede," and when bridge properly executed....</div>

                    </div>
                  </div>




                </div>


                <div className="collapse fade" data-kt-inbox-message="message">
                  <div className="py-5">
                    <p>Hi Bob,</p>
                    <p>With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important part of any article is the title.Without a compelleing title, your reader won't even get to the first sentence.After the title, however, the first few sentences of your article are certainly the most important part.</p>
                    <p>Jornalists call this critical, introductory section the "Lede," and when bridge properly executed, it's the that carries your reader from an headine try at attention-grabbing to the body of your blog post, if you want to get it right on of these 10 clever ways to omen your next blog posr with a bang</p>
                    <p>Best regards,</p>
                    <p className="mb-0">Jason Muller</p>
                  </div>
                </div>

                <div data-kt-inbox-message="message_wrapper">

                  <div className="d-flex flex-wrap gap-2 flex-stack cursor-pointer" data-kt-inbox-message="header">

                    <div className="d-flex align-items-center">

                      <div className="symbol symbol-50 me-4">
                        <span className="symbol-label" style={{ backgroundImage: `url(assets/media/avatars/300-5.jpg)` }}></span>
                      </div>

                      <div className="pe-5">

                        <div className="d-flex align-items-center flex-wrap gap-1">
                          <a href="#" className="fw-bold text-dark text-hover-primary">Sean Bean</a>

                          <span className="svg-icon svg-icon-7 svg-icon-success mx-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <circle fill="currentColor" cx="12" cy="12" r="8" />
                            </svg>
                          </span>

                          <span className="text-muted fw-bold">3 days ago</span>
                        </div>


                        <div className="d-none" data-kt-inbox-message="details">
                          <span className="text-muted fw-semibold">to me</span>

                          <a href="#" className="me-1" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">

                            <span className="svg-icon svg-icon-5 m-0">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                              </svg>
                            </span>

                          </a>


                          <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-300px p-4" data-kt-menu="true">

                            <table className="table mb-0">
                              <tbody>

                                <tr>
                                  <td className="w-75px text-muted">From</td>
                                  <td>Emma Bold</td>
                                </tr>


                                <tr>
                                  <td className="text-muted">Date</td>
                                  <td>19 Aug 2023, 6:43 am</td>
                                </tr>


                                <tr>
                                  <td className="text-muted">Subject</td>
                                  <td>Trip Reminder. Thank you for flying with us!</td>
                                </tr>


                                <tr>
                                  <td className="text-muted">Reply-to</td>
                                  <td>emma@intenso.com</td>
                                </tr>

                              </tbody>
                            </table>

                          </div>

                        </div>


                        <div className="text-muted fw-semibold mw-450px" data-kt-inbox-message="preview">Jornalists call this critical, introductory section the "Lede," and when bridge properly executed....</div>

                      </div>
                    </div>




                  </div>


                  <div className="collapse fade" data-kt-inbox-message="message">
                    <div className="py-5">
                      <p>Hi Bob,</p>
                      <p>With resrpect, i must disagree with Mr.Zinsser. We all know the most part of important part of any article is the title.Without a compelleing title, your reader won't even get to the first sentence.After the title, however, the first few sentences of your article are certainly the most important part.</p>
                      <p>Jornalists call this critical, introductory section the "Lede," and when bridge properly executed, it's the that carries your reader from an headine try at attention-grabbing to the body of your blog post, if you want to get it right on of these 10 clever ways to omen your next blog posr with a bang</p>
                      <p>Best regards,</p>
                      <p className="mb-0">Jason Muller</p>
                    </div>
                  </div>

                </div>

                <div
                  className='card-footer pt-4'
                  id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                >
                  <div className='d-flex flex-row'>
                    <div className="d-flex flex-column-auto mx-2">
                      <button
                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                        type='button'
                        data-bs-toggle='tooltip'
                        title='Coming soon'
                      >
                        <i className='bi bi-paperclip fs-3'></i>
                      </button>
                    </div>
                    <div className="d-flex flex-column-fluid mx-2">
                      <textarea
                        className='form-control form-control-flush mb-3'
                        rows={1}
                        data-kt-element='input'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={onEnterPress}
                      ></textarea>
                    </div>

                    <div className="d-flex flex-column-auto mx-2">
                      <button onClick={()=>null} type="button" className="btn btn-active-light-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                      </button>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="tab-pane fade" id="kt_customer_phone" role="tabpanel">

              <div
                id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
              >
                <div
                  className={clsx('scroll-y me-n5 pe-9', { 'h-500px h-lg-auto': !isDrawer })}
                  data-kt-element='messages'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies={
                    isDrawer
                      ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                      : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                  }
                  data-kt-scroll-wrappers={
                    isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
                  }
                  data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                >
                  {messages.map((message, index) => {

                    const state = message.sender_id === currentUser?.user_id ? 'primary' : 'info'
                    const templateAttr = {}
                    if (message.template) {
                      Object.defineProperty(templateAttr, 'data-kt-element', {
                        value: `template-${message.messagetype}`,
                      })
                    }
                    const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.messagetype === 'in' ? 'start' : 'end'
                      } mb-10`
                    return (
                      <div
                        key={`message${index}`}
                        className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
                        {...templateAttr}
                      >
                        <div
                          className={clsx(
                            'd-flex flex-column align-items',
                            `align-items-${message.sender_id === currentUser?.user_id ? 'end' : 'start'}`
                          )}
                        >
                          <div className='d-flex align-items-center mb-2'>
                            {message.sender_id === currentUser?.user_id ? (
                              <>
                                <div className='me-3'>
                                  <span className='text-muted fs-7 mb-1'>{formatDate(new Date(message?.time === '' ? new Date() : message?.time))}</span>
                                  <a
                                    href='#'
                                    className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                  >

                                  </a>
                                </div>

                              </>
                            ) : (
                              <>


                                <span className='text-muted fs-7 mb-1'>{formatDate(new Date(message?.time === '' ? new Date() : message?.time))}</span>

                              </>

                            )}
                          </div>

                          <div
                            className={clsx(
                              'p-5 rounded',
                              `bg-light-${state}`,
                              'text-dark fw-bold mw-lg-400px',
                              `text-${message.sender_id === currentUser?.user_id ? 'end' : 'start'}`
                            )}
                            data-kt-element='message-text'
                            dangerouslySetInnerHTML={{ __html: message.text }}
                          ></div>
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div
                  className='card-footer pt-4'
                  id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                >
                  <div className='d-flex flex-row'>
                    <div className="d-flex flex-column-auto mx-2">
                      <button
                        className='btn btn-sm btn-icon btn-active-light-primary me-1'
                        type='button'
                        data-bs-toggle='tooltip'
                        title='Coming soon'
                      >
                        <i className='bi bi-paperclip fs-3'></i>
                      </button>
                    </div>
                    <div className="d-flex flex-column-fluid mx-2">
                      <textarea
                        className='form-control form-control-flush mb-3'
                        rows={1}
                        data-kt-element='input'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={onEnterPress}
                      ></textarea>
                    </div>

                    <div className="d-flex flex-column-auto mx-2">
                      <button onClick={()=>null} type="button" className="btn btn-active-light-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                          <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg>
                      </button>

                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>




        </div>


      </div>
    </div>
  )
}

export { DrawerMessenger }
