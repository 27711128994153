//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import {ActionTypes} from '../constants'

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const couponAdminInitial = []

export const couponAdminReducer = (state = couponAdminInitial, action) => {
  switch (action.type) {
    case ActionTypes.adminPublish.updateCouponSuccess:
      return state.map((x) => {
        if (x.couponId === action.payload?.couponId) {
          return {...x, ...action.payload}
        }
        return x
      })

    case ActionTypes.adminPublish.getCouponSuccess:
      return action.payload

    default:
      return state
  }
}
