//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------
import { ActionTypes } from '../constants';

const _ = require('lodash');


//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const BranchInitial = [];

export const BranchReducer = (state = BranchInitial, action) => {
    switch (action.type) {
        case ActionTypes.Branch.createBranchSuccess:
          
            return [action.payload, ...state];
        case ActionTypes.Branch.deleteBranchSuccess:
                return state.filter(x => x.branchId !== action.payload.branchId);
                // return  _.differenceWith(state, action.payload, (val, dataItem) => val.branchId === dataItem.branchId)
        case ActionTypes.Branch.updateBranchSuccess:
                return state.map(x => {
                    if (x.branchId === action.payload?.branchId) {
                        return { ...x, ...action.payload };
                    }
                    return x;
                }); 
        case ActionTypes.Branch.getBranchSuccess:
            return action.payload;
        case ActionTypes.Branch.getBranchFail:
            return state;
        default:
            return state;
    }
};
