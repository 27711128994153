import React, {useEffect, useState} from 'react'
import Select from 'react-select'

const MultiSelectDropdown = ({
  options,
  setValueDropDown,
  valueDropDown,
  onOptionChange = (val) => {},
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  useEffect(() => {
    setValueDropDown(selectedOptions)
  }, [selectedOptions])
  useEffect(() => {
    setValueDropDown(valueDropDown)
    setSelectedOptions(valueDropDown)
  }, [])

  const modifiedOptions = options.map((option) => ({
    value: option.alias,
    label: (option?.Dbfield?.charAt(0).toUpperCase() + option?.Dbfield?.slice(1)).replaceAll('_', ' '),
  }))

  const handleOptionSelect = (selectedOption) => {
    const selectedOptionValues = selectedOption.map((option) => option.value)
    setSelectedOptions(selectedOptionValues)
    onOptionChange(selectedOption)
  }

  const formatOptionLabel = ({label, value}) => (
    <label className='d-flex align-item-center '>
      <input
        className='me-2 '
        type='checkbox'
        value={value}
        checked={selectedOptions.indexOf(value) !== -1} // check if option is selected
        onChange={() => {}}
        style={{backgroundColor: 'var(--bs-app-blank-bg)'}}
      />
      {label}
    </label>
  )

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '180px',
      minHeight: '46px',
      backgroundColor: 'var(--bs-app-blank-bg)',

      // set the width here
    }),
    menu: (provided, state) => ({
      ...provided,
      height: '200px', // set the height to 200px
      overflowY: 'scroll',
      backgroundColor: 'var(--bs-app-blank-bg)',
      // enable scrolling if necessary
    }),

    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'var(--bs-primary-light)', // Change the background color on hover
        color:'#6b6b6b'
      },
    }),
  }

  return (
    <div className='fixdrop'>
      <Select
        options={modifiedOptions.length > 0 ? modifiedOptions : []}
        value={selectedOptions.map((option) => modifiedOptions.find((o) => o.value === option))}
        onChange={handleOptionSelect}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        formatOptionLabel={formatOptionLabel}
        menuPortalTarget={document.body}
        styles={customStyles}
      />
    </div>
  )
}

export default MultiSelectDropdown
