const DeleteData = ({isSelected, deletePackage}) => {
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{isSelected?.length}</span> Selected
      </div>

      <button type='button' className='btn btn-danger' onClick={() => deletePackage()}>
        Delete Selected
      </button>
    </div>
  )
}

export {DeleteData}
