import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {Dropdown1, Search} from '../../../../partials'
import {useAuth} from '../../../../../app/modules/auth'
import {useFunction} from '../../../../../app/modules/auth/core/functionRoles'
import { useEffect } from 'react'

const projects: ReadonlyArray<{image: string; title: string; link: string; label: string}> = [
  // {
  //   image: '/media/svg/brand-logos/bebo.svg',
  //   title: 'Team Members',
  //   link: 'apps/settings/employees',
  // },

  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Sector',
    link: 'apps/sector/',
    label: 'Sectors',
  },
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Organization',
    link: 'crafted/organization',
    label: 'organizations',
  },
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Email Template',
    link: 'apps/email',
    label: 'Campaign',
  },
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Custom Form',
    link: 'apps/forms',
    label: 'CustomForm',
  },
  // {
  //   image: '/media/svg/brand-logos/bebo.svg',
  //   title: 'Admin',
  //   link: 'apps/marchant/sellers',
  //   label: 'Admin ',
  // },
  // {
  //   image: '/media/svg/brand-logos/bebo.svg',
  //   title: 'Marchant',
  //   link: 'apps/marchant',
  //   label: 'Marchant',
  // },
  // {
  //   image: '/media/svg/brand-logos/bebo.svg',
  //   title: 'Lead Table',
  //   link: 'apps/leadtable/',
  // }
]

const SuperAdminprojects: ReadonlyArray<{
  image: string
  title: string
  link: string
  label: string
}> = [
  // {
  //   image: '/media/svg/brand-logos/bebo.svg',
  //   title: 'Sector',
  //   link: 'apps/sector/',
  // },
  // {
  //   image: '/media/svg/brand-logos/bebo.svg',
  //   title: 'Role',
  //   link: 'apps/userrole/role',
  // },
  // {
  //   image: '/media/svg/brand-logos/bebo.svg',
  //   title: 'Custom Form',
  //   link: 'apps/forms'
  // },
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Sector',
    link: 'apps/sector/',
    label: 'Sectors',
  },
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Role',
    link: 'apps/userrole/role',
    label: 'SuperRole',
  },
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Custom Form',
    link: 'apps/forms',
    label: 'CustomForm',
  },
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Organization',
    link: '/crafted/organization',
    label: 'organizations',
  },
  // {
  //   image: '/media/svg/brand-logos/bebo.svg',
  //   title: 'Lead Table',
  //   link: 'apps/leadtable/',
  // }
]

const SettingsTab = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
   navigate('/crafted/organization/settings')
  }, [])
 
  let project: any = []
  
  if (currentUser?.is_admin === true  ) {//
    // let values:any= {
    //   image: '/media/svg/brand-logos/bebo.svg',
    //   title: 'Role',
    //   link: 'apps/userrole/role',
    //   label:'is_admin'
    // }
    // project=[values,...projects]
     project = projects
  } else {
    project = [...projects]
  }

  // let SuperAdminproject: any = []
  // if (currentUser?.is_superadmin &&String(currentUser.user_id) == 'fff05a99-24f5-4a21-80e7-4dc0a49669db' ) {//currentUser.user_id === 'fff05a99-24f5-4a21-80e7-4dc0a49669db'
  //   let values:any={
  //       image: '/media/svg/brand-logos/bebo.svg',
  //       title: 'Role',
  //       link: 'apps/userrole/role',
  //       label: 'role',
  //     }
  //   SuperAdminproject=[values,...SuperAdminprojects]
  //   // project = projects
  // } else {
  //   SuperAdminproject = [...SuperAdminprojects]
  // }

  const {setfunction, functionName, setfunctionCategory, functionCategory} = useFunction()

  return (
    <div className='m-0'>
      {/* <div className='d-flex mb-10'>
        <Search />
        <div className='flex-shrink-0 ms-2'>
          <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div>
      </div> */}
      <div className='m-0'>
        <div className='mb-10'>
          {currentUser?.is_superadmin == 1
            ? SuperAdminprojects?.filter(
                (key: any) =>
                  
                  functionCategory?.includes(key.label)
              ).map((p:any) => (
                <Link
                  key={p.link}
                  to={p.link}
                  className='custom-list d-flex align-items-center px-5 py-4'
                >
                  <div className='symbol symbol-40px me-5'>
                    <span className='symbol-label'>
                      <img
                        src={toAbsoluteUrl(p.image)}
                        alt={p.title}
                        className='h-50 align-self-center'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column flex-grow-1'>
                    <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{p.title}</h5>
                  </div>
                </Link>
              ))
            : project
                ?.filter(
                  (key: any) =>
                    (key.label !== 'Sectors' &&
                      key.label !== 'Campaign' &&
                      key.label !== 'organizations' &&
                      key.label !== 'CustomForm') ||
                    functionCategory?.includes(key.label)
                )
                .map((p: any) => (
                  <Link
                    key={p.link}
                    to={p.link}
                    className='custom-list d-flex align-items-center px-5 py-4'
                  >
                    <div className='symbol symbol-40px me-5'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl(p.image)}
                          alt={p.title}
                          className='h-50 align-self-center'
                        />
                      </span>
                    </div>

                    <div className='d-flex flex-column flex-grow-1'>
                      <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{p.title}</h5>
                    </div>
                  </Link>
                ))}
        </div>
      </div>
    </div>
  )
}

export {SettingsTab}
