import {shallowEqual, useSelector} from 'react-redux'

const Branchdata = (state) => state.formvalues

export const useFormsData = () => {
  const selectedData = useSelector(Branchdata, shallowEqual)
  return selectedData
}

const formData = (state) => state.formData

export const useFormsfullData = () => {
  const selectedData = useSelector(formData, shallowEqual)
  return selectedData
}

const getformData = (state) => state.AllForm

export const useGetFormsData = () => {
  const selectedData = useSelector(getformData, shallowEqual)
  return selectedData
}

const getEditvalues = (state) => state.editval

export const useEditvalues = () => {
  const selectedData = useSelector(getEditvalues, shallowEqual)
  return selectedData
}
const thresholdPointValues=(state) => state.Point

export const useThresholdPoints = () => { 
  const selected = useSelector(thresholdPointValues, shallowEqual)
  return selected
}
