export const datas = [
  //   {Dbfield: 'superadminid', alias: 'aid'},
  {Dbfield: 'name', alias: 'sName'},
  {Dbfield: 'status', alias: 'status'},
]
export const datass = [
  {Dbfield: 'name', alias: 'sName'},
  {Dbfield: 'status', alias: 'status'},
]

export const AscDescDatas = ['sName', 'status']

export const Alias = (data) => datas.filter((item) => item.Dbfield === data)[0]?.alias
export const Dbfield = (data) => datas.filter((item) => item.alias === data)[0]?.Dbfield
