import axios, { AxiosRequestHeaders } from 'axios'
import { UserModel} from './_models'
import { getAuth } from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL
const new_token = getAuth()
const headers: AxiosRequestHeaders = {
  token: new_token?.api_token as string, // Assuming `tokenValue` is of type `string | undefined`
}
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/verify_token`
export const LOGIN_URL = `${API_URL}/user/login`
export const REGISTER_URL = `${API_URL}/user/managerUser`
export const REQUEST_PASSWORD_URL = `${API_URL}/user/forgotPassword`
export const REQUEST_PASSWORDSAVE_URL = `${API_URL}/user/setNewPassWord`
export const CONFIRM_OTP_URL = `${API_URL}/user/verify`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

export function verify_otp(otp: any) {
  return axios.post(`${CONFIRM_OTP_URL}`, otp)
}

// Server should return AuthModel
export function register(value: any) {
  return axios.post(REGISTER_URL, {
    email: value?.email,
    phone_number: value?.phone,
    user_name: value?.name,
    password: value?.password,
    is_admin: value?.is_admin
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<any>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestPasswordsave(data: any) {
  return axios.post<any>(REQUEST_PASSWORDSAVE_URL, {
    data,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  }
  )
}
