//  ----------------------------------------------------------------------------
//  Dependencies
//  ----------------------------------------------------------------------------

//  -- Constants ----------------------

const _ = require('lodash')

//  ----------------------------------------------------------------------------
//  Reducers
//  ----------------------------------------------------------------------------

//  -- Example ------------------------
const template = []

export const reducerTemplate = (state = template, action) => {
  switch (action.type) {
    case 'Template':
      return action.payload
 

    default:
      return state
  }
}


