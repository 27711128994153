/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useGetReport} from '../../../../app/modules/apps/Forms/store/Report/selectors'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { handleChangePrint } from '../../../../app/modules/Component/helper/print'
import { feedBack } from '../../../../app/modules/Component/modal/data'
import { getFeedback } from '../../../../app/modules/ApiCall'
import { useAuth } from '../../../../app/modules/auth'
import PaginationUI from '../../../../app/modules/Component/pagination/pagination'
import { FilterDate } from '../../../../app/pages/dashboard/FilterDate'

const TablesWidget9 = ({className}) => {
  const API_URL = process.env.REACT_APP_API_URL

  // const resultlatest = useGetReport()?.resultlatest
const [resultlatest,setResultlatest]=useState([])
const [loading, setLoading] = useState(false)
const { currentUser, superauth, auth } = useAuth()
const [valueDropDown, setValueDropDown] = useState(['pName', 'fScore', 'cCreated'])
const [paginationData, setPaginationData] = useState({
  currentPage: 1,
  totalPages: 1,
  totalCount: 6,
})
const [sorting, setSorting] = useState([{ fieldName: "cCreated", order: "DESC" }, { fieldName: "fScore", order: "DESC" }])
const [paginationDataApi, setPaginationDataApi] = useState({ pages: 1, limits: 5 })
const [moreloading, setMoreLoading] = useState(false)

const [condition, setCondition] = useState([
  { column: 'pName', operator: 'LIKE', value: `%%` },
])
const Alias = (data, tableName) => {
  return tableName?.filter((item) => item.Dbfield === data)[0]?.alias
} 
const setFilter = (column, row) => {
  if (!row) {
    setCondition([
      {
        column: Alias(column, feedBack) ? Alias(column, feedBack) : 'cCreated',
        operator: 'LIKE',
        value: `%%`,
      },
    ])
  }else{
  setCondition([
    {
      column: Alias(column, feedBack) ? Alias(column, feedBack) : 'cCreated',
      operator: 'BETWEEN',
      value: row,
    },
  ])
}
}
const token = auth?.api_token
const getForm = async () => {
  setLoading(true)
  let data = {
    fieldName: valueDropDown,
    sorting: sorting,
    pagination: { pages: paginationDataApi?.pages || 1, limits: paginationDataApi?.limits || 5 },
    filters: condition,
    form_id:  '',
    company_id:currentUser?.company_id,
    user_id: currentUser?.user_id
  }
try {
  const agentData = await getFeedback(data, token)
  if (agentData.status === 200) {
    setResultlatest(agentData?.data?.data?.data)
    setMoreLoading(false)
    setLoading(false)
    setPaginationData({
      currentPage: agentData?.data?.data?.currentPage,
      totalPages: agentData?.data?.data?.totalPages,
      totalCount: agentData?.data?.data?.totalCount,
    })
  }
} catch (error) {
  setLoading(false)

}
 

}
useEffect(()=>{
  // const getReportData = async()  => {
  //   try {
  //     let token = sessionStorage.getItem('kt-auth-react-v')
  
  //     let token1 = JSON.parse(token)
  //     setLoading(true)
  //     const config = {
  //       headers: {authorizationtoken: token1?.api_token, 'Content-Type': 'application/json'},
  //     }
  
  //     const {data} = await axios.get(`${API_URL}/googlereview/report`, config)
  //     setResultlatest(data?.resultlatest)
  //     setLoading(false)

  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }
  getForm()
},[valueDropDown, sorting, paginationDataApi, condition])
let navigate=useNavigate()
  const gotofeedback=(id)=>{
    navigate('/apps/feedback/list', {state: id})

  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>All Feedback</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Over {resultlatest?.length} members</span>
        </h3>
     
        <div className='d-flex fixbtnload my-0 mt-2 mb-2 '>
        <FilterDate fildata={feedBack?.filter(data => ( data.Dbfield !== 'person_name' && data.Dbfield !== 'feedback_score'))} filterfunction={setFilter} />
        <select
  onChange={(e) => handleChangePrint(e, resultlatest)}
  data-control="select2"
  data-hide-search="true"
  data-placeholder="Export"
  className="form-select w-100px"
  id="element-to-print"
>
  <option value="CSV">CSV</option>
  <option value="PDF">PDF</option>
</select>

               
              </div>
        {/* <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        > */}
          {/* <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            New Member
          </a> */}
        {/* </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th> */}
                <th className='min-w-150px'>Person Name</th>
                <th className='min-w-140px'>Form Name</th>
                <th className='min-w-120px'>Average</th>
                {/* <th className='min-w-100px text-end'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {Array.isArray(resultlatest) && resultlatest.length > 0 ? (
              Array.isArray(resultlatest) &&
                resultlatest.map((item) => (
                  <>
                    <tr>
                      {/* <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td> */}
                      <td>
                        <div className='d-flex align-items-center'>
                          {/* <div className='symbol symbol-45px me-5'>
                            <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                          </div> */}
                          <div className='d-flex justify-content-start flex-column'>
                            <a  className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer'  onClick={()=>gotofeedback(item?.id)}>
                              {item?.person_name}
                            </a>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              HTML, JS, ReactJS
                            </span> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                          {item?.form_name}
                        </a>
                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Web, UI/UX Design
                        </span> */}
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>
                              {item?.feedback_score}/10
                            </span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-primary'
                              role='progressbar'
                              style={{width: `${item?.feedback_score * 10}%`}}
                            ></div>
                          </div>
                        </div>
                      </td>
                      {/* <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td> */}
                    </tr>
                  </>
                ))
            ):(<>
              {loading ? (
                <span className=' mb-4 mt-7 indicator-progress loadingCutomer fixloaderissue'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <div className=' mb-4 mt-7 fixnotavaibale '>No Data Available</div>
              )}
            </>)}
              {/* <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Jessie Clarcson
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        C#, ASP.NET, MS SQL
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Agoda
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Houses &amp; Hotels
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>70%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-danger'
                        role='progressbar'
                        style={{width: '70%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-5.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Lebron Wayde
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        PHP, Laravel, VueJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    RoadGee
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Transportation
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>60%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-success'
                        role='progressbar'
                        style={{width: '60%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-20.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Natali Goodwin
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Python, PostgreSQL, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    The Hill
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>Insurance</span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-warning'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Kevin Leonard
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        HTML, JS, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    RoadGee
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Art Director
                  </span>
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>90%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-info'
                        role='progressbar'
                        style={{width: '90%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </td>
              </tr> */}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      { resultlatest && resultlatest.length > 0 &&(<>
          {/* <div className='d-grid mt-3 mb-5'>
            {(paginationDataApi?.limits * paginationDataApi?.pages || 1) >=paginationData.totalCount  ? (
              <button
                key={paginationDataApi?.limits} // Add a unique key here
                onClick={() => {}}
                type='button'
                className='btn btn-primary btn-active-icon-primary fixlistingendbutton'
              >
             
                     That's It
                  
               
              </button>
            ) : (
              <button
                key={paginationDataApi?.limits} // Add a unique key here
                onClick={() => {
                  setPaginationDataApi({
                    ...paginationDataApi,
                    limits: paginationDataApi?.limits + 10 || 10,
                  })
                  setMoreLoading(true)
                }}
                type='button'
                className='btn btn-primary btn-active-icon-primary fixloadmorebutton'
              >
                {!moreloading && <>Load More +</>}
                {moreloading && (
                  <span className='indicator-progress fixloadersite'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
          </div> */}
          <div className='d-flex flex-stack flex-wrap pt-10 mb-2'>
            <div className='fs-6 fw-semibold text-gray-700'></div>

            <ul className='pagination'>
              <PaginationUI
                currentPage={paginationData.currentPage}
                totalPages={paginationData.totalPages}
                onPageChange={(value) =>
                  setPaginationDataApi({
                    ...paginationDataApi,
                    pages: value,
                    limits: paginationDataApi?.limits || 5,
                  })
                }
              />
            </ul>
       </div></>)}
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget9}
