import {Link, useNavigate} from 'react-router-dom'

import {toAbsoluteUrl, KTSVG} from '../../../../../_metronic/helpers'

import {Dropdown1, Search} from '../../../../../_metronic/partials'
import { useEffect } from 'react';

const projects: ReadonlyArray<{image: string; title: string; link: string; label: string}> = [
  {
    image: '/media/svg/brand-logos/bebo.svg',
    title: 'Subscribers',
    link: 'apps/subscription/list',
    label: 'Subscription',
  },
]

const Submenu = () => {
  const navigate = useNavigate()

  useEffect(() =>{
   navigate('apps/subscription/list')

  },[])

  return (
    <div className='m-0'>
      {/* <div className='d-flex mb-10'>
        <Search />
        <div className='flex-shrink-0 ms-2'>
          <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div>
      </div> */}
      <div className='m-0'>
        <div className='mb-10'>
          {projects?.map((key: any) => (
            <Link
              key={key.link}
              to={key.link}
              className='custom-list d-flex align-items-center px-5 py-4'
            >
              <div className='symbol symbol-40px me-5'>
                <span className='symbol-label'>
                  <img
                    src={toAbsoluteUrl(key.image)}
                    alt={key.title}
                    className='h-50 align-self-center'
                  />
                </span>
              </div>

              <div className='d-flex flex-column flex-grow-1'>
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{key.title}</h5>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Submenu
