import React, {  useState } from 'react'
import PaginationUI from '../../../Component/pagination/pagination'
import {  KTSVG } from '../../../../../_metronic/helpers'
import {  AscDescDatas } from '.././apicall/Changefield'
import { datas} from '.././apicall/Changefield'
import CryptoJS from 'crypto-js'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useAuth } from '../../../auth'


type ButtonClickHandler = (item: any) => void

type DeleteClickHandler = (item: any, value: any) => void

interface paginationObject {
  currentPage: any
  totalPages: any
  totalCount: any
}

interface MyComponentProps {
  valueDropDown: any
  data1: any
  setData1: any
  paginationData: paginationObject
  componentRef: any
  componentRefcol: any
  functionName: any
  onEditBtn: ButtonClickHandler
  onDeleteBtn: ButtonClickHandler
  onViewBtn: ButtonClickHandler
  onViewBtn1: ButtonClickHandler
  deleteAllData: DeleteClickHandler
  setPaginationDataApi: any
  paginationDataApi: any
  isSelected: any
  listloading: any
  setListloading: any
  setloadMoreloading:any
  loadMoreloading:any
}

export const ListView: React.FC<MyComponentProps> = ({
  valueDropDown,
  setData1,
  data1,
  paginationData,
  componentRef,
  componentRefcol,
  onEditBtn,
  onDeleteBtn,
  onViewBtn,
  onViewBtn1,
  functionName,
  deleteAllData,
  setPaginationDataApi,
  paginationDataApi,
  isSelected,
  listloading,
  loadMoreloading,
  setloadMoreloading

}) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) return

    const newData = [...data1]
    const [draggedItem] = newData.splice(result.source.index, 1)
    newData.splice(result.destination.index, 0, draggedItem)
    setData1(newData)
    //setState({data: newData})
  }

  const { currentUser } = useAuth()
  const API_URL = process.env.REACT_APP_API_URL
  // const navigate = useNavigate()

  // const [search, setSearch] = useState('')
  // //   const [data1, setData1] = useState<User[]>([] as User[])
  // const [rowColumn, setRowColumn] = useState('row')

  // const [dropdowndata, setdropdowndata] = useState<any>()
  //   const [paginationData, setPaginationData] = useState<any>({})
  // const [paginationDataApi, setPaginationDataApi] = useState<any>({pages: 1, limits: 2})
  const [sorting, setSorting] = useState<any>([{ fieldName: 'pcreated', order: 'DESC' }])
  const [order, setOrder] = useState<any>('ASC')
  // const [loading, setLoading] = useState(false)
  // const [condition, setCondition] = useState<any>([
  //   { column: 'pName', operator: 'LIKE', value: `%%` },
  // ])

  //   const [valueDropDown, setValueDropDown] = useState<any>(['pName', 'ptitle', 'pimg'])
  // const [deleteAllData1, setDeleteAllData1] = useState<any>([])
  // const [isSelected, setIsSelected] = useState<any>([])
  // const deleteAllData = (e: React.ChangeEvent<HTMLInputElement>, value: any) => {
  //   if (value == 'full') {
  //     if (e.target.checked) {
  //       setIsSelected(data1.map((item: any) => item.packageid))
  //     } else {
  //       setIsSelected([])
  //     }
  //   } else {
  //     if (isSelected.includes(value.packageid)) {
  //       let index = isSelected.indexOf(value.packageid)
  //       isSelected.splice(index, 1)
  //       setIsSelected([...isSelected])
  //     } else {
  //       setIsSelected([...isSelected, value.packageid])
  //     }
  //   }
  //
  // }

  const secretKey = 'my-secret-key'
  const decryptData = (encryptedData: any) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  }

  //   useEffect(() => {
  //     getpackageDataApi()
  //   }, [paginationDataApi, valueDropDown, sorting, condition])

  const handleColumnClick = (name: any, orders: any) => {
    //  if(sort.includes(name)
    let alias = datas.filter((item) => item.Dbfield === name)[0]?.alias
    if (AscDescDatas.includes(alias)) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC')

      const isDuplicate = sorting.some((sortField: any) => sortField.fieldName === alias)

      if (!isDuplicate) {
        // add newSortField to sortFields array
        setSorting([{ fieldName: alias, order: orders }])
      } else {
        const newSortFields = sorting.filter((sortField: any) => sortField.fieldName !== alias)
        setSorting([{ fieldName: alias, order: orders }])
      }
    }
  }
  function truncateString(str: string) {
    if (!str?.length) {
      return str
    } else if (str?.length <= 20) {
      return str
    } else {
      return str.substring(0, 15) + '...'
    }
  }

  return (
    <>
      <div className='tab-content'>

        {/* ////////////////// */}
        <div id='kt_project_users_table_pane' className='tab-pane fade  show active'>
          <div className='card card-flush'>
            <div className='card-body pt-0'>
                <div className='table-responsive checkset' ref={componentRefcol}>
                  {data1 && data1.length > 0 ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <table
                        id='kt_project_users_table'
                        className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold'
                      >
                        <thead className='text-start text-gray-400 fw-bold fs-7 cursor-pointer text-uppercase gs-0'>
                          <tr>
                            <th>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  onChange={(e) => deleteAllData(e, 'full')}
                                //  checked={}
                                //onChange={() => onSelect(id)}
                                //  data-kt-check={(e)=>deleteAllData}//(e: any) =>  e.target.checked}
                                //data-kt-check-target='#kt_table_users .form-check-input'
                                />
                              </div></th>
                            {data1?.length > 0 &&
                              Object.keys(data1[0])
                                ?.filter((key) => key !== 'sector_id' && key !== 'sector_company_id')
                                ?.map((name, index) => (
                                  <>
                                    <th
                                      key={name}
                                      className='min-w-150px'
                                      onClick={() => handleColumnClick(name, order)}
                                    >
                                      {(name?.charAt(0).toUpperCase() + name?.slice(1)).replaceAll('_', ' ')}
                                      {/* {AscDescDatas.includes(Alias(name))
                                  ? sorting.filter((item: any) => item.fieldName == Alias(name))
                                      ?.length > 0 &&
                                    sorting.filter((item: any) => item.fieldName == Alias(name))[0]
                                      .order === 'ASC'
                                    ? ' ▲'
                                    : ' ▼'
                                  : null} */}
                                      {/* {sorting[index].fieldName==filAlias(name)&& sorting.order=== 'ASC' ? ' ▲' : ' ▼'}setSorting  */}
                                    </th>
                                  </>
                                ))}
                                <th className='align-text-center'>Action</th>
                          </tr>
                        </thead>
                        <Droppable droppableId='table'>
                          {(provided) => (
                            <tbody
                              className='fs-6'
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {data1?.map((item: any, index: number) => (
                                <Draggable
                                  key={item?.sector_id}
                                  draggableId={item?.sector_id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <tr
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >

                                        <td>
                                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              onChange={(e) => deleteAllData(e, item)}
                                              // value={'true'}
                                              checked={isSelected.includes(item.sector_id)}
                                            />
                                          </div>
                                        </td>
                                      
                                      {Object.keys(item)?.map((name: any) =>
                                        (name !== 'sector_id' && name !== 'sector_company_id') ? (
                                          name !== 'images' ? (
                                            <td>{truncateString(item[name])}</td>
                                          ) : (
                                            <td>
                                              <div className='symbol symbol-35px symbol-circle'>
                                                <img
                                                  className='imageWidth'
                                                  // src='/metronic8/react/demo7/media/avatars/charDhum.jpg'
                                                  src={`${API_URL}/uploads/images/${item?.images}`}
                                                  alt='img'
                                                />
                                              </div>
                                            </td>
                                          )
                                        ) : null
                                      )}

                                        <td>
                                          {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                          <a
                                            href="#"
                                            className='btn btn-light btn-active-light-primary btn-sm  '
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                          >
                                            Actions
                                            <KTSVG
                                              path='/media/icons/duotune/arrows/arr072.svg'
                                              className='svg-icon-5 m-0'
                                            />
                                          </a>
                                          {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                          {/* begin::Menu */}
                                          <div
                                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                            data-kt-menu='true'
                                          >
                                            {/* begin::Menu item */}
                                            {functionName?.includes('Update Sectors') && (
                                              <div className='menu-item px-3'>
                                                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                <a
                                                  href="#"
                                                  className='menu-link px-3'
                                                  // {i? 'data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer"': ''}
                                                  // data-bs-toggle='modal'
                                                  // data-bs-target='#kt_modal_add_customer'
                                                  onClick={() => onEditBtn(item)}
                                                >
                                                  Edit
                                                </a>
                                                {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                              </div>)}

                                            {/* end::Menu item */}

                                            {/* begin::Menu item */}
                                            {functionName?.includes('Delete Sectors') && (
                                              <div className='menu-item px-3'>
                                                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                <a
                                                  className='menu-link px-3'
                                                  data-kt-users-table-filter='delete_row'
                                                  onClick={() => onDeleteBtn(item)

                                                  }
                                                >
                                                  Delete
                                                </a>
                                                {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                              </div>)}
                                            {currentUser?.is_superadmin ? (
                                              <div className='menu-item px-3'>
                                                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                <a
                                                  className='menu-link px-3'
                                                  data-kt-users-table-filter='delete_row'
                                                  onClick={() => onViewBtn1(item)}
                                                >
                                                  {item?.status == 'Active' ? 'Deactive' : 'Active'}
                                                </a>
                                                {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                              </div>
                                            ) : null}
                                          </div>
                                        </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  ) : (
                    <>   {listloading ? (
                      <span className='indicator-progress loadingCutomer fixloaderissue' >
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>):(  <div
                            className='col-sm-4 mb-4 mt-7 fixnotavaibale '
                          >
                            No Data Available
                          </div>)}</>
                  )}

                </div>
            
            </div>
          </div>
        </div>


        <div id='kt_project_users_table_pane' className='tab-pane fade'>
          <div className='card card-flush'>
            <div className='card-body pt-0'>
              <div className='table-responsive' ref={componentRefcol}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <table
                    id='kt_project_users_table'
                    className='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold'
                  >
                    <thead className='fs-7 text-gray-400'>
                      <tr>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            onChange={(e) => deleteAllData(e, 'full')}
                          //  checked={}
                          //onChange={() => onSelect(id)}
                          //  data-kt-check={(e)=>deleteAllData}//(e: any) =>  e.target.checked}
                          //data-kt-check-target='#kt_table_users .form-check-input'
                          />
                        </div>
                        {data1?.length > 0 &&
                          Object.keys(data1[0])
                            ?.filter((key) => key !== 'sector_id')
                            ?.map((name, index) => (
                              <>
                                <th
                                  key={name}
                                  className='min-w-150px'
                                  onClick={() => handleColumnClick(name, order)}
                                >
                                  {(name?.charAt(0).toUpperCase() + name?.slice(1)).replaceAll('_', ' ')}
                                  {/* {AscDescDatas.includes(Alias(name))
                                  ? sorting.filter((item: any) => item.fieldName == Alias(name))
                                      ?.length > 0 &&
                                    sorting.filter((item: any) => item.fieldName == Alias(name))[0]
                                      .order === 'ASC'
                                    ? ' ▲'
                                    : ' ▼'
                                  : null} */}
                                  {/* {sorting[index].fieldName==filAlias(name)&& sorting.order=== 'ASC' ? ' ▲' : ' ▼'}setSorting  */}
                                </th>
                              </>
                            ))}
                      </tr>
                    </thead>
                    <Droppable droppableId='table'>
                      {(provided) => (
                        <tbody
                          className='fs-6'
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {data1?.map((item: any, index: number) => (<>
                            <Draggable
                              key={item?.sector_id}
                              draggableId={item?.sector_id}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <td>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        onChange={(e) => deleteAllData(e, item)}
                                        // value={'true'}
                                        checked={isSelected.includes(item.sector_id)}
                                      />
                                    </div>
                                  </td>
                                  {Object.keys(item)?.map((name: any) =>
                                    name !== 'sector_id' ? (
                                      name !== 'images' ? (
                                        <td>{truncateString(item[name])}</td>
                                      ) : (
                                        <td>
                                          <div className='symbol symbol-35px symbol-circle'>
                                            <img
                                              className='imageWidth'
                                              // src={`http://localhost:5001/images/saibaba.jpg`}
                                              // src='/metronic8/react/demo7/media/avatars/charDhum.jpg'
                                              src={`${API_URL}/uploads/images/${item?.images}`}
                                              alt='img'
                                            />
                                          </div>
                                        </td>
                                      )
                                    ) : null
                                  )}

                                  <td>
                                    {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                    <a
                                      className='btn btn-light btn-active-light-primary btn-sm  '
                                      data-kt-menu-trigger='click'
                                      data-kt-menu-placement='bottom-end'
                                    >
                                      Actions
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr072.svg'
                                        className='svg-icon-5 m-0'
                                      />
                                    </a>
                                    {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                    {/* begin::Menu */}
                                    <div
                                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                      data-kt-menu='true'
                                    >
                                      {/* begin::Menu item */}
                                      {functionName?.includes('Update Sectors') && (
                                        <div className='menu-item px-3'>
                                          {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                          <a
                                            className='menu-link px-3'
                                            // {i? 'data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer"': ''}
                                            // data-bs-toggle='modal'
                                            // data-bs-target='#kt_modal_add_customer'
                                            onClick={() => onEditBtn(item)}
                                          >
                                            Edit
                                          </a>
                                          {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                        </div>)}
                                      {functionName?.includes('View Sectors') && (
                                        <div className='menu-item px-3'>
                                          {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                          <a
                                            className='menu-link px-3'
                                            onClick={() => onViewBtn(item)}
                                          >
                                            View
                                          </a>
                                          {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                        </div>)}
                                      {/* end::Menu item */}

                                      {/* begin::Menu item */}
                                      {functionName?.includes('Delete Sectors') && (
                                        <div className='menu-item px-3'>
                                          {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                          <a
                                            className='menu-link px-3'
                                            data-kt-users-table-filter='delete_row'
                                            onClick={() => onDeleteBtn(item)}
                                          >
                                            Delete
                                          </a>
                                          {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                        </div>)}

                                      {currentUser?.is_superadmin ? (
                                        <div className='menu-item px-3'>
                                          {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                          <a
                                            className='menu-link px-3'
                                            data-kt-users-table-filter='delete_row'
                                            onClick={() => onViewBtn1(item)}
                                          >
                                            {item?.status == 'Active' ? 'Deactive' : 'Active'}
                                          </a>
                                          {/* eslint-enable jsx-a11y/anchor-is-valid */}
                                        </div>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          </>))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>



        <div className='d-grid mt-3 mb-5'>
          {(paginationDataApi?.limits * paginationDataApi?.pages || 1) >= paginationData.totalCount ? (
            <button
              onClick={() => { }}
              type='button'
              className='btn btn-primary btn-active-icon-primary fixlistingendbutton'
            >
              That's It
            </button>
          ) : (
            <button
              onClick={() => {
                setPaginationDataApi({
                  ...paginationDataApi,
                  limits: paginationDataApi?.limits + 10 || 10,
                })
                setloadMoreloading(true)
              }
              }
              type='button'
              className='btn btn-primary btn-active-icon-primary fixloadmorebutton'
            >  {!loadMoreloading && (
              <>
                Load More +
              </>)}
              {loadMoreloading && (
                <span className='indicator-progress fixloadersite' >
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>)}
            </button>
          )}
        </div>

        <div className='d-flex flex-stack flex-wrap pt-10 mb-10'>
          <div className='fs-6 fw-semibold text-gray-700'></div>

          <ul className='pagination'>
            <PaginationUI
              currentPage={paginationData.currentPage}
              totalPages={paginationData.totalPages}
              onPageChange={(value: any) =>
                setPaginationDataApi({ ...paginationDataApi, pages: value, limits: paginationDataApi?.limits || 10 })
              }
            />
          </ul>
        </div>
      </div>
    </>
  )
}
